import { Raycaster, Vector2, Vector3 } from 'three';

export class _Raycaster {
  public mouse;
  public raycaster;
  public camera;
  public onClickEntities;
  public container;
  public origin;
  public direction;

  constructor() {
    this.mouse = new Vector2();
    this.raycaster = new Raycaster();
    this.camera = null;
    this.onClickEntities = [];
    this.container = null;
    this.origin = new Vector3();
    this.direction = new Vector3();
  }

  setFromCamera(camera, container) {
    this.camera = camera;
    this.container = container;
  }

  castFromCamera(event) {
    const theX = event.clientX || event.screenX || event.center.x;
    const theY = event.clientY || event.screenY || event.center.y;
    this.mouse.x = ((theX - this.container.offsetLeft) / this.container.clientWidth) * 2 - 1;
    this.mouse.y = -((theY - this.container.offsetTop) / this.container.clientHeight) * 2 + 1;
    this.raycaster.setFromCamera(this.mouse, this.camera);
    return this.raycaster.intersectObjects(this.onClickEntities, true);
  }

  castFromOrigin(origin, direction) {
    this.raycaster.set(origin, direction);
    return this.raycaster.intersectObjects(this.onClickEntities, true);
  }
}
