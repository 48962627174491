import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(@Inject(MatSnackBar) private readonly snackBar: MatSnackBar) {}

  showMessage(message: string, duration: number = 4000) {
    this.snackBar.open(message, null, { duration });
  }

  showErrorMessage(message: string, duration: number = 4000) {
    this.snackBar.open(message, null, { duration, panelClass: 'snackbar-error' });
  }
}
