<nav mat-tab-nav-bar class="nav-bar mb-2" joyrideStep="secondStep" title="Das Hauptmenü" text="Hier können Sie auswählen, was Sie an Ihrem Projekt konfigurieren möchten. Außerdem können Sie Ihre Benutzerdaten festlegen." stepPosition="bottom">
  <ng-container *ngFor="let tab of tabs; index as i">
    <a mat-tab-link *ngIf="tab.hide ? !tab.hide() : true" class="tab-link"
       (click)="onTabClick(tab, i)"
       [disabled]="tab.disable ? tab.disable() : false"
       [routerLink]="tab.path ? tab.path : []"
       routerLinkActive #rla="routerLinkActive"
       [active]="tab.path ? rla.isActive : false">
      <mat-icon *ngIf="tab.icon">{{tab.icon}}</mat-icon>
      <ng-container *ngIf="tab.label">{{tab.label}}</ng-container>
    </a>
  </ng-container>
</nav>

<div class="mx-2 mb-2" id="vertical-panel" style="height: calc(100vh - 64px); overflow: auto;">
  <div style="width: 400px">
    <router-outlet></router-outlet>
  </div>
</div>
