import { Text } from './text.model';

export class Texts {
  public type: string;
  public list: Array<Text> = [];

  add(text: Text) {
    this.list.push(text);
  }

  select(uid) {
    const index = this.list.map(function(e) { return e.uid; }).indexOf(uid);
    if (index >= 0) {
      return this.list[index];
    }
    return undefined;
  }
}
