import { Inject, Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, PathReference } from '@angular/fire/database';
import { FirebaseOperation, QueryFn } from '@angular/fire/database/interfaces';

@Injectable({ providedIn: 'root' })
export class FirebaseDbListService {
  constructor(@Inject(AngularFireDatabase) private readonly db: AngularFireDatabase) {}

  read<T>(path: PathReference, queryFn?: QueryFn): AngularFireList<T> {
    return this.db.list<T>(path, queryFn);
  }

  save<T>(path: PathReference, data: T): Promise<any> {
    return this.read(path).push(data);
  }

  update<T>(path: PathReference, key: FirebaseOperation, data: T): Promise<void> {
    return this.read(path).update(key, data);
  }

  replace<T>(path: PathReference, key: FirebaseOperation, data: T): Promise<void> {
    return this.read(path).set(key, data);
  }

  remove(path: PathReference, key: FirebaseOperation): Promise<void> {
    return this.read(path).remove(key);
  }

  removeAll(path: PathReference): Promise<void> {
    return this.read(path).remove();
  }
}
