import { Identifiable } from '../identifiable.model';

export class Environment implements Identifiable {
  uid: string = null;
  name: string = null;
  src: string = null;
  thumbnail: string = null;
  type: string = 'environment';

  set(props) {
    this.name = props.name;
    this.src = props.src;
    this.thumbnail = props.thumbnail;
    this.type = 'environment';
    this.uid = props.uid;
  }
}
