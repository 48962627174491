import { Texts } from './texts.model';
import { Ground } from './ground.model';
import { Plate } from '../firestore/categories/plate/plate.model';
import { Stone } from '../firestore/categories/stone/stone.model';
import { Border } from '../firestore/categories/border/border.model';
import { Accessories } from './accessories.model';
import { Environment } from '../firestore/environments/environment.model';
import {Accessory} from '../firestore/categories/accessory/accessory.model';

export class Project {
  accesories: Array<Accessory>;
  environment: Environment;
  border: Border;
  stone: Stone;
  plates: Array<Plate>;
  texts: Texts;
  ground: Ground;
  message: string;
  name: string;
  uid: string;
  lastModified: string;
  lastOrdered: string;
  priceCalculation: boolean;

  constructor() {
    this.setDefault();
  }

  setDefault() {
    this.accesories = [];
    this.environment = new Environment();
    this.border = new Border();
    this.stone = new Stone();
    this.plates = [];
    this.texts = new Texts();
    this.ground = new Ground();
    this.ground.setDefault();
    this.name = '';
    this.message = '';
    this.lastModified = '';
    this.lastOrdered = '';
    this.priceCalculation = false;
  }
}

