import { Inject, Injectable } from '@angular/core';
import { ProjectService } from './project.service';
import * as Hammer from 'hammerjs';
import { UtilsService } from '../three-view/three-utils/utils.service';
import { MaterialService } from '../three-view/three-services/material.service';
import { fromEvent } from 'rxjs';
import {AccessoriesService} from '../three-view/three-services/accessories.service';

@Injectable()
export class EventsService {

  public canLoadModel = true;
  public currentTab = 'ground';
  private container;
  private mouseMoveEvent;
  public subscription;

  constructor(
    @Inject(ProjectService) private readonly projectService: ProjectService,
    @Inject(AccessoriesService) private readonly accessoriesService: AccessoriesService,
    @Inject(MaterialService) private readonly materialService: MaterialService,
    @Inject(UtilsService) private readonly utilsService: UtilsService
  ) { }

  public initEvents() {
    this.container = document.getElementById('ThreeJS');
    this._hammer = new Hammer(this.container);
    this._hammer.get('pan').set({ direction: Hammer.DIRECTION_ALL, threshold: 0 });

    this.mouseMoveEvent = fromEvent(this.container, 'mousemove');
    //to init subscription
    this.addMaterialPolishHoverListener();
    this.removeMaterialHoverListener();
    //to init events for moving accesories incase you reload page while on accessories tab
    if(window.location.pathname == '/accessories'){
      this._moveAccessory()
   }
  }

  public addMaterialPolishHoverListener(){
    let self = this;
    this.subscription = self.mouseMoveEvent.subscribe((evt: MouseEvent) => {
      if(self.projectService.detectIfMaterialIsAppliable(evt)){
       self.container.classList.add("bucketIcon");
      }else{
        self.container.classList.remove("bucketIcon");
        self.container.style.cursor = 'no-drop';
      }
    });
  }

  private removeMaterialHoverListener(){
    if(this.subscription){
      this.subscription.unsubscribe();
    }
    this.container.style.cursor = "default";
  }


  public switchEvent(value) {
    this.utilsService.monumentHolderWidth.next(this.utilsService.getBorderSize().monumentHolderWidth);
    switch (value) {
      case 'help':
      case 'user':
      case 'email':
      case 'project':
      case 'fScreen':
      case 'ground':
        this.removeAllEvents();
        this.currentTab = value;
        break;
      case 'border':
        this.removeAllEvents();
        this.currentTab = value;
        this.canLoadModel = true;
        break;
      case 'plate':
        this.removeAllEvents();
        this.currentTab = value;
        this.canLoadModel = true;
        break;
      case 'stone':
        this.removeAllEvents();
        this.currentTab = value;
        this.canLoadModel = false;
        break;
      case 'material':
        this.currentTab = value;
        this.removeAllEvents();
        this.canLoadModel = true;
        this.setMaterial();
        break;
      case 'texts':
        this.removeAllEvents();
        this.moveText();
        this.currentTab = value;
        this.canLoadModel = true;
        break;
      case 'accessories':
        this.removeAllEvents();
        this._moveAccessory();
        this.currentTab = value;
        this.canLoadModel = true;
        break;
      case 'admin':
        this.removeAllEvents();
        this.projectService.shouldReload = true;
        this.currentTab = value;
        break;
    }
  }

  public moveText() {
    this._hammer.on('tap press panstart', (ev) => {
      this.projectService.selectText(ev);
    });
    this._hammer.on('panmove', (ev) => {
      this.projectService.moveText(ev);
    });
    this._hammer.on('panend pancancel', (ev) => {
      this.projectService.deselectText(ev);
    });
  }

  public rotateStone() {
    this.projectService.rotateStone();
  }

  public centerStone() {
    this.projectService.centerStone();
  }

  public moveStone() {
    this._hammer.on('tap press panstart', (ev) => {
      this.projectService.selectStone(ev);
    });
    this._hammer.on('panmove', (ev) => {
      this.projectService.moveStone(ev);
    });
    this._hammer.on('panend pancancel', (ev) => {
      this.projectService.deselectStone(ev);
      this.removeAllEvents();
      window.document.body.style.cursor = 'default';
    });
  }

  public removeAllEvents() {
    this._hammer.off('panstart');
    this._hammer.off('panmove');
    this._hammer.off('panend pancancel');
    this._hammer.off('tap press');
    window.document.body.style.cursor = 'default';

    this.accessoriesService.deselectCurrentAccessory();

    if (this.projectService.lastSelectedText) {
      const node = this.utilsService.getNodeByName('texts');
      this.materialService.overwriteSelectedMaterial(node, this.projectService.lastSelectedText, 0x000000);
      this.projectService.lastSelectedText.selected = false;
      this.projectService.lastSelectedText = undefined;
      this.utilsService.enableControls();
    }

    this.removeMaterialHoverListener()
  }

  public setCheckmark(event) {
    if (!event) {
      return;
    }
    if (this._lastSibling) {
      this._lastSibling.style.display = 'none';
    }
    if (event.target.className !== 'fixedImg') {
      return;
    }

    this._lastSibling = event.target.nextSibling.nextSibling;
    this._lastSibling.style.display = 'block';
  }

  private setMaterial() {
    this._hammer.on('tap press', (ev) => {
      this.projectService.placeMaterial(ev);
    });
  }

  private _moveAccessory() {
    this._hammer.on('tap press panstart', (ev) => {
      this.accessoriesService.handleMouseEvent(ev);
    });
    this._hammer.on('panmove', (ev) => {
      this.accessoriesService.moveAccessory(ev);
    });
    this._hammer.on('panend pancancel', (ev) => {
      this.accessoriesService.stopMovingAccessory(ev);
    });
  }

  private _hammer;
  private _lastSibling;
}
