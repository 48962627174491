<mat-card *ngIf="!uiService.isJoomla" class="example-card">
  <ng-container [ngSwitch]="section">
    <ng-container *ngSwitchCase="'login'">
      <div style="text-align: center">
        <mat-card-title><strong style="font-size: 18px">Anmeldung</strong></mat-card-title>
      </div>
      <mat-card-content>
        <form [formGroup]="loginForm" fxLayout="column" (fmxSubmit)="signIn()">
          <app-material-text-input formControlName="email" placeholder="E-Mail" [debounceTime]="0">
          </app-material-text-input>
          <app-material-password-input formControlName="password" placeholder="Passwort"></app-material-password-input>
          <a class="pass-reset" (click)="goToPassReset()">Passwort vergessen?</a>
          <button mat-raised-button color="primary" style="margin-top: 10px" type="submit"
                  [disabled]="loading$ | async">Anmelden
          </button>
          <div class="divider">
            <strong class="divider-title"> Einloggen mit </strong>
          </div>
          <button class="login-with-google-btn" style="cursor: pointer" type="button"
                  [hidden]="loading$ | async"
                  (click)="googleSignIn()">
            Sign in with Google
          </button>
          <div class="divider">
            <strong class="divider-title"> Noch keinen Account </strong>
          </div>
          <button mat-raised-button class="switch-button" type="button" [disabled]="loading$ | async"
                  (click)="goToRegister()">Registrieren
          </button>
          <div *ngIf="loading$ | async">
            <mat-progress-bar style="position:fixed;top:0;left: 0" mode="query"></mat-progress-bar>
          </div>
        </form>
      </mat-card-content>
    </ng-container>
    <ng-container *ngSwitchCase="'register'">
      <div style="text-align: center">
        <mat-card-title><strong style="font-size: 18px">Registrieren</strong></mat-card-title>
      </div>
      <mat-card-content>
        <form [formGroup]="registerForm" fxLayout="column" (fmxSubmit)="signUpWithEmail()">
          <app-material-text-input formControlName="email" placeholder="E-Mail"
                                   [debounceTime]="0"></app-material-text-input>
          <ng-container formGroupName="passwords">
            <app-material-password-input formControlName="password"
                                         placeholder="Passwort"></app-material-password-input>
            <app-material-password-input formControlName="confirmPass"
                                         placeholder="Passwort Wiederholung"></app-material-password-input>
          </ng-container>
          <button mat-raised-button color="primary" style="margin-top: 10px" type="submit"
                  [disabled]="loading$ | async">Registrieren
          </button>
          <div class="divider">
            <strong class="divider-title"> Sie haben bereits einen account? </strong>
          </div>
          <button mat-raised-button class="switch-button" type="button" [disabled]="loading$ | async"
                  (click)="goToLogIn()">Anmeldung
          </button>
          <div *ngIf="loading$ | async">
            <mat-progress-bar style="position:fixed;top:0;left: 0" mode="query"></mat-progress-bar>
          </div>
        </form>
      </mat-card-content>
    </ng-container>
    <ng-container *ngSwitchCase="'resetPass'">
      <div style="text-align: center">
        <mat-card-title><strong style="font-size: 18px">Passwort zurücksetzen</strong></mat-card-title>
      </div>
      <mat-card-content>
        <form [formGroup]="resetForm" fxLayout="column" (fmxSubmit)="sendPassReset()">
          <app-material-text-input formControlName="email" placeholder="E-Mail"
                                   [debounceTime]="0"></app-material-text-input>
          <button mat-raised-button color="primary" style="margin-top: 10px" type="submit"
                  [disabled]="loading$ | async">Passwort zurücksetzen
          </button>
          <div class="divider"></div>
          <button mat-raised-button class="switch-button" type="button" [disabled]="loading$ | async"
                  (click)="goToLogIn()">Anmeldung
          </button>
          <div *ngIf="loading$ | async">
            <mat-progress-bar style="position:fixed;top:0;left: 0" mode="query"></mat-progress-bar>
          </div>
        </form>
      </mat-card-content>
    </ng-container>
  </ng-container>
</mat-card>
