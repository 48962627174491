import { Inject, Injectable } from '@angular/core';
import { AssetsLoadingManager } from './loader-service';
import { UtilsService } from '../three-utils/utils.service';
import { Color, MeshPhysicalMaterial, PMREMGenerator, RepeatWrapping, Shader, Texture, Vector2, Vector3, UnsignedByteType, Object3D } from 'three';
import { from, Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { MaterialsFirestoreService } from '../../modules/material/services/materials-firestore.service';
import { PolishesFirestoreService } from '../../modules/material/services/polishes-firestore.service';
import { Material } from '../../../models/firestore/materials/material.model';
import { Polish } from '../../../models/firestore/polishes/polish.model';
import { ThreeViewService } from '../three-view.service'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'

@Injectable({ providedIn: 'root' })
export class MaterialService {
  appData;

  constructor(
    private utilsService: UtilsService,
    private _loader: AssetsLoadingManager,
    @Inject(MaterialsFirestoreService) private readonly materialsFirestoreService: MaterialsFirestoreService,
    @Inject(PolishesFirestoreService) private readonly polishesFirestoreService: PolishesFirestoreService,
    @Inject(ThreeViewService) private readonly threeViewService: ThreeViewService
  ) { }

  public getCurrentCubeMap() {
    return new Promise((resolve, reject) => {
      const path = 'assets/cubemaps/mud_road_1k.hdr';

      this.pmremGenerator = new PMREMGenerator(this.threeViewService.getRenderer());
      this.pmremGenerator.compileEquirectangularShader();
      let rgbeLoader = new RGBELoader();
      rgbeLoader.setDataType(UnsignedByteType);
      rgbeLoader.load(
        path,
        (res) => {
          const envMap = this.pmremGenerator.fromEquirectangular(res).texture;
          resolve(envMap);
        }
      );
    });
  }

  private _cubeMap = null;
  public applyPolish(object, properties) {
    return new Promise<void>((resolve, reject) => {
      this.getCurrentCubeMap().then((envMap) => {
        object.material = new MeshPhysicalMaterial({});
        object.material.envMap = envMap;
        this._cubeMap = envMap;
        this.mapPropsToMaterial(properties, object.material);
        this.applyShaders(object.material);
        object.material.needsUpdate = true;
        resolve();
      })
    })
  }

  public updatePolish(object, values) {
    object.material = new MeshPhysicalMaterial({});
    object.material.envMap = this._cubeMap;
    this.mapPropsToMaterial(values, object.material);
    this.applyShaders(object.material);
    object.material.needsUpdate = true;
  }

  // todo: add types to this
  public applyMaterial(object, materialName?: string) {
    const cachedFirestoreMaterials: Array<Material> = this.materialsFirestoreService.getCached();
    const cachedFirestorePolishes: Array<Polish> = this.polishesFirestoreService.getCached();
    if (materialName === 'ground' || materialName === 'secondGround') {
      const baseMat2 = this._getPropertByName(cachedFirestorePolishes, materialName);
      this._loadMaterial(baseMat2).pipe(take(1)).subscribe(loadedMaterial => object.material = loadedMaterial);
      // object.material = loadedMaterial;
      return;
    }
    if (!object.userData.polish && object.userData === 'text') {
      object.userData.polish = 'Stahl';
    }
    let material;  // material computing
    if (object.userData.type === 'plate' && object.userData.appearance !== undefined) {  // older projects will have undefined material
      material = object.userData.appearance.material ?? 'Aura';
    } else {
      if (!object.userData.material) {
        material = 'Aura';  // Default material if missing for some reason
      } else {
        material = typeof object.userData.material === 'string' ? object.userData.material : object.userData.material[object.name];
      }
    }

    let polish;  // polish computing
    if (object.userData.type === 'plate' && object.userData.appearance !== undefined) {  // older projects will have undefined polish
      polish = object.userData.appearance.polish ?? 'Poliert';
    } else {
      if (!object.userData.polish) {
        polish = 'Poliert';  // Default polish if missing for some reason
      } else {
        polish = typeof object.userData.polish === 'string' ? object.userData.polish : object.userData.polish[object.name];
      }
    }
    const matKeys = this._getPropertByName(cachedFirestoreMaterials, material);
    const polishKeys = this._getPropertByName(cachedFirestorePolishes, polish);
    if (!matKeys || !polishKeys) {
      return;
    }

    const mergedKeys = {
      name: matKeys.name + '_' + polishKeys.name,
      map: polishKeys.map ? polishKeys.map : matKeys.map
    };

    for (const key in polishKeys) {
      if (key !== 'name' && key !== 'map') {
        mergedKeys[key] = polishKeys[key];
      }

    }

    this._loadMaterial(mergedKeys)
      .pipe(
        take(1),
        tap(loadedMaterial => {
          object.traverse(child => {
            if (child.material) {
              child.material = loadedMaterial;
            }
          });
        })
      )
      .subscribe();
  }

  public overwriteSelectedMaterial(node, obj, selected) {
    const mesh = this.utilsService.getMeshByUID(node, obj.uid);
    if (!mesh) {
      return;
    }
    let selectedObj = []
    mesh.traverse(child => {
      if (child.material) {
        if(selected){
          selectedObj.push(child)
        }
        else{
          selectedObj = []
        }
      }
    });
    this.threeViewService.outlinePass.selectedObjects = selectedObj
  }

  private _getPropertByName(obj, name) {
    for (const props in obj) {
      if (obj[props].name === name) {
        return this.utilsService.createDeepClone(obj[props]);
      }
    }
  }

  private _loadMaterial(props): Observable<MeshPhysicalMaterial> {

    if (this._cachedMaterials[props.name]) {
      return of(this._cachedMaterials[props.name]);
    }

    if (this._chachedCubeMap) {
      const material = new MeshPhysicalMaterial({ envMap: this._chachedCubeMap });
      material.needsUpdate = true;
      this.mapPropsToMaterial(props, material);
      this.applyShaders(material);
      return of(material);
    } else {
      return from(this.getCurrentCubeMap()).pipe(
        map((texture: Texture) => {
          this._chachedCubeMap = texture;
          const material = new MeshPhysicalMaterial({ envMap: this._chachedCubeMap });
          material.needsUpdate = true;
          this.mapPropsToMaterial(props, material);
          this.applyShaders(material);
          this._cachedMaterials[props.name] = material;
          return material;
        })
      );
    }
  }

  private mapPropsToMaterial(props, material: MeshPhysicalMaterial) {
    for (const prop in props) {
      if (props[prop] === null) continue;
      switch (prop) {
        case 'name':
        case 'roughness':
        case 'metalness':
        case 'reflectivity':
        case 'envMapIntensity':
        case 'depthTest':
        case 'opacity':
        case 'depthWrite':
        case 'transparent':
        case 'side':
        case 'clearcoat':
        case 'clearcoatRoughness':
          material[prop as string] = props[prop];
          break;
        case 'emissive':
        case 'color':
          material[prop] = new Color(props[prop]);
          break;
        case 'envMap':
          // material[prop] = new THREE.Color(props[prop]);
          break;
        case 'map':
        case 'roughnessMap':
        case 'normalMap':
          const repeat = props.repeat || 1;
          if (this._cachedMaps[props[prop]]) {
            const tempMap = this._cachedMaps[props[prop]].clone();
            if (prop !== 'map') {
              tempMap.repeat.set(repeat, repeat);
            }
            material[prop] = tempMap;
            material[prop].needsUpdate = true;
            material.needsUpdate = true;
          } else {
            // cache images
            this._loader.load(props[prop]).then(result => {

              result.wrapS = result.wrapT = RepeatWrapping;
              result.offset.set(0, 0);
              if (prop !== 'map') {
                result.repeat.set(repeat, repeat);
              }
              result.anisotropy = 8;
              result.needsUpdate = true;
              material[prop] = result;
              material[prop].needsUpdate = true;
              material.needsUpdate = true;
              this._cachedMaps[props[prop]] = result;
            });
          }

          break;
        case 'normalScale':
          material[prop] = new Vector2(props[prop][0], props[prop][1]);
          break;
      }
    }
  }

  private applyShaders(material: MeshPhysicalMaterial) {
    material.onBeforeCompile = (shader: Shader) => {
      shader.uniforms.scaler = { value: 0.0116 };
      shader.uniforms.gamma = { value: this.utilsService.overallGamma };
      shader.vertexShader = shader.vertexShader.replace(
        '#define STANDARD',
        `
        #define STANDARD
        varying vec3 wNormal;
        varying vec3 vUv2;
        `
      );
      shader.vertexShader = shader.vertexShader.replace(
        'void main() {',
        `
        void main() {
          vec4 worldPosition2 = modelMatrix * vec4( position, 1.0 );
          wNormal = normalize( mat3( modelMatrix[0].xyz, modelMatrix[1].xyz, modelMatrix[2].xyz ) * normal );
          vUv2 = worldPosition2.xyz;
        `
      );

      shader.fragmentShader = shader.fragmentShader.replace(
        'uniform float opacity;',
        `uniform float opacity;
         uniform float scaler;
         uniform float gamma;
         varying vec3 vUv2;
         varying vec3 wNormal;
         vec3 GetTriplanarWeights (vec3 normals) {
            vec3 triW = abs(normals);
            return triW / (triW.x + triW.y + triW.z);
          }
        struct TriplanarUV {
          vec2 x, y, z;
        };
        TriplanarUV GetTriplanarUV (vec3 pos) {
            TriplanarUV  triUV;
            triUV.x = pos.zy;
            triUV.y = pos.xz;
            triUV.z = pos.xy;
            return triUV;
          }
         `
      );

      shader.fragmentShader = shader.fragmentShader.replace(
        '#include <map_fragment>',
        `
        #ifdef USE_MAP
            vec3 xColor = texture2D(map, vUv2.yz * scaler).rgb;
            vec3 yColor = texture2D(map, vUv2.xz * scaler).rgb;
            vec3 zColor = texture2D(map, vUv2.xy * scaler).rgb;
            vec3 triW = GetTriplanarWeights(wNormal);
            vec4 easedColor = vec4( xColor * triW.x + yColor * triW.y + zColor * triW.z, 1.0);
            vec4 gammaCorrectedColor = vec4( pow(abs(easedColor.x),gamma), pow(abs(easedColor.y),gamma), pow(abs(easedColor.z),gamma), 1.0);
            vec4 texelColor3 = mapTexelToLinear( gammaCorrectedColor );
            diffuseColor *= texelColor3;
        #endif
        `
      );
      // shader.fragmentShader = shader.fragmentShader.replace(
      //   '#include <roughnessmap_fragment>',
      //   `
      //   float roughnessFactor = roughness;

      //   #ifdef USE_ROUGHNESSMAP
      //     vec3 xColorR = texture2D(roughnessMap, vUv2.yz * scaler).rgb;
      //     vec3 yColorR = texture2D(roughnessMap, vUv2.xz * scaler).rgb;
      //     vec3 zColorR = texture2D(roughnessMap, vUv2.xy * scaler).rgb;

      //     vec3 triWR = GetTriplanarWeights(wNormal);
      //     vec4 easedColorR = vec4( xColorR * triWR.x + yColorR * triWR.y + zColorR * triWR.z, 1.0);
      //     vec4 gammaCorrectedColorR = vec4( pow(abs(easedColorR.x),gamma), pow(abs(easedColorR.y),gamma), pow(abs(easedColorR.z),gamma), 1.0);
      //     vec4 texelColorR = mapTexelToLinear( gammaCorrectedColorR );
      //     roughnessFactor *= texelColorR.g;
      //   #endif

        `
      );
      // shader.fragmentShader = shader.fragmentShader.replace(
      //   '#include <normal_fragment_maps>',
      //   `
      //   vec3 perturbNormal2Arb( vec3 eye_pos, vec3 surf_norm ) {
      //     vec3 q0 = dFdx( eye_pos.xyz );
      //     vec3 q1 = dFdy( eye_pos.xyz );
      //     vec2 st0 = dFdx( vUv.st );
      //     vec2 st1 = dFdy( vUv.st );
      //     vec3 S = normalize( q0 * st1.t - q1 * st0.t );
      //     vec3 T = normalize( -q0 * st1.s + q1 * st0.s );
      //     vec3 N = normalize( surf_norm );
      //     vec3 mapN = texture2D( normalMap, vUv ).xyz * 2.0 - 1.0;
      //     mapN.xy = normalScale * mapN.xy;
      //     mat3 tsn = mat3( S, T, N );
      //     return normalize( tsn * mapN );
      // };
      //   #ifdef OBJECTSPACE_NORMALMAP
      //       normal = texture2D( normalMap, vUv ).xyz * 2.0 - 1.0; // overrides both flatShading and attribute normals
      //       #ifdef FLIP_SIDED
      //           normal = - normal;
      //       #endif
      //       #ifdef DOUBLE_SIDED
      //           normal = normal * ( float( gl_FrontFacing ) * 2.0 - 1.0 );
      //       #endif
      //       normal = normalize( normalMatrix * normal );
      //   #elif defined( TANGENTSPACE_NORMALMAP )

      //       TriplanarUV triUV = GetTriplanarUV(vUv2);

      //       vec3 tangentNormalX = texture2D(normalMap, vUv2.yz * scaler).xyz;
      //       vec3 tangentNormalY = texture2D(normalMap, vUv2.xz * scaler).xyz;
      //       vec3 tangentNormalZ = texture2D(normalMap, vUv2.xy * scaler).xyz;

      //       vec3 worldNormalX = tangentNormalX.xyz;
      //       vec3 worldNormalY = tangentNormalY.xyz;
      //       vec3 worldNormalZ = tangentNormalZ;

      //       vec3 triWN = GetTriplanarWeights(wNormal);
      //       vec3 mapI = normalize(worldNormalX * triWN.x + worldNormalY * triWN.y + worldNormalZ * triWN.z);
      //       vec3 mapN = vec3(mapI.x, mapI.y, mapI.z);
      //       mapN.xy *= normalScale;
      //       #ifdef USE_TANGENT
      //           normal = normalize( vTBN * mapN );
      //       #else
      //           normal = perturbNormal2Arb( -vViewPosition, normal, mapN );
      //       #endif
      //   #elif defined( USE_BUMPMAP )
      //       normal = perturbNormalArb( -vViewPosition, normal, dHdxy_fwd() );
      //   #endif
      //   `
      // )
    };
  }

  private _cachedMaterials = {};
  private _cachedMaps = {};
  private _chachedCubeMap;
  private pmremGenerator;
}
