import { Inject, Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from './auth.service';
import {tap} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class RequiredRoleGuard implements CanActivate {

  constructor(@Inject(AuthService) private readonly authService: AuthService,
              @Inject(Router) private readonly router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(!route.data.roles) {
      this.router.navigate(['/help'])
      return false
    }
    return this.authService.hasAnyClaim(route.data.roles)
      .pipe(
        tap(hasRequiredRole => !hasRequiredRole && this.router.navigate(['/help'])),
      )
  }
}
