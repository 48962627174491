import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../../environments/environment';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { HttpClientModule } from '@angular/common/http';
import { throwIfModuleAlreadyLoaded } from '../app.utils';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { ENVIRONMENT } from './env/env-config.token';
import { FmxErrorsModule } from '@vixncu/ngx-formx';
import { CookieModule } from 'ngx-cookie';

const ANGULAR_FIRE_MODULES = [
  AngularFireModule.initializeApp(environment.firebase),
  AngularFirestoreModule,
  AngularFireDatabaseModule,
  AngularFireStorageModule,
  AngularFireAuthModule,
  AngularFireFunctionsModule,
  AngularFireAnalyticsModule
];

@NgModule({
  imports: [
    HttpClientModule,
    NgxWebstorageModule.forRoot({ prefix: 'lithos' }),
    ...ANGULAR_FIRE_MODULES,
    CookieModule.forRoot(),
    FmxErrorsModule.forRoot()
  ],
  providers: [
    UserTrackingService,
    ScreenTrackingService,
    { provide: ENVIRONMENT, useValue: environment }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfModuleAlreadyLoaded(parentModule, 'CoreModule');
  }
}
