import { Inject, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserEmail } from '../../../../models/firestore/users/user-email.model';
import { FirestoreUserResourceService } from '../../../core/firebase/firestore/firestore-user-resource.service';

@Injectable({ providedIn: 'root' })
export class EmailsFirestoreService extends FirestoreUserResourceService<UserEmail> {
  constructor(@Inject(AngularFirestore) protected readonly firestore: AngularFirestore) { super(firestore); }

  protected get resourcePath(): string {
    return 'emails';
  }
}
