import { Inject, Injectable } from '@angular/core';
import { FileUpload } from '../../models/fileupload';
import { forkJoin, Observable } from 'rxjs';
import { AngularFireList } from '@angular/fire/database';
import { FirebaseStorageService } from '../core/firebase/firebase-storage.service';
import { take } from 'rxjs/operators';
import { FirebaseDbListService } from '../core/firebase/firebase-db-list.service';

export interface ProjectFilesUpload {
  projectDataUrl: string;
  thumbnailUrl: string;
}

@Injectable({ providedIn: 'root' })
export class UploadFileService {
  constructor(
    @Inject(FirebaseDbListService) private readonly dbListService: FirebaseDbListService,
    @Inject(FirebaseStorageService) private readonly storage: FirebaseStorageService
  ) { }

  changeBasePath(path) {
    if (path === 'restricted') { // fix to not override restricted folder
      this.storageBasePath = 'restricted_user';
    } else {
      this.storageBasePath = path;
    }
  }

  getBasePath() {
    return this.storageBasePath;
  }

  saveProjectFilesToStorage(projectUpload: FileUpload, thumbnailUpload: FileUpload): Observable<ProjectFilesUpload> {
    const projectDataPath = `${this.storageBasePath}/${projectUpload.file.name}`;
    const projectData$ = this.storage.uploadAndWaitToComplete(projectDataPath, projectUpload.file);

    const thumbnailPath = `${this.storageBasePath}/${thumbnailUpload.file.name}`;
    const thumbnail$ = this.storage.uploadAndWaitToComplete(thumbnailPath, thumbnailUpload.file);

    return forkJoin({ projectDataUrl: projectData$, thumbnailUrl: thumbnail$ }).pipe(take(1));
  }

  addModelToStorage(file: File, relativeUploadPath: string): Observable<string> {
    const path = `${this.restrictedStorageBasePath}/${relativeUploadPath}`;
    return this.storage.uploadAndWaitToComplete(path, file);
  }

  /** @deprecated use addModelToStorage **/
  pushNew3DModelToDatabase(object, route, callback) {
    return new Promise<void>((resolve, reject) => {
      const path = `${this.restrictedStorageBasePath}/${route}${object.name}`;
      this.storage.upload(path, object);
      callback('object');
      resolve();
    });
  }

  /** @deprecated use addModelToStorage **/
  pushNewThumbnailToDatabase(thumbnail, route, callback) {
    const path = `${this.restrictedStorageBasePath}/${route}${thumbnail.name}`;
    this.storage.uploadAndWaitToComplete(path, thumbnail)
      .pipe(take(1))
      .subscribe(downloadUrl => callback(downloadUrl));
  }

  private storageBasePath = '/uploads';
  private readonly restrictedStorageBasePath = '/restricted';
}
