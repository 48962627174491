import { ModelUtils } from '../../models/project/model.utils';

export class Text {
  rows: string[];
  font: string;
  fontSize: number;
  alignment: string;
  fontType: string;
  material: string;
  polish: string;
  position: { x: number, y: number, z: number };
  direction: { x: number, y: number, z: number };
  rotation: number;
  type: string;
  color: string;
  thickness: number;
  selected: boolean;
  uid: string;

  constructor() { }

  setMat(props) {
    this.material = props.material;
  }

  setUid(uid) {
    this.uid = uid;
  }

  setValues(props) {
    this.rows = props.rows;
    this.font = props.font;
    this.fontSize = props.fontSize;
    this.alignment = props.alignment;
    this.fontType = props.fontType;
    this.material = props.material;
    this.rotation = props.rotation;
    this.thickness = props.thickness || 0.3;
    this.type = 'text';
    this.polish = props.polish;
    this.uid = props.uid;
  }

  setDefault() {
    this.rows = ['Name'];
    this.font = 'Baron';
    this.fontSize = 30;
    this.alignment = 'center';
    this.fontType = 'normal';
    this.material = 'Aura';
    this.polish = 'Stahl';
    this.rotation = 0;
    this.selected = false;
    this.type = 'text';
    this.color = '#dacaba';
    this.position = { x: 0, y: 50, z: 50 };
    this.direction = { x: 0, y: 0, z: 1 };
    this.thickness = 1.0;
    this.uid = ModelUtils.uuidv4();
  }

  set(props) {
    if (props.rows) {
      this.rows = props.rows;
    }
    if (props.font) {
      this.font = props.font;
    }
    if (props.fontSize) {
      this.fontSize = props.fontSize;
    }
    if (props.alignment) {
      this.alignment = props.alignment;
    }
    if (props.fontType) {
      this.fontType = props.fontType;
    }
    if (props.material) {
      this.material = props.material;
    }
    if (props.position) {
      this.position = props.position;
    }
    if (props.direction) {
      this.direction = props.direction;
    }
    if (typeof props.rotation === 'number') {
      this.rotation = props.rotation;
    }
    if (props.color) {
      this.color = props.color;
    }
    if (props.thickness) {
      this.thickness = props.thickness;
    }
    if (props.type) {
      this.type = 'text';
    }
    if (props.polish) {
      this.polish = props.polish;
    }
    if (props.uid) {
      this.uid = props.uid;
    }
    this.selected = props.selected;
  }
}
