import {Inject, Injectable} from '@angular/core';
import { AssetsLoadingManager } from './loader-service';
import { UtilsService } from '../three-utils/utils.service';
import { MaterialService } from './material.service';
import { Accessory } from '../../../models/firestore/categories/accessory/accessory.model';
import { Vector3 } from 'three';
import {ProjectService} from '../../services/project.service';
import {AbstractObject3DService} from './abstract-object3d.service';
import {BorderItemsFirestoreService} from '../../modules/ground/services/border-items-firestore.service';
import {MeasurementsService} from './measurements.service';
import {Stone} from '../../../models/firestore/categories/stone/stone.model';
import {Corner} from '../../../models/firestore/categories/plate/corner.enum';
import {Plate} from '../../../models/firestore/categories/plate/plate.model';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class AccessoriesService extends AbstractObject3DService<Accessory>{
  /**UIDs marked for deletion in order to avoid loading an already deleted acc
   * @deprecated <- accessories can't be deleted until they are loaded, or can they?*/
  markedForDeletion: string[] = [];
  public readonly accessoriesChanged: BehaviorSubject<Accessory[]> = new BehaviorSubject<Accessory[]>([]);

  constructor(
    @Inject(MaterialService) protected materialsService: MaterialService,
    @Inject(BorderItemsFirestoreService) protected firestoreService: BorderItemsFirestoreService,
    @Inject(AssetsLoadingManager) protected _loader: AssetsLoadingManager,
    @Inject(UtilsService) protected utilsService: UtilsService,
    @Inject(MeasurementsService) protected measurementsService: MeasurementsService) {
    super(utilsService, materialsService, _loader, Accessory, 'accessories');
  }

  public requestUpdate(updateValues: Partial<Accessory>, simpleUpdate?: boolean): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._objectRef = this.getAccessory(updateValues.uid);
      if(!this._objectRef) {return resolve();}
      this._update(updateValues);
      resolve();
    });
  }

  public addAccessory(values: Accessory) {
    this.deselectCurrentAccessory();
    if(!values?.name) {return;}
    let accToAdd = new Accessory();
    accToAdd.set({...values, uid: this.firestoreService.createId()});
    this._createObject3D(accToAdd.src).then((obj) => {
      obj.traverse(child => {
        child.userData = this.utilsService.createDeepClone(accToAdd);
      });
      this._accessories.push(this.utilsService.createDeepClone(accToAdd));
      this._node.add(obj);
      this._update(accToAdd);
      this._applyMaterial();
      this.accessoriesChanged.next(this._accessories);
      this.needsRender.next();
    });
  }

  public removeSelectedAccessory() {
    this.removeAccessory(this._selectedAcc);
  }

  public removeAccessoryByUid(uid: string) {
    const acc = this.getAccessory(uid);
    this.removeAccessory(acc);
  }

  public removeAccessory(acc: Accessory) {
    if(acc instanceof Accessory) {
      this._node.children = this._node.children.filter(child => {
        if (child.userData.uid !== acc.uid) {
          return true;
        } else {
          this.utilsService.disposeElement(child);
          return false;
        }
      });
      this._accessories.splice(this._accessories.indexOf(acc), 1);
      this.accessoriesChanged.next(this._accessories);
      this.needsRender.next();
    }
  }

  public purge() {
    this.removeFromScene();
    this._accessories = [];
    this.needsRender.next();
  }

  /**
   * Called when clicked whilst material tab is active
   * Updates both scene objects and local instances in this._accessories
   * @param updateValues -> uid = accessory uid, meshName = accessory part to be changed
   */
  public changeAppearance(updateValues: {appearance: {material?: string, polish?: string}, meshName: string, uid: string}) {
    const acc = this.getAccessory(updateValues.uid);
    const accMesh = this.utilsService.getMeshByUID(this._node, acc.uid);
    if(!accMesh) {return;}
    this._objectRef = acc;
    const groupIndex = this._findMeshInGroup(updateValues.meshName);
    let tempMeshes: Array<string>;
    if(groupIndex !== -1) {
      tempMeshes = [...acc.groups[groupIndex].models];
    } else {
      tempMeshes = [updateValues.meshName];
    }
    for(let tempMesh of tempMeshes) {
      accMesh.userData.material[tempMesh] = updateValues.appearance.material ?? accMesh.userData.material[tempMesh];
      acc.material[tempMesh] = accMesh.userData.material[tempMesh];
    }
    if(updateValues.appearance.polish) {
      accMesh.userData.polish[updateValues.meshName] = updateValues.appearance.polish;
      acc.polish[updateValues.meshName] = updateValues.appearance.polish;
    }
    accMesh.traverse(child => {
      child.userData = this.utilsService.createDeepClone(accMesh.userData);
    })
    this._applyMaterial();
  }

  public handleMouseEvent(ev) {
    const intersect = this.utilsService.getMouseIntersections(['accessories'], ev);
    this.deselectCurrentAccessory();
    if(intersect) {
      this.selectAccessory(intersect.object.userData.uid);
    }
  }

  public selectAccessory(uid: string) {
    if(this._selectedAcc) {
      this.deselectCurrentAccessory();
    }
    this.utilsService.disableControls();
    this._selectedAcc = this.getAccessory(uid);
    this._selectedAcc.selected = true;
    this.materialService.overwriteSelectedMaterial(this._node, this._selectedAcc, true);
    this.accessoriesChanged.next(this._accessories);
    this.needsRender.next();
  }

  public deselectCurrentAccessory() {
    if (this._selectedAcc) {
      this.utilsService.enableControls();
      this.materialsService.overwriteSelectedMaterial(this._node, this._selectedAcc, false);
      this._selectedAcc.selected = false;
      this._selectedAcc = undefined;
      this.accessoriesChanged.next(this._accessories);
      this.needsRender.next();
    }
  }

  public moveAccessory(ev) {
    if (this._selectedAcc) {
      this.utilsService.disableControls();
      const intersect = this.utilsService.getMouseIntersections(['environment', 'border', 'plate', 'ground', 'stone'], ev);
      if (intersect) {
        this._selectedAcc.setPosition({
          x: intersect.point.x,
          y: intersect.point.y,
          z: intersect.point.z
        });
        this._update(this._selectedAcc);
      }
    }
  }

  public stopMovingAccessory(ev) {
    this.utilsService.enableControls();
  }

  /**
   * Returns true if an accessory is currently selected, false otherwise
   */
  public hasSelected(): boolean {
    const foundAcc = this._accessories.find(acc => acc.selected === true);
    return !!foundAcc;
  }

  protected _update(updateValues: Partial<Accessory>) {
    if (updateValues && updateValues.position) {
      const mesh = this.utilsService.getMeshByUID(this._node, updateValues.uid);
        if (updateValues.position.x == 0 && updateValues.position.z == 0) {
          let ground = this.utilsService.getGroundSize();
          updateValues.position.x = 0;
          updateValues.position.z = ground.width / 2;
        }
        let posVector = new Vector3(updateValues.position.x, updateValues.position.y, updateValues.position.z);
        try{
          if (updateValues.position.y === 0) {
            let intersections = this.utilsService.getObjectIntersections(
              ["ground", "border", "plate", "stone"],
              new Vector3(updateValues.position.x, 200, updateValues.position.z),
              new Vector3(0, -1, 0)
            );
            if (intersections && intersections[0]) {
              posVector.y = intersections[0].point.y
            }
          }
          updateValues.setPosition({
            x: posVector.x,
            y: posVector.y,
            z: posVector.z
          })
          mesh.position.copy(posVector);
        } catch(e) {

        }
    }
    this.needsRender.next();
  }

  protected _applyMaterial() {
    this._node.traverse(child => {
      if (child.type === 'Mesh' && (child.name.includes('accessoryHolder') || child.name.includes('Kreuz'))) {
        this.materialService.applyMaterial(child);
      }
    });
    this.needsRender.next();
  }

  getAccessory(uid: string): Accessory | undefined {
    for(let i = 0; i < this._accessories.length; i++) {
      if(this._accessories[i].uid === uid) {
        return this._accessories[i];
      }
    }
    return undefined;
  }

  getAccessoriesCopy() {
    return [...this._accessories];
  }

  private _accessories: Array<Accessory> = [];
  private _selectedAcc: Accessory;
}

