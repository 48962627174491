import { Injectable } from '@angular/core';
import { Box3, DoubleSide, Line, LineBasicMaterial, LineSegments, Sprite, SpriteMaterial, Texture, Vector3, Mesh, BufferAttribute, Object3D, BufferGeometry } from 'three';
import { UtilsService } from '../three-utils/utils.service';
import {Corner} from '../../../models/firestore/categories/plate/corner.enum';


@Injectable()
export class MeasurementsService {
  public node;
  public measurementsBool = false;

  constructor(private utilsService: UtilsService) {
  }

  makeLines(nod, borderValues, borderSize, groundSize, gravestoneBbox) {
    this.node = nod;
    if (this.node.children.length) {
      this.clearData(this.node);
    }
    this._linesProperties(borderSize, borderValues, groundSize, this.node, gravestoneBbox);
  }

  public getSizeOfGroup(group: any) {
    const bbox = new Box3();
    group.traverse((child) => {
      if (child.type === 'Mesh') {
        child.updateMatrixWorld();
        let tempMesh = child.clone();
        tempMesh.position.set(0, 0, 0);
        tempMesh.rotation.set(0, 0, 0);
        tempMesh.scale.set(1, 1, 1);
        tempMesh.updateMatrixWorld();
        const tempGeo = tempMesh.geometry.clone();
        tempGeo.applyMatrix4(child.matrixWorld);
        const tempBBox = this.getBoundingBox(tempGeo);

        if (bbox.max.x < tempBBox.max.x) {
          bbox.max.x = tempBBox.max.x;
        }
        if (bbox.max.y < tempBBox.max.y) {
          bbox.max.y = tempBBox.max.y;
        }
        if (bbox.max.z < tempBBox.max.z) {
          bbox.max.z = tempBBox.max.z;
        }

        if (bbox.min.x > tempBBox.min.x) {
          bbox.min.x = tempBBox.min.x;
        }
        if (bbox.min.y > tempBBox.min.y) {
          bbox.min.y = tempBBox.min.y;
        }
        if (bbox.min.z > tempBBox.min.z) {
          bbox.min.z = tempBBox.min.z;
        }
        tempMesh.geometry.dispose();
        tempMesh = null;
      }
    });
    return {
      width: -bbox.min.x + bbox.max.x,
      height: -bbox.min.y + bbox.max.y,
      length: -bbox.min.z + bbox.max.z
    };
  }

  public getSizeOfGroup2(group: any) {
    const bbox = new Box3();
    group.traverse((child) => {
      if (child.type === 'Mesh') {
        child.updateMatrixWorld();
        let tempMesh = child.clone();
        // tempMesh.position.set(0, 0, 0);
        tempMesh.updateMatrixWorld();
        const tempGeo = tempMesh.geometry.clone();
        tempGeo.applyMatrix4(tempMesh.matrixWorld);
        const tempBBox = this.getBoundingBox(tempGeo);

        if (bbox.max.x < tempBBox.max.x) {
          bbox.max.x = tempBBox.max.x;
        }
        if (bbox.max.y < tempBBox.max.y) {
          bbox.max.y = tempBBox.max.y;
        }
        if (bbox.max.z < tempBBox.max.z) {
          bbox.max.z = tempBBox.max.z;
        }

        if (bbox.min.x > tempBBox.min.x) {
          bbox.min.x = tempBBox.min.x;
        }
        if (bbox.min.y > tempBBox.min.y) {
          bbox.min.y = tempBBox.min.y;
        }
        if (bbox.min.z > tempBBox.min.z) {
          bbox.min.z = tempBBox.min.z;
        }
        tempMesh.geometry.dispose();
        tempMesh.material.dispose();
        tempGeo.dispose();
        tempMesh = null;
      }
    });
    return {
      width: -bbox.min.x + bbox.max.x,
      height: -bbox.min.y + bbox.max.y,
      length: -bbox.min.z + bbox.max.z
    };
  }

  public getBoundingBoxOfGroup(group: any) {
    const bbox = new Box3();
    group.traverse((child) => {
      if (child.type === 'Mesh') {
        child.updateMatrixWorld();
        let tempMesh = child.clone();
        tempMesh.position.set(0, 0, 0);
        // tempMesh.rotation.set(0, 0, 0);
        tempMesh.updateMatrixWorld();
        const tempGeo = tempMesh.geometry.clone();
        tempGeo.applyMatrix4(tempMesh.matrixWorld);

        const tempBBox = this.getBoundingBox(tempGeo);

        if (bbox.max.x < tempBBox.max.x) {
          bbox.max.x = tempBBox.max.x;
        }
        if (bbox.max.y < tempBBox.max.y) {
          bbox.max.y = tempBBox.max.y;
        }
        if (bbox.max.z < tempBBox.max.z) {
          bbox.max.z = tempBBox.max.z;
        }

        if (bbox.min.x > tempBBox.min.x) {
          bbox.min.x = tempBBox.min.x;
        }
        if (bbox.min.y > tempBBox.min.y) {
          bbox.min.y = tempBBox.min.y;
        }
        if (bbox.min.z > tempBBox.min.z) {
          bbox.min.z = tempBBox.min.z;
        }
        tempMesh.geometry.dispose();
        tempMesh.material.dispose();
        tempMesh = null;
      }
    });
    return bbox;
  }

  public getBoundingBox(geometry: any) {
    geometry.computeBoundingBox();
    return geometry.boundingBox;
  }

  public showMeasurements(value) {
    this._dimensionVisibility = value;
  }

  public getBoundingBoxOfPlate(mesh: any) {
    let targetMesh = mesh.children[0].children[0]
    mesh.children[0].updateMatrixWorld();
    let tempGeo = targetMesh.geometry.clone();
    tempGeo.applyMatrix4(targetMesh.matrixWorld);

    const bbox = new Box3();
    const tempBBox = this.getBoundingBox(tempGeo);

    if (bbox.max.x < tempBBox.max.x) {
      bbox.max.x = tempBBox.max.x;
    }
    if (bbox.max.y < tempBBox.max.y) {
      bbox.max.y = tempBBox.max.y;
    }
    if (bbox.max.z < tempBBox.max.z) {
      bbox.max.z = tempBBox.max.z;
    }

    if (bbox.min.x > tempBBox.min.x) {
      bbox.min.x = tempBBox.min.x;
    }
    if (bbox.min.y > tempBBox.min.y) {
      bbox.min.y = tempBBox.min.y;
    }
    if (bbox.min.z > tempBBox.min.z) {
      bbox.min.z = tempBBox.min.z;
    }
    tempGeo.dispose();
    tempGeo = null;

    return bbox;
  }



  public deleteMeasurements() {
    // if (this.node) this.disposeElement(this.node);
  }
  private _plateNode;
  public drawPlateMeasurements(node: Object3D, remove: boolean = false) {
    this._plateNode = this._getNodeByName(node.parent, 'measurements-plate');
    if (remove && this._plateNode && this._plateNode.children.length > 0) {
      for (var i = this._plateNode.children.length - 1; i >= 0; i--) {
        this._plateNode.remove(this._plateNode.children[i])
      }
      return;
    }
    if (!node.children[0]) {
      this.disposeElement(this._plateNode);
      return;
    }
    if (this._dimensionVisibility && this._dimensionVisibility['plate']) {
      this.disposeElement(this._plateNode);
      for (let i = 0; i < node.children.length; i++) {
        if(node.children[i].userData.dimensionLines && node.children[i].userData.dimensionLines.length > 0) {
          this.drawCustomeMeasurementsForPlate(node.children[i]);
        } else {
          const element = node.children[i].children[0];
          this.drawDefaultMeasurementsForPlate(element as Mesh);
        }
      }
    } else if (this._plateNode.children.length > 0) {
      this.disposeElement(this._plateNode);
    }

  }

  public clearAll() {
    this.disposeElement(this._plateNode);
  }

  private drawCustomeMeasurementsForPlate(plate) {
    let currentSize = this.utilsService.getTransformedSizesOfGroup(plate);
    let currentBbox = this.utilsService.getTransformedBBoxOfGroup(plate);
    for (let index = 0; index < plate.userData.dimensionLines.length; index++) {
      const element = plate.userData.dimensionLines[index];
      /* Fix for bottom/top plates - dimensions need to be inverted, also in admin panel corner must always be TOP */
      if (plate.userData.corner === Corner.BOTTOM) {
        if (element.axis === "Right") {
          element.axis = "Left"
        } else if (element.axis === "Left") {
          element.axis = "Right"
        }
      }
      if (element.from >= 0 && element.to >= 0 && element.axis) {
        let maxY = 0;
        let offsetHeight = 0;
        const color = '#4a4a4a';
        const textSize = 50;
        const meshFromOri = plate.children[element.meshIDFrom];
        if (!meshFromOri) return;

        let meshFrom = plate.children[element.meshIDFrom].clone();
        meshFrom.applyMatrix4(meshFromOri.matrixWorld);


        const meshToOri = plate.children[element.meshIDTo];
        if (!meshToOri) return;
        let meshTo = meshToOri.clone();
        meshTo.applyMatrix4(meshToOri.matrixWorld);

        const vectorFromOri = new Vector3(
          meshFrom.geometry.attributes.position.array[element.from * 3],
          meshFrom.geometry.attributes.position.array[element.from * 3 + 1],
          meshFrom.geometry.attributes.position.array[element.from * 3 + 2]
        );
        if (!vectorFromOri) return;

        const vectorFrom = vectorFromOri.clone();
        vectorFrom.applyMatrix4(meshFrom.matrixWorld);

        const vectorToOri = new Vector3(
          meshTo.geometry.attributes.position.array[element.to * 3],
          meshTo.geometry.attributes.position.array[element.to * 3 + 1],
          meshTo.geometry.attributes.position.array[element.to * 3 + 2]
        );
        if (!vectorToOri) return;
        const vectorTo = vectorToOri.clone();
        vectorTo.applyMatrix4(meshTo.matrixWorld);

        // const distance = Math.round(vectorFrom.distanceTo(vectorTo));
        let defaultOffset = 6;
        if (element.offset) {
          defaultOffset += element.offset;
        }
        let axis = 'X';
        maxY = (vectorFrom.y + vectorTo.y) / 2;
        if(element.offsetHeight){
          offsetHeight += element.offsetHeight;
        }
        if(maxY + offsetHeight < 0.2){
          offsetHeight = 0;
          maxY = 0.1;
        }
        switch (element.axis) {
          case 'Top':
            // vectorFrom.z = -defaultOffset;
            vectorFrom.z = vectorTo.z = currentBbox.min.z - defaultOffset;
            vectorFrom.y = vectorTo.y = maxY + offsetHeight;
            break;
          case 'Bottom':
            // vectorFrom.z = currentSize.length + defaultOffset;
            vectorFrom.z = vectorTo.z = currentBbox.max.z + defaultOffset;
            vectorFrom.y = vectorTo.y = maxY + offsetHeight;
            break;
          case 'Left':
            vectorFrom.x = -currentSize.width / 2 - defaultOffset;
            vectorTo.x = -currentSize.width / 2 - defaultOffset;
            vectorFrom.y = vectorTo.y = maxY + offsetHeight;
            axis = 'Z';
            break;
          case 'Right':
            vectorFrom.x = currentSize.width / 2 + defaultOffset;
            vectorTo.x = currentSize.width / 2 + defaultOffset;
            vectorFrom.y = vectorTo.y = maxY + offsetHeight;
            axis = 'Z';
            break;
          case 'Horizontal':
            vectorFrom.y = vectorTo.y = maxY + offsetHeight;
            break;
          case 'Top Left':
            vectorFrom.x = vectorTo.x = -currentSize.width / 2 - defaultOffset;
            vectorFrom.z = vectorTo.z = currentBbox.min.z - defaultOffset;
            // vectorFrom.x = vectorTo.x = currentSize.width / 2 + defaultOffset ;
            // vectorFrom.z = vectorTo.z = currentSize.length/2 ;
            axis = 'Z';
            break;
          case 'Top Right':
            vectorFrom.x = vectorTo.x = currentSize.width / 2 + defaultOffset;
            axis = 'Z';
            vectorFrom.z = vectorTo.z = currentBbox.min.z - defaultOffset;
            break;
          case 'Bottom Left':
            // vectorFrom.x = vectorTo.x = -currentSize.width / 2 - defaultOffset ;
            // vectorFrom.z = vectorTo.z = currentSize.length + defaultOffset ;

            vectorFrom.x = vectorTo.x = -currentSize.width / 2 - defaultOffset;
            axis = 'Z';
            vectorFrom.z = vectorTo.z = currentBbox.max.z + defaultOffset;
            break;
          case 'Bottom Right':
            vectorFrom.x = vectorTo.x = currentSize.width / 2 + defaultOffset;
            axis = 'Z';
            vectorFrom.z = vectorTo.z = currentBbox.max.z + defaultOffset;
            break;
        }

        let distance = Math.round(new Vector3(vectorFrom.x, vectorFrom.y, vectorFrom.z)
          .distanceTo(new Vector3(vectorTo.x, vectorTo.y, vectorTo.z)));
        this._drawLineGeometry(
          {
            x: vectorFrom.x,
            y: vectorFrom.y,
            z: vectorFrom.z
          },
          {
            x: vectorTo.x,
            y: vectorTo.y,
            z: vectorTo.z
          },
          axis, element.selected ? 0xff0000 : null, this._plateNode
        );

        const text9 = this._makeText(
          distance, // text
          512, 256, 0, // size
          (vectorFrom.x + vectorTo.x) / 2, (vectorFrom.y + vectorTo.y) / 2, (vectorFrom.z + vectorTo.z) / 2,  // position
          0, 0, 0, // rotation
          textSize, // size
          color // color
        );
        this._plateNode.add(text9);
        meshFrom.geometry.dispose();
        meshFrom.material.dispose();
        meshFrom = null;
        meshTo.geometry.dispose();
        meshTo.material.dispose();
        meshTo = null;

      }
    }
  }

  private drawDefaultMeasurementsForPlate(plate: Mesh) {
    const offset = 10;
    const textOffset = 0;
    const color = '#4a4a4a';
    const textSize = 50;
    const plateBBox = this.utilsService.getBoundingBoxOfTransformedMesh(plate)
    let theZ = plateBBox.max.z + offset;

    if (plate.userData.corner === 'top-right' || plate.userData.corner === 'top-left') {
      theZ = plateBBox.min.z - offset;
    }

    this._drawLineGeometry(
      {
        x: plateBBox.min.x,
        y: plateBBox.max.y,
        z: theZ
      },
      {
        x: plateBBox.max.x,
        y: plateBBox.max.y,
        z: theZ
      },
      'X', null, this._plateNode
    );
    const longo = Math.round((-plateBBox.min.x + plateBBox.max.x));
    const text9 = this._makeText(
      longo, // text
      512, 256, 0, // size
      plateBBox.min.x + longo / 2, plateBBox.max.y + textOffset, theZ, // position
      0, 0, 0, // rotation
      textSize, // size
      color // color
    );
    this._plateNode.add(text9);

    let theX = plateBBox.min.x - offset;

    if (plate.userData.corner === 'bottom-right' || plate.userData.corner === 'top-right') {
      theX = plateBBox.max.x + offset;
    }
    this._drawLineGeometry(
      {
        x: theX,
        y: plateBBox.max.y,
        z: plateBBox.min.z
      },
      {
        x: theX,
        y: plateBBox.max.y,
        z: plateBBox.max.z
      },
      'Z', null, this._plateNode
    );
    const longo2 = Math.round((-plateBBox.min.z + plateBBox.max.z));
    const text10 = this._makeText(
      longo2, // text
      512, 256, 0, // size
      theX - textOffset, plateBBox.max.y + textOffset, plateBBox.max.z - longo2 / 2, // position
      0, Math.PI / 2, 0, // rotation
      textSize, // size
      color // color
    );
    this._plateNode.add(text10);
  }

  private drawCustomeMeasurements(object, objNode) {
    let currentSize = this.utilsService.getTransformedSizesOfGroup(object);
    let currentBbox = this.utilsService.getTransformedBBoxOfGroup(object);
    for (let index = 0; index < object.userData.dimensionLines.length; index++) {
      let values = object.userData;
      const element = object.userData.dimensionLines[index];
      if (element.from >= 0 && element.to >= 0 && element.axis) {
        let maxY = 0;
        let offsetHeight = 0;
        const color = '#4a4a4a';
        const textSize = 50;
        const meshFromOri = object.children[element.meshIDFrom];
        if (!meshFromOri) return;

        let meshFrom = object.children[element.meshIDFrom].clone();
        meshFrom.applyMatrix4(meshFromOri.matrixWorld);


        const meshToOri = object.children[element.meshIDTo];
        if (!meshToOri) return;
        let meshTo = meshToOri.clone();
        meshTo.applyMatrix4(meshToOri.matrixWorld);

        const vectorFromOri = new Vector3(
          meshFrom.geometry.attributes.position.array[element.from * 3],
          meshFrom.geometry.attributes.position.array[element.from * 3 + 1],
          meshFrom.geometry.attributes.position.array[element.from * 3 + 2]
        );
        if (!vectorFromOri) return;

        const vectorFrom = vectorFromOri.clone();
        vectorFrom.applyMatrix4(meshFrom.matrixWorld);

        const vectorToOri = new Vector3(
          meshTo.geometry.attributes.position.array[element.to * 3],
          meshTo.geometry.attributes.position.array[element.to * 3 + 1],
          meshTo.geometry.attributes.position.array[element.to * 3 + 2]
        );
        if (!vectorToOri) return;
        const vectorTo = vectorToOri.clone();
        vectorTo.applyMatrix4(meshTo.matrixWorld);

        // const distance = Math.round(vectorFrom.distanceTo(vectorTo));
        let defaultOffset = 6;
        if (element.offset) {
          defaultOffset += element.offset;
        }
        let axis = 'X';
        maxY = (vectorFrom.y + vectorTo.y) / 2;
        if(element.offsetHeight){
          offsetHeight += element.offsetHeight;
        }
        if(maxY + offsetHeight < 0.2){
          offsetHeight = 0;
          maxY = 0.1;
        }
        switch (element.axis) {
          case 'Top':
            // vectorFrom.z = -defaultOffset;
            vectorFrom.z = vectorTo.z = currentBbox.min.z - defaultOffset;
            vectorFrom.y = vectorTo.y = maxY + offsetHeight;
            break;
          case 'Bottom':
            // vectorFrom.z = currentSize.length + defaultOffset;
            vectorFrom.z = vectorTo.z = currentBbox.max.z + defaultOffset;
            vectorFrom.y = vectorTo.y = maxY + offsetHeight;
            break;
          case 'Left':
            vectorFrom.x = -currentSize.width / 2 - defaultOffset;
            vectorTo.x = -currentSize.width / 2 - defaultOffset;
            vectorFrom.y = vectorTo.y = maxY + offsetHeight;
            axis = 'Z';
            break;
          case 'Right':
            vectorFrom.x = currentSize.width / 2 + defaultOffset;
            vectorTo.x = currentSize.width / 2 + defaultOffset;
            vectorFrom.y = vectorTo.y = maxY + offsetHeight;
            axis = 'Z';
            break;
          case 'Horizontal':
            vectorFrom.y = vectorTo.y = maxY + offsetHeight;
            break;
          case 'Top Left':
            vectorFrom.x = vectorTo.x = -currentSize.width / 2 - defaultOffset;
            vectorFrom.z = vectorTo.z = currentBbox.min.z - defaultOffset;
            // vectorFrom.x = vectorTo.x = currentSize.width / 2 + defaultOffset ;
            // vectorFrom.z = vectorTo.z = currentSize.length/2 ;
            axis = 'Z';
            break;
          case 'Top Right':
            vectorFrom.x = vectorTo.x = currentSize.width / 2 + defaultOffset;
            axis = 'Z';
            vectorFrom.z = vectorTo.z = currentBbox.min.z - defaultOffset;
            break;
          case 'Bottom Left':
            // vectorFrom.x = vectorTo.x = -currentSize.width / 2 - defaultOffset ;
            // vectorFrom.z = vectorTo.z = currentSize.length + defaultOffset ;

            vectorFrom.x = vectorTo.x = -currentSize.width / 2 - defaultOffset;
            axis = 'Z';
            vectorFrom.z = vectorTo.z = currentBbox.max.z + defaultOffset;
            break;
          case 'Bottom Right':
            vectorFrom.x = vectorTo.x = currentSize.width / 2 + defaultOffset;
            axis = 'Z';
            vectorFrom.z = vectorTo.z = currentBbox.max.z + defaultOffset;
            break;
        }

        let distance = Math.round(new Vector3(vectorFrom.x, vectorFrom.y, vectorFrom.z)
          .distanceTo(new Vector3(vectorTo.x, vectorTo.y, vectorTo.z)));
        this._drawLineGeometry(
          {
            x: vectorFrom.x,
            y: vectorFrom.y,
            z: vectorFrom.z
          },
          {
            x: vectorTo.x,
            y: vectorTo.y,
            z: vectorTo.z
          },
          axis, element.selected ? 0xff0000 : null, objNode
        );

        const text9 = this._makeText(
          distance, // text
          512, 256, 0, // size
          (vectorFrom.x + vectorTo.x) / 2, (vectorFrom.y + vectorTo.y) / 2, (vectorFrom.z + vectorTo.z) / 2,  // position
          0, 0, 0, // rotation
          textSize, // size
          color // color
        );
        this._borderNode.add(text9);
        meshFrom.geometry.dispose();
        meshFrom.material.dispose();
        meshFrom = null;
        meshTo.geometry.dispose();
        meshTo.material.dispose();
        meshTo = null;

      }
    }
  }

  private _borderNode;
  public drawBorderMeasurements(node: Object3D, remove: boolean = false){
    this._borderNode = this._getNodeByName(node.parent, 'measurements-border');

    if (remove && this._borderNode && this._borderNode.children.length > 0) {
      for (var i = this._borderNode.children.length - 1; i >= 0; i--) {
        this._borderNode.remove(this._borderNode.children[i])
      }
      this._dimensionVisibility['border'] = false;
      return;
    }
    if (!node.children[0]) return;
    if (this._dimensionVisibility && this._dimensionVisibility['border']) {
      this.disposeElement(this._borderNode);
      if (node.children[0].userData.dimensionLines && node.children[0].userData.dimensionLines.length > 0) {
        this.drawCustomeMeasurements(node.children[0], this._borderNode);
      } else {
        let computedDim = new Box3();
        computedDim = this.getBoundingBoxOfGroup( node.children[0] );
        this.drawDefaultMeasurementsForBorder(computedDim);

      }

    } else if (this._borderNode.children.length > 0) {
      this.disposeElement(this._borderNode);
    }

  }

  private drawDefaultMeasurementsForBorder(borderBBox) {
    const offset = 10;
    const textOffset = 0;
    const color = '#4a4a4a';
    const textSize = 50;
    this._drawLineGeometry(
      {
        x: borderBBox.min.x,
        y: 0.05,
        z: borderBBox.max.z + offset
      },
      {
        x: borderBBox.max.x,
        y: 0.05,
        z: borderBBox.max.z + offset
      },
      'X', color, this._borderNode
    );
    const text1 = this._makeText(
      Math.round((-borderBBox.min.x + borderBBox.max.x)), // text
      512, 256, 0, // size
      0, 0.05, borderBBox.max.z + offset + textOffset, // position
      Math.PI / 2, -Math.PI, 0, // rotation
      textSize, // size
      color // color
    );
    this._borderNode.add(text1);

    this._drawLineGeometry(
      {
        x: borderBBox.max.x + offset,
        y: 0.05,
        z: borderBBox.min.z
      },
      {
        x: borderBBox.max.x + offset,
        y: 0.05,
        z: borderBBox.max.z
      },
      'Z', color, this._borderNode
    );

    const text2 = this._makeText(
      Math.round((-borderBBox.min.z + borderBBox.max.z)), // text
      512, 256, 0, // size
      borderBBox.max.x + offset + textOffset, 0.05, (-borderBBox.min.z + borderBBox.max.z) / 2, // position
      -Math.PI / 2, 0, -Math.PI / 2, // rotation
      textSize, // size
      color // color
    );
    this._borderNode.add(text2);

    this._drawLineGeometry(
      {
        x: borderBBox.max.x + offset,
        y: 0.0,
        z: -offset
      },
      {
        x: borderBBox.max.x + offset,
        y: borderBBox.max.y - borderBBox.min.y,
        z: -offset
      },
      'Y', color, this._borderNode
    );


    const text5 = this._makeText(
      Math.round(borderBBox.max.y - borderBBox.min.y), // text
      512, 256, 0, // size
      borderBBox.max.x + offset + textOffset, (borderBBox.max.y - borderBBox.min.y) / 2, -offset, // position
      0, 0, -Math.PI / 2, // rotation
      textSize, // size
      color // color
    );
    this._borderNode.add(text5);
  }

  private _stoneNode;
  public drawStoneMeasurements(node: Object3D, remove: boolean = false){
    this._stoneNode = this._getNodeByName(node.parent, 'measurements-stone');

    if (remove && this._stoneNode && this._stoneNode.children.length > 0) {
      for (var i = this._stoneNode.children.length - 1; i >= 0; i--) {
        this._stoneNode.remove(this._stoneNode.children[i])
      }
      this._dimensionVisibility['stone'] = false;
      return;
    }
    if (!node.children[0]) return;

    if (this._dimensionVisibility && this._dimensionVisibility['stone']) {
      this.disposeElement(this._stoneNode);
      if (node.children[0].userData.dimensionLines && node.children[0].userData.dimensionLines.length > 0) {
        this.drawCustomeMeasurements(node.children[0], this._borderNode);
      } else {
        let computedDim = new Box3();
        computedDim = this.getBoundingBoxOfGroup( node.children[0] );
        this.drawDefaultMeasurementsForStone(computedDim, node.children[0]);
      }

    } else if (this._stoneNode.children.length > 0) {
      this.disposeElement(this._stoneNode);
    }

  }

  private drawDefaultMeasurementsForStone(stoneBBox ,stone) {
    this._stoneNode.position.copy(stone.position);
    this._stoneNode.rotation.y = stone.rotation.y;
    const offset = 10 ;
    const textOffset = 0;
    const color = '#4a4a4a';
    const textSize = 50;
    this._drawLineGeometry(
      {
        x: stoneBBox.max.x + offset,
        y: stoneBBox.min.y,
        z: stoneBBox.min.z - offset
      },
      {
        x: stoneBBox.max.x + offset,
        y: stoneBBox.max.y,
        z: stoneBBox.min.z - offset
      },
      'Y', color, this._stoneNode
    );


    const text6 = this._makeText(
      Math.round(stoneBBox.max.y - stoneBBox.min.y), // text
      512, 256, 0, // size
      stoneBBox.max.x + offset + textOffset, (stoneBBox.max.y) / 2, stoneBBox.min.z - offset, // position
      0, 0, -Math.PI / 2, // rotation
      textSize, // size
      color // color
    );
    this._stoneNode.add(text6);

    this._drawLineGeometry(
      {
        x: stoneBBox.max.x + offset,
        y: stoneBBox.max.y,
        z: stoneBBox.min.z
      },
      {
        x: stoneBBox.max.x + offset,
        y: stoneBBox.max.y,
        z: stoneBBox.max.z
      },
      'Z', color, this._stoneNode
    );

    const msrtfrt = Math.round(stoneBBox.max.z - stoneBBox.min.z);
    const text7 = this._makeText(
      msrtfrt, // text
      512, 256, 0, // size
      stoneBBox.max.x + offset, stoneBBox.max.y + textOffset, stoneBBox.min.z + (msrtfrt / 2), // position
      0, Math.PI / 2, 0, // rotation
      textSize, // size
      color // color
    );
    this._stoneNode.add(text7);


    this._drawLineGeometry(
      {
        x: stoneBBox.min.x,
        y: stoneBBox.max.y,
        z: stoneBBox.min.z - offset
      },
      {
        x: stoneBBox.max.x,
        y: stoneBBox.max.y,
        z: stoneBBox.min.z - offset
      },
      'X', color, this._stoneNode
    );
    const smurf = Math.round(stoneBBox.max.x - stoneBBox.min.x);
    const text8 = this._makeText(
      smurf, // text
      512, 256, 0, // size
      stoneBBox.max.x - smurf / 2, stoneBBox.max.y + textOffset, stoneBBox.min.z - offset, // position
      0, 0, 0, // rotation
      textSize, // size
      color // color
    );
    this._stoneNode.add(text8);
  }

  public drawMeasurements(model, values) {
    if (!values || !values.type) return;
    const scene = model.parent;
    this.node = this._getNodeByName(scene, 'measurements-' + values.type);
    if (!values.name) {
      if (this.node) {
        this.disposeElement(this.node);
        this.node = null;
      }
      return;
    }


    if (!this.node) {
      return;
    }

    const color = '#4a4a4a';
    const textSize = 50;
    let object;
    if (this._dimensionVisibility[values.type]) {
      switch (values.type) {
        case 'border':
          object = model.children[0];
          break;
        case 'stone':
          object = model.children[0];
          break;
        case 'plate':
          object = model.children[0];
          break;
      }
    }
    this.disposeElement(this.node);

    if (!object) {
      return;
    }
    if (object.userData.dimensionLines && object.userData.dimensionLines.length > 0) {
      let maxY = 0;

      // const ground = this._getNodeByName(scene, "ground").children.length > 0 ? this._getNodeByName(scene, "ground") : null;
      let currentBbox;
      if (values.type === 'border') {
        currentBbox = this.getSizeOfGroup2(object);
      }
      if (values.type === 'stone') {
        currentBbox = this.getSizeOfGroup(object);
        // this.node.position.copy(object.position);
        this.node.rotation.y = object.rotation.y;
      }


      // let currentBbox = this.getSizeOfGroup(ground);
      for (let index = 0; index < object.userData.dimensionLines.length; index++) {
        const element = object.userData.dimensionLines[index];
        // if(!element.meshIDFrom) element.meshIDFrom = 0;
        // if(!element.meshIDTo) element.meshIDTo = 0;
        if (element.from >= 0 && element.to >= 0 && element.axis) {
          if (!object.children) {
            return;
          }
          const meshFromOri = object.children[element.meshIDFrom];
          if (!meshFromOri) {
            return;
          }

          let meshFrom = meshFromOri.clone();
          if (values.type === 'stone') {
            meshFrom.position.set(0, 0, 0);
          }

          // meshFrom.updateMatrixWorld();
          if (values.type === 'plate') {
            meshFrom.scale.set(model.children[0].scale.x, model.children[0].scale.y, model.children[0].scale.z);
            meshFrom.position.set(model.children[0].position.x, model.children[0].position.y, model.children[0].position.z);
            meshFrom.rotation.set(model.children[0].rotation.x, model.children[0].rotation.y, model.children[0].rotation.z);
          }

          const meshToOri = object.children[element.meshIDTo];
          if (!meshToOri) {
            return;
          }

          let meshTo = meshToOri.clone();
          if (values.type === 'stone') {
            meshTo.position.set(0, 0, 0);
          }
          // meshTo.updateMatrixWorld();
          if (values.type === 'plate') {
            meshTo.scale.set(model.children[0].scale.x, model.children[0].scale.y, model.children[0].scale.z);
            meshTo.position.set(model.children[0].position.x, model.children[0].position.y, model.children[0].scale.z);
            meshTo.rotation.set(model.children[0].rotation.x, model.children[0].rotation.y, model.children[0].rotation.z);
          }
          const vectorFromOri = new Vector3(meshFrom.geometry.attributes.position.array[element.from * 3], meshFrom.geometry.attributes.position.array[element.from * 3 + 1], meshFrom.geometry.attributes.position.array[element.from * 3 + 2]);
          if (!vectorFromOri) {
            return;
          }
          const vectorFrom = vectorFromOri.clone();
          vectorFrom.applyMatrix4(meshFrom.matrixWorld);

          const vectorToOri = new Vector3(meshTo.geometry.attributes.position.array[element.to * 3], meshTo.geometry.attributes.position.array[element.to * 3 + 1], meshTo.geometry.attributes.position.array[element.to * 3 + 2]);
          if (!vectorToOri) {
            return;
          }
          const vectorTo = vectorToOri.clone();
          vectorTo.applyMatrix4(meshTo.matrixWorld);

          let defaultOffset = 6;
          let defaultOffsetHeight = 0;
          if (element.offset) {
            defaultOffset += element.offset;
          }
          if(element.offsetHeight){
            defaultOffsetHeight += element.offsetHeight;
          }
          let axis = 'X';
          maxY = (vectorFrom.y + vectorTo.y) / 2;
          if(maxY + defaultOffsetHeight < 0.2){
            defaultOffsetHeight = 0;
            maxY = 0.1;
          }
          switch (element.axis) {
            case 'Top':
              // vectorFrom.z = -defaultOffset;
              vectorFrom.z = vectorTo.z = -defaultOffset;
              vectorFrom.y = vectorTo.y = maxY + defaultOffsetHeight;
              break;
            case 'Bottom':
              // vectorFrom.z = currentBbox.length + defaultOffset;
              vectorFrom.z = vectorTo.z = currentBbox.length + defaultOffset;
              vectorFrom.y = vectorTo.y = maxY + defaultOffsetHeight;
              break;
            case 'Left':
              vectorFrom.x = -currentBbox.width / 2 - defaultOffset;
              vectorTo.x = -currentBbox.width / 2 - defaultOffset;
              vectorFrom.y = vectorTo.y = maxY + defaultOffsetHeight;
              axis = 'Z';
              break;
            case 'Right':
              vectorFrom.x = currentBbox.width / 2 + defaultOffset;
              vectorTo.x = currentBbox.width / 2 + defaultOffset;
              vectorFrom.y = vectorTo.y = maxY + defaultOffsetHeight;
              axis = 'Z';
              break;
            case 'Horizontal':
              vectorFrom.y = vectorTo.y = maxY + defaultOffsetHeight;
              break;
            case 'Top Left':
              vectorFrom.x = vectorTo.x = -currentBbox.width / 2 - defaultOffset;
              vectorFrom.z = vectorTo.z = 0;
              // vectorFrom.x = vectorTo.x = currentBbox.width / 2 + defaultOffset ;
              // vectorFrom.z = vectorTo.z = currentBbox.length/2 ;
              axis = 'Z';
              break;
            case 'Top Right':
              vectorFrom.x = vectorTo.x = currentBbox.width / 2 + defaultOffset;
              axis = 'Z';
              vectorFrom.z = vectorTo.z = 0;
              break;
            case 'Bottom Left':
              // vectorFrom.x = vectorTo.x = -currentBbox.width / 2 - defaultOffset ;
              // vectorFrom.z = vectorTo.z = currentBbox.length + defaultOffset ;

              vectorFrom.x = vectorTo.x = -currentBbox.width / 2 - defaultOffset;
              axis = 'Z';
              vectorFrom.z = vectorTo.z = currentBbox.length;
              if (values.type === 'stone') {
                vectorFrom.z = vectorTo.z = currentBbox.length / 2;
              }
              break;
            case 'Bottom Right':
              vectorFrom.x = vectorTo.x = currentBbox.width / 2 + defaultOffset;
              axis = 'Z';
              vectorFrom.z = vectorTo.z = currentBbox.length;
              if (values.type === 'stone') {
                vectorFrom.z = vectorTo.z = currentBbox.length / 2;
              }
              break;
          }


          let distance = Math.round(new Vector3(vectorFrom.x, vectorFrom.y, vectorFrom.z)
            .distanceTo(new Vector3(vectorTo.x, vectorTo.y, vectorTo.z)));
          this._drawLineGeometry(
            {
              x: vectorFrom.x,
              y: vectorFrom.y,
              z: vectorFrom.z
            },
            {
              x: vectorTo.x,
              y: vectorTo.y,
              z: vectorTo.z
            },
            axis, element.selected ? 0xff0000 : null, this.node
          );

          const text9 = this._makeText(
            distance, // text
            512, 256, 0, // size
            (vectorFrom.x + vectorTo.x) / 2, (vectorFrom.y + vectorTo.y) / 2, (vectorFrom.z + vectorTo.z) / 2,  // position
            0, 0, 0, // rotation
            textSize, // size
            color // color
          );
          this.node.add(text9);
          meshFrom.geometry.dispose();
          meshFrom.material.dispose();
          meshFrom = null;
          meshTo.geometry.dispose();
          meshTo.material.dispose();
          meshTo = null;
        }


      }

      return;
    } else {
      switch (values.type) {
        case 'border':
          this.drawBorder(object);
          break;
        case 'stone':
          this.drawStone(object);
          break;
        case 'plate':
          this.drawDefaultMeasurementsForPlate(model);
          break;
      }
    }

  }

  public disposeElement(object: any): void {
    // if (object.name === 'measurements-plate') {
    //   for (let index = 0; index < object.children.length; index++) {
    //     const element = object.children[index];
    //     for (let j = element.children.length - 1; j >= 0; j--) {
    //       const child = element.children[j];
    //       element.remove(child);
    //     }
    //   }
    // } else {
    const i = object.children.length - 1;
    while (object.children.length > 0) {
      object.children.forEach(element => {
        element.traverse((child) => {
          if (child.geometry) {
            child.geometry.dispose();
          }
          object.remove(child);
        });
      });
    }
    // }
  }

  public clearData(measurements) {
    let i = measurements.children.length - 1;
    while (measurements.children.length > 0) {
      if (measurements.children[i].material) {
        measurements.children[i].material.dispose();
      }
      if (measurements.children[i].geometry) {
        measurements.children[i].geometry.dispose();
        measurements.remove(measurements.children[i]);
        i--;
      }
    }
  }

  private _makeText(text, x, y, z, posX, posY, posZ, rotX, rotY, rotZ, size, color) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = x;
    canvas.height = y;
    context.font = size + 'px Arial';
    context.textAlign = 'center';
    context.textBaseline = 'bottom';
    const textMetrics = context.measureText(text);
    const textHeight = textMetrics['actualBoundingBoxAscent'] - textMetrics['actualBoundingBoxDescent'];
    const textWidth = textMetrics.width + 20;
    context.beginPath();
    context.fillStyle = '#f7f7f7';
    context.rect(canvas.width / 2 - textWidth / 2, canvas.height / 2 - textHeight, textWidth, textHeight);
    context.fill();
    // context.stroke();

    context.fillStyle = '#4f4f52';
    context.fillText(text, canvas.width / 2, canvas.height / 2);

    const texture = new Texture(canvas);
    texture.needsUpdate = true;
    texture.anisotropy = 16;

    const spriteMaterial = new SpriteMaterial({ map: texture, color: 0xffffff, opacity: 0.8, alphaTest: 0.5, side: DoubleSide });
    const sprite = new Sprite(spriteMaterial);

    sprite.position.set(posX, posY, posZ);
    sprite.scale.set(canvas.width / 8, canvas.height / 8, 0.1);
    return sprite;
  }

  private _textGeometry(posX, posY, posZ, rotX, rotY, rotZ, dim, node) {
    const text = this._makeText(dim + ' cm', 256, 128, -10, posX, posY, posZ, rotX, rotY, rotZ, 64, 'black');
    node.add(text);
  }

  private _lineGeometry(start, end, node, axis) {
    const thickness = 2;
    const lineGeometry = new BufferGeometry();
    const firstCapGeometry = new BufferGeometry();
    const secondCapGeometry = new BufferGeometry();
    const material = new LineBasicMaterial({ color: 0x525252 });
    let lineGeometryArr = new Float32Array([
      parseFloat(start.x) , parseFloat(start.y), parseFloat(start.z), parseFloat(end.x), parseFloat(end.y), parseFloat(end.z)
    ])
    let firstCapGeometryArr
    let secondCapGeometryArr
    lineGeometry.setAttribute( 'position', new BufferAttribute( lineGeometryArr, 3 ) );


    switch (axis) {
      case 'X':
        firstCapGeometryArr = new Float32Array([parseFloat(start.x) , parseFloat(start.y), parseFloat((start.z - thickness).toString()),
          parseFloat(start.x), parseFloat(start.y), parseFloat((start.z + thickness).toString())])
       firstCapGeometry.setAttribute( 'position', new BufferAttribute( firstCapGeometryArr, 3 ) );

       secondCapGeometryArr = new Float32Array([parseFloat(end.x) , parseFloat(end.y), parseFloat((end.z - thickness).toString()),
         parseFloat(end.x), parseFloat(end.y), parseFloat((end.z + thickness).toString())])
       secondCapGeometry.setAttribute( 'position', new BufferAttribute( secondCapGeometryArr, 3 ) );
        break;
      case 'Y':
          firstCapGeometryArr = new Float32Array([parseFloat(start.x) , parseFloat(start.y), parseFloat((start.z - thickness).toString()),
          parseFloat(start.x), parseFloat(start.y), parseFloat((start.z + thickness).toString())])
       firstCapGeometry.setAttribute( 'position', new BufferAttribute( firstCapGeometryArr, 3 ) );

       secondCapGeometryArr = new Float32Array([parseFloat(end.x) , parseFloat(end.y), parseFloat((end.z - thickness).toString()),
         parseFloat(end.x), parseFloat(end.y), parseFloat((end.z + thickness).toString())])
       secondCapGeometry.setAttribute( 'position', new BufferAttribute( secondCapGeometryArr, 3 ) );
        break;
      case 'Z':
        firstCapGeometryArr = new Float32Array([parseFloat((start.x - thickness).toString()) , parseFloat(start.y), parseFloat(start.z),
          parseFloat((start.x + thickness).toString()), parseFloat(start.y), parseFloat(start.z)])
        firstCapGeometry.setAttribute( 'position', new BufferAttribute( firstCapGeometryArr, 3 ) );

        secondCapGeometryArr = new Float32Array([parseFloat((end.x - thickness).toString()) , parseFloat(end.y), parseFloat(end.z),
          parseFloat((end.x + thickness).toString()), parseFloat(end.y), parseFloat(end.z)])
        secondCapGeometry.setAttribute( 'position', new BufferAttribute( secondCapGeometryArr, 3 ) );
        break;
    }

    const line = new LineSegments(lineGeometry, material);
    const firstCap = new Line(firstCapGeometry, material);
    const secondCap = new Line(secondCapGeometry, material);
    line.position.y = 0.2;
    firstCap.position.y = 0.2;
    secondCap.position.y = 0.2;
    node.add(firstCap);
    node.add(secondCap);
    node.add(line);

    // line.needsUpdate = true; // todo: ThreeJS Migration
    line.matrixWorldNeedsUpdate = true;
  }

  private drawBorder(border) {
    const offset = 10;
    const textOffset = 0;
    const color = '#4a4a4a';
    const textSize = 50;
    const borderBBox = this.getBoundingBoxOfGroup(border);
    this._drawLineGeometry(
      {
        x: borderBBox.min.x,
        y: 0.05,
        z: borderBBox.max.z + offset
      },
      {
        x: borderBBox.max.x,
        y: 0.05,
        z: borderBBox.max.z + offset
      },
      'X'
    );
    const text1 = this._makeText(
      Math.round((-borderBBox.min.x + borderBBox.max.x)), // text
      512, 256, 0, // size
      0, 0.05, borderBBox.max.z + offset + textOffset, // position
      Math.PI / 2, -Math.PI, 0, // rotation
      textSize, // size
      color // color
    );
    this.node.add(text1);

    this._drawLineGeometry(
      {
        x: borderBBox.max.x + offset,
        y: 0.05,
        z: borderBBox.min.z
      },
      {
        x: borderBBox.max.x + offset,
        y: 0.05,
        z: borderBBox.max.z
      },
      'Z'
    );

    const text2 = this._makeText(
      Math.round((-borderBBox.min.z + borderBBox.max.z)), // text
      512, 256, 0, // size
      borderBBox.max.x + offset + textOffset, 0.05, (-borderBBox.min.z + borderBBox.max.z) / 2, // position
      -Math.PI / 2, 0, -Math.PI / 2, // rotation
      textSize, // size
      color // color
    );
    this.node.add(text2);
    if (border.children[0] && border.children[0].userData && border.children[0].userData.category !== 'fixed') {
      this._drawLineGeometry(
        {
          x: borderBBox.max.x + offset,
          y: 0.0,
          z: -offset
        },
        {
          x: borderBBox.max.x + offset,
          y: border.children[0].userData.monumentHolderHeight,
          z: -offset
        },
        'Z'
      );
      const text3 = this._makeText(
        Math.round(border.children[0].userData.monumentHolderHeight), // text
        512, 256, 0, // size
        borderBBox.max.x + offset + textOffset, border.children[0].userData.monumentHolderHeight / 2, -offset, // position
        0, 0, -Math.PI / 2, // rotation
        textSize, // size
        color // color
      );
      this.node.add(text3);
      if (border.children[0].userData.borderHeight > 0) {
        this._drawLineGeometry(
          {
            x: borderBBox.min.x - offset,
            y: 0.0,
            z: borderBBox.max.z + offset
          },
          {
            x: borderBBox.min.x - offset,
            y: border.children[0].userData.borderHeight,
            z: borderBBox.max.z + offset
          },
          'Z'
        );
        const text4 = this._makeText(
          Math.round(border.children[0].userData.borderHeight), // text
          512, 256, 0, // size
          borderBBox.min.x - offset - textOffset, border.children[0].userData.borderHeight / 2, borderBBox.max.z + offset, // position
          0, 0, Math.PI / 2, // rotation
          textSize, // size
          color // color
        );
        this.node.add(text4);
      }

    } else {
      this._drawLineGeometry(
        {
          x: borderBBox.max.x + offset,
          y: 0.0,
          z: -offset
        },
        {
          x: borderBBox.max.x + offset,
          y: borderBBox.max.y - borderBBox.min.y,
          z: -offset
        },
        'Y'
      );

      const text5 = this._makeText(
        Math.round(borderBBox.max.y - borderBBox.min.y), // text
        512, 256, 0, // size
        borderBBox.max.x + offset + textOffset, (borderBBox.max.y - borderBBox.min.y) / 2, -offset, // position
        0, 0, -Math.PI / 2, // rotation
        textSize, // size
        color // color
      );
      this.node.add(text5);
    }
  }

  private drawStone(stone) {
    this.node.position.copy(stone.position);
    this.node.rotation.y = stone.rotation.y;
    const offset = 10;
    const textOffset = 0;
    const color = '#4a4a4a';
    const textSize = 50;
    const stoneBBox = this.getBoundingBoxOfGroup(stone.children[0]);
    this._drawLineGeometry(
      {
        x: stoneBBox.max.x + offset,
        y: stoneBBox.min.y,
        z: stoneBBox.min.z - offset
      },
      {
        x: stoneBBox.max.x + offset,
        y: stoneBBox.max.y,
        z: stoneBBox.min.z - offset
      },
      'Y'
    );


    const text6 = this._makeText(
      Math.round(stoneBBox.max.y - stoneBBox.min.y), // text
      512, 256, 0, // size
      stoneBBox.max.x + offset + textOffset, (stoneBBox.max.y) / 2, stoneBBox.min.z - offset, // position
      0, 0, -Math.PI / 2, // rotation
      textSize, // size
      color // color
    );
    this.node.add(text6);

    this._drawLineGeometry(
      {
        x: stoneBBox.max.x + offset,
        y: stoneBBox.max.y,
        z: stoneBBox.min.z
      },
      {
        x: stoneBBox.max.x + offset,
        y: stoneBBox.max.y,
        z: stoneBBox.max.z
      },
      'Z'
    );

    const msrtfrt = Math.round(stoneBBox.max.z - stoneBBox.min.z);
    const text7 = this._makeText(
      msrtfrt, // text
      512, 256, 0, // size
      stoneBBox.max.x + offset, stoneBBox.max.y + textOffset, stoneBBox.min.z + (msrtfrt / 2), // position
      0, Math.PI / 2, 0, // rotation
      textSize, // size
      color // color
    );
    this.node.add(text7);


    this._drawLineGeometry(
      {
        x: stoneBBox.min.x,
        y: stoneBBox.max.y,
        z: stoneBBox.min.z - offset
      },
      {
        x: stoneBBox.max.x,
        y: stoneBBox.max.y,
        z: stoneBBox.min.z - offset
      },
      'X'
    );
    const smurf = Math.round(stoneBBox.max.x - stoneBBox.min.x);
    const text8 = this._makeText(
      smurf, // text
      512, 256, 0, // size
      stoneBBox.max.x - smurf / 2, stoneBBox.max.y + textOffset, stoneBBox.min.z - offset, // position
      0, 0, 0, // rotation
      textSize, // size
      color // color
    );
    this.node.add(text8);
  }

  private _drawLineGeometry(start, end, axis, color?, node?) {
    const defaultColor = 0x000000;
    let lineGeometryArr = new Float32Array()
    let firstCapGeomArr
    let secondCapGeomArr
    const thickness = 3;
    const lineGeometry = new BufferGeometry();
    const firstCapGeometry = new BufferGeometry();
    const secondCapGeometry = new BufferGeometry();
    const material = new LineBasicMaterial({ color: defaultColor });
    lineGeometryArr = new Float32Array([
      parseFloat(start.x) , parseFloat(start.y), parseFloat(start.z), parseFloat(end.x), parseFloat(end.y), parseFloat(end.z)
    ])

    lineGeometry.setAttribute( 'position', new BufferAttribute( lineGeometryArr, 3 ) );

    switch (axis) {
      case 'X':
        firstCapGeomArr = new Float32Array([parseFloat(start.x) , parseFloat(start.y), parseFloat((start.z - thickness).toString()),
           parseFloat(start.x), parseFloat(start.y), parseFloat((start.z + thickness).toString())])
        firstCapGeometry.setAttribute( 'position', new BufferAttribute( firstCapGeomArr, 3 ) );

        secondCapGeomArr = new Float32Array([parseFloat(end.x) , parseFloat(end.y), parseFloat((end.z - thickness).toString()),
          parseFloat(end.x), parseFloat(end.y), parseFloat((end.z + thickness).toString())])
        secondCapGeometry.setAttribute( 'position', new BufferAttribute( secondCapGeomArr, 3 ) );
        break;
      case 'Y':
        firstCapGeomArr = new Float32Array([parseFloat(start.x) , parseFloat(start.y), parseFloat((start.z - thickness).toString()),
          parseFloat(start.x), parseFloat(start.y), parseFloat((start.z + thickness).toString())])
        firstCapGeometry.setAttribute( 'position', new BufferAttribute( firstCapGeomArr, 3 ) );

        secondCapGeomArr = new Float32Array([parseFloat(end.x) , parseFloat(end.y), parseFloat((end.z - thickness).toString()),
          parseFloat(end.x), parseFloat(end.y), parseFloat((end.z + thickness).toString())])
        secondCapGeometry.setAttribute( 'position', new BufferAttribute( secondCapGeomArr, 3 ) );
        break;
      case 'Z':
        firstCapGeomArr = new Float32Array([parseFloat((start.x - thickness).toString()) , parseFloat(start.y), parseFloat(start.z),
          parseFloat((start.x + thickness).toString()), parseFloat(start.y), parseFloat(start.z)])
        firstCapGeometry.setAttribute( 'position', new BufferAttribute( firstCapGeomArr, 3 ) );

        secondCapGeomArr = new Float32Array([parseFloat((end.x - thickness).toString()) , parseFloat(end.y), parseFloat(end.z),
          parseFloat((end.x + thickness).toString()), parseFloat(end.y), parseFloat(end.z)])
        secondCapGeometry.setAttribute( 'position', new BufferAttribute( secondCapGeomArr, 3 ) );
        break;
    }

    const line = new LineSegments(lineGeometry, material);
    const firstCap = new Line(firstCapGeometry, material);
    const secondCap = new Line(secondCapGeometry, material);
    line.position.y = 0.2;
    firstCap.position.y = 0.2;
    secondCap.position.y = 0.2;
    if (node) {
      node.add(firstCap);
      node.add(secondCap);
      node.add(line);

    } else {
      this.node.add(firstCap);
      this.node.add(secondCap);
      this.node.add(line);
    }

    // line.needsUpdate = true; // todo: ThreeJS Migration
    line.matrixWorldNeedsUpdate = true;
  }


  private _getNodeByName(scene, name) {
    return scene.getObjectByName(name);
  }

  private _linesProperties(borderSize, borderValues, groundSize, node, gravestoneBbox) {
    const offset = 10;
    const heightFromGround = 0.2;
    const width = groundSize.width;
    const length = groundSize.length;
    const height = borderValues ? borderValues.height !== undefined ? borderValues.height : borderSize.borderHeight : 0;

    // widthLine
    const widthLineStart = new Vector3(-width / 2, 0, length + offset);
    const widthLineEnd = new Vector3(width / 2, 0, length + offset);
    // heightLine
    const heightLineStart = new Vector3(width / 2 + offset, 0, length + offset);
    const heightLineEnd = new Vector3(width / 2 + offset, height, length + offset);
    // lengthLine
    const lengthLineStart = new Vector3(width / 2 + offset, 0, 0);
    const lengthLineEnd = new Vector3(width / 2 + offset, 0, length);

    this._lineGeometry(widthLineStart, widthLineEnd, node, 'X');
    this._lineGeometry(heightLineStart, heightLineEnd, node, 'Y');
    this._lineGeometry(lengthLineStart, lengthLineEnd, node, 'Z');

    this._textGeometry(0, heightFromGround, length + offset * 2, -Math.PI / 2, 0, 0, width, node);
    this._textGeometry(width / 2 + (offset * 2), height / 2, length + offset, 0, 0, 0, height, node);
    this._textGeometry(width / 2 + (offset * 2), heightFromGround, length / 2, -Math.PI / 2, 0, Math.PI / 2, length, node);

    // gravestone
    const parentNode = this.node.parent.getObjectByName('stone');
    if (parentNode && parentNode.children.length) {
      const gravestoneWidth = -gravestoneBbox.min.x + gravestoneBbox.max.x;
      const gravestoneHeigth = gravestoneBbox.max.z - gravestoneBbox.min.z;
      const gravestoneLength = -gravestoneBbox.min.y + gravestoneBbox.max.y;

      // gHeightLine
      const gHeightLineStart = new Vector3(
        parentNode.children[0].position.x + gravestoneBbox.max.x + offset, parentNode.children[0].position.y + gravestoneBbox.min.z,
        gravestoneBbox.min.y + parentNode.children[0].position.z
      );
      const gHeightLineEnd = new Vector3(
        parentNode.children[0].position.x + gravestoneBbox.max.x + offset, parentNode.children[0].position.y + gravestoneBbox.max.z,
        gravestoneBbox.min.y + parentNode.children[0].position.z
      );

      // gWidthLine
      const gWidthLineStart = new Vector3(
        parentNode.children[0].position.x + gravestoneBbox.max.x, parentNode.children[0].position.y + gravestoneBbox.max.z + offset / 2,
        +gravestoneBbox.min.y + parentNode.children[0].position.z
      );
      const gWidthLineEnd = new Vector3(
        parentNode.children[0].position.x + gravestoneBbox.min.x, parentNode.children[0].position.y + gravestoneBbox.max.z + offset / 2,
        +gravestoneBbox.min.y + parentNode.children[0].position.z
      );

      // gLengthLine
      const gLengthLineStart = new Vector3(
        parentNode.children[0].position.x + gravestoneBbox.max.x + offset,
        parentNode.children[0].position.y + gravestoneBbox.max.z + offset / 2, parentNode.children[0].position.z + gravestoneBbox.min.y
      );
      const gLengthLineEnd = new Vector3(
        parentNode.children[0].position.x + gravestoneBbox.max.x + offset,
        parentNode.children[0].position.y + gravestoneBbox.max.z + offset / 2, parentNode.children[0].position.z - gravestoneBbox.min.y
      );

      this._lineGeometry(gHeightLineStart, gHeightLineEnd, node, 'X');
      this._lineGeometry(gWidthLineStart, gWidthLineEnd, node, 'Y');
      this._lineGeometry(gLengthLineStart, gLengthLineEnd, node, 'Z');

      this._textGeometry(
        parentNode.children[0].position.x, parentNode.children[0].position.y + gravestoneBbox.max.z + offset / 2,
        parentNode.children[0].position.z + gravestoneBbox.min.y, 0, 0, 0, Math.round(gravestoneWidth), node
      );
      this._textGeometry(
        parentNode.children[0].position.x + gravestoneBbox.max.x + (offset * 2),
        parentNode.children[0].position.y + gravestoneBbox.max.z / 2, parentNode.children[0].position.z + gravestoneBbox.min.y, 0, 0, 0,
        Math.round(gravestoneHeigth), node
      );
      this._textGeometry(
        parentNode.children[0].position.x + gravestoneBbox.max.x + (offset * 3) - (offset / 2),
        parentNode.children[0].position.y + gravestoneBbox.max.z, parentNode.children[0].position.z, 0, 0, 0, Math.round(gravestoneLength),
        node
      );
    }
  }

  private _dimensionVisibility;
}
