import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../core/auth/auth.service';
import {ActivatedRoute} from '@angular/router';
import {UiService} from '../../services/ui.service';
import {FirebaseStorageService} from '../../core/firebase/firebase-storage.service';
import {catchError, filter, finalize, first, take, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, Subject, throwError, timer} from 'rxjs';
import {NavigationService} from '../../core/services/navigation.service';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {SnackbarService} from '../../core/services/snackbar.service';
import {CookieService} from 'ngx-cookie';
import {AngularFireAnalytics} from '@angular/fire/analytics';

enum AuthSections {
  LOGIN = 'login',
  REGISTER = 'register',
  RESET_PASSWORD = 'resetPass'
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  section: AuthSections = AuthSections.LOGIN;
  readonly loginForm: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl(null, [Validators.required])
  });
  readonly registerForm: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    passwords: new FormGroup({
      password: new FormControl(null, [Validators.required]),
      confirmPass: new FormControl(null, [Validators.required]),
    }, [this.matchPasswords])
  });
  readonly resetForm: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
  });
  readonly loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly errors$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(
    @Inject(AuthService) private readonly authService: AuthService,
    @Inject(FirebaseStorageService) private readonly storage: FirebaseStorageService,
    @Inject(NavigationService) private readonly navigationService: NavigationService,
    @Inject(ActivatedRoute) private readonly route: ActivatedRoute,
    @Inject(SnackbarService) private readonly snackbarService: SnackbarService,
    @Inject(CookieService) private readonly cookieService: CookieService,
    @Inject(AngularFireAnalytics) private readonly analytics: AngularFireAnalytics,
    @Inject(ElementRef) private readonly elementRef: ElementRef,
    public uiService: UiService
  ) {
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument
      .body.style.backgroundImage = 'url(\'https://www.lithosmarmor.de/images/joomlaplates/left-bg02.jpg\')';
  }

  ngOnInit() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    const edge = ua.indexOf('Edge/');
    if (msie > 0 || edge > 0) {
      document.getElementById('unsupported').style.display = 'block';
    }
    this.checkForJoomlaLogin();
  }

  signUpWithEmail() {
    if (this.registerForm.valid) {
      this.authService.signUpWithEmail(this.registerForm.value.email, this.registerForm.get('passwords').value.password)
        .pipe(
          tap(() => this.loading$.next(true)),
          catchError(error => this.onAuthError(error)),
          finalize(() => this.loading$.next(false))
        )
        .subscribe(() => this.onRegisterSuccess(this.registerForm.value.email));
    }
  }


  signIn() {
    if (this.loginForm.valid) {
      this.authService.signInWithEmailAndPassword(this.loginForm.value.email, this.loginForm.value.password)
        .pipe(
          tap(() => this.loading$.next(true)),
          catchError(error => this.onAuthError(error)),
          finalize(() => this.loading$.next(false))
        )
        .subscribe(() => this.onAuthSuccess());
    }
  }

  googleSignIn() {
    this.authService.signInWithGoogle()
      .pipe(
        tap(() => this.loading$.next(true)),
        catchError(error => this.onAuthError(error)),
        finalize(() => this.loading$.next(false))
      )
      .subscribe(() => this.onAuthSuccess());
  }

  sendPassReset() {
    if (this.resetForm.valid) {
      this.authService.sendPassReset(this.resetForm.value.email)
        .pipe(
          tap(() => this.loading$.next(true)),
          catchError(error => this.onAuthError(error)),
          finalize(() => this.loading$.next(false))
        )
        .subscribe(() => {
          this.snackbarService.showMessage('Passwort-Reset gesendet!', 2000);
          this.goToLogIn();
        });
    }
  }

  goToPassReset() {
    this.section = AuthSections.RESET_PASSWORD;
  }

  goToRegister() {
    this.section = AuthSections.REGISTER;
  }

  goToLogIn() {
    this.section = AuthSections.LOGIN;
  }

  ngOnDestroy(): void {
    this.elementRef.nativeElement.ownerDocument
      .body.style.backgroundImage = '';
    this.destroyed$.next();
    this.loading$.next(false);
  }

  private onRegisterSuccess(email: string): void {
    this.loginForm.get('email').setValue(email);
    this.registerForm.reset();
    this.snackbarService.showMessage('Eine Verfizierungs E-Mail wurde gesendet!', 2000);
    this.section = AuthSections.LOGIN;
  }

  private onAuthSuccess(): void {
    // added for analytics; might not be best practice
    this.authService.appUser$.subscribe(user => user.uid && this.analytics.setUserId(user.uid));
    this.snackbarService.showMessage('Erfolgreiche Authentifizierung!', 2000);
    if (AuthService.REDIRECT_AFTER_AUTH_URL) {
      this.navigationService.goTo(AuthService.REDIRECT_AFTER_AUTH_URL);
      AuthService.REDIRECT_AFTER_AUTH_URL = null;
    } else {
      this.navigationService.goToHomePage();
    }
  }

  private onAuthError(error: unknown): Observable<never> {
    error && this.snackbarService.showErrorMessage(error.toString());
    // error && this.errors$.next(error.toString());
    // timer(4000).subscribe(() => this.errors$.next(''));
    return throwError(error);
  }

  private checkForJoomlaLogin() {
    const token = this.cookieService.get('app_session');
    if (token) {
      // todo: isJoomla should not be used like this (setting it in a different service and using it in this template) -> future refactoring
      setTimeout(() => this.uiService.isJoomla = true, 0);

      this.authService.signInWithJoomlaToken(token)
        .pipe(
          take(1),
          filter(user => !!user),
          tap(() => this.loading$.next(true)),
          catchError(error => this.onAuthError(error)),
          finalize(() => this.loading$.next(false))
        )
        .subscribe(() => this.onAuthSuccess());
    }
  }

  private matchPasswords(control: AbstractControl): ValidationErrors | null {
    const pass = control.get('password');
    const passConfirm = control.get('confirmPass');
    if (!pass.value || !passConfirm.value) {
      return null;
    }
    if (pass.value === passConfirm.value) {
      passConfirm.setErrors(null);
      return null;
    }
    passConfirm.setErrors({different: true});
    return {different: true};
  }

  private readonly destroyed$: Subject<void> = new Subject<void>();
}
