import { AfterViewInit, Component, Inject } from '@angular/core';
import { UiService } from './services/ui.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AppInitService } from './app-init.service';
import { JoyrideService } from 'ngx-joyride';
import { AbstractLocalStorageService } from './core/services/abstract-local-storage.service';
import { AppUser } from './modules/user/data/user.model';
import { UserStorageService } from './core/auth/user-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  public data;

  constructor(
    @Inject(UiService) public uiService: UiService,
    @Inject(MatIconRegistry) private matIconRegistry: MatIconRegistry,
    @Inject(DomSanitizer) private domSanitizer: DomSanitizer,
    @Inject(AppInitService) private readonly appInitService: AppInitService,
    @Inject(JoyrideService) private readonly tutorialService: JoyrideService,
    @Inject(UserStorageService) private readonly userStorage: AbstractLocalStorageService<AppUser>
  ) {
    this.addSvgIcon('3d_rotation', './../assets/icons/3d_rotation.svg');
    this.addSvgIcon('delete_forever', './../assets/icons/delete_forever.svg');
    this.addSvgIcon('rotate_left', './../assets/icons/rotate_left.svg');
    this.addSvgIcon('center_focus_strong', './../assets/icons/center_focus_strong.svg');
    this.addSvgIcon('zoom_out_map', './../assets/icons/zoom_out_map.svg');
    this.addSvgIcon('plate_anchor', './../assets/icons/vertical_align_center.svg');
    this.addSvgIcon('compare_arrows', './../assets/icons/compare_arrows.svg');
    this.addSvgIcon('add_circle_outline', './../assets/icons/add_circle_outline.svg');
    this.addSvgIcon('cloud_upload', './../assets/icons/cloud_upload.svg');
    this.addSvgIcon('facebook', './../assets/icons/facebook.svg');
    this.addSvgIcon('instagram', './../assets/icons/instagram.svg');
    this.addSvgIcon('web', './../assets/icons/www.svg');
    this.addSvgIcon('drag_handle', './../assets/icons/drag_handle.svg');
    this.addSvgIcon('done_outline_black', './../assets/icons/done_outline_black.svg');
    this.addSvgIcon('close_x', './../assets/icons/close_x.svg');
    this.appInitService.init();
  }

  ngAfterViewInit() {
    let check = localStorage.getItem("tutorialDone")
    let loggedIn = false;
    this.userStorage.retrieve() ? loggedIn = true : loggedIn = false;
    if (check != "true" && loggedIn) {
      this.tutorialService.startTour({steps: ['firstStep@help', 'secondStep@help', 'thirdStep@project', 'fourthStep@project', 'fifthStep@ground', 'sixthStep@user', 'seventhStep@email'], waitingTime: 100});
      localStorage.setItem("tutorialDone", "true");
    }
  }

  private addSvgIcon(iconName: string, assetsPath: string) {
    this.matIconRegistry.addSvgIcon(iconName, this.domSanitizer.bypassSecurityTrustResourceUrl(assetsPath));
  }
}

