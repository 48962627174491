import { Query } from '@angular/fire/firestore';

const enum FirestoreFilterOperator {
  EQUALS = '==',
  ARRAY_CONTAINS = 'array-contains',
  ARRAY_CONTAINS_ANY = 'array-contains-any',
  IN = 'in',
}

export class FirestoreFilter {
  private constructor(
    readonly property: string,
    readonly operator: FirestoreFilterOperator,
    readonly value: any,
    readonly remove: boolean = false
  ) {}

  static equals(property: string, value: any): FirestoreFilter {
    return new FirestoreFilter(property, FirestoreFilterOperator.EQUALS, value);
  }

  static removeEquals(property: string): FirestoreFilter {
    return new FirestoreFilter(property, FirestoreFilterOperator.EQUALS, null, true);
  }

  static arrayContains(property: string, value: string | number) {
    return new FirestoreFilter(property, FirestoreFilterOperator.ARRAY_CONTAINS, value);
  }

  static arrayContainsAny(property: string, items: Array<any>) {
    return new FirestoreFilter(property, FirestoreFilterOperator.ARRAY_CONTAINS_ANY, items);
  }

  static in(property: string, items: Array<any>) {
    return new FirestoreFilter(property, FirestoreFilterOperator.IN, items);
  }

  appendTo(query: Query): Query {
    return query.where(this.property, this.operator, this.value);
  }
}

export class FiltersSet {
  constructor(filters: Array<FirestoreFilter> = []) {
    filters.forEach(filter => this.add(filter));
  }

  add(filter: FirestoreFilter) {
    const storageKey: string = FiltersSet.getStorageKey(filter);
    this.filters.set(storageKey, filter);
  }

  toArray(): Array<FirestoreFilter> {
    return Array.from(this.filters.values());
  }

  private static getStorageKey(filter: FirestoreFilter): string {
    return `${filter.property}_${filter.operator}`;
  }

  private readonly filters: Map<string, FirestoreFilter> = new Map<string, FirestoreFilter>();
}
