import { Inject, Injectable } from '@angular/core';
import { _Raycaster } from '../three-services/Raycaster';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Box3, Mesh, MeshBasicMaterial, Object3D, Scene, SphereGeometry, Vector3 } from 'three';
import { AppData } from '../../../models/app/app-data.model';
import { FirestoreMigrationService } from '../../services/migration/firestore-migration.service';
import { MaterialsFirestoreService } from '../../modules/material/services/materials-firestore.service';
import { PolishesFirestoreService } from '../../modules/material/services/polishes-firestore.service';
import { EnvironmentFirestoreService } from '../../modules/project/services/environment-firestore.service';
import { TextMaterialsFirestoreService } from '../../modules/text/services/text-materials-firestore.service';
import { TextFontsFirestoreService } from '../../modules/text/services/text-fonts-firestore.service';
import { FirestoreFolderSelectionMigrationService } from '../../services/migration/firestore-folder-selection-migration.service';
import { cloneDeep } from 'lodash-es';
import { diff } from 'deep-object-diff';
import { FirestoreDuplicatesRemovalService } from '../../services/migration/firestore-duplicates-removal.service';
import { FilterDefinition, FilterValueExtractor } from '../../../../commons/filter/filter-definition';

@Injectable({ providedIn: 'root' })
export class UtilsService {
  appData: AppData;
  public tween;
  public currentBorderValues;
  public currentStoneValues;
  public currentPlateValues;
  public canEditBorder = false;
  // todo: see how this affects the system, currentStone and currentModel needed to be initiliazed to avoid errors
  public currentStone = null;
  public currentModel = null;
  public canMoveStone = false;
  public groundSize = { width: null, length: null, height: null };
  public filters = {
    stone: [],
    plate: [],
    border: [],
    accessories: []
  };
  public borderSize = {
    length: 0,
    borderTickness: 0,
    borderHeight: 0,
    monumentHolderWidth: 0,
    monumentHolderLength: 0,
    monumentHolderHeight: 0,
    withoutBorders: true
  };
  public plateThichkness: number;
  public monumentHolderWidth = new BehaviorSubject(this.borderSize.monumentHolderWidth);
  public currentStoneSubject = new BehaviorSubject(this.currentStone);
  public wasUpdated = false;
  public overallGamma = 2.2;

  constructor(
    private http: HttpClient,
    @Inject(FirestoreMigrationService) private readonly migrationService: FirestoreMigrationService,
    @Inject(MaterialsFirestoreService) private readonly materialsFirestoreService: MaterialsFirestoreService,
    @Inject(PolishesFirestoreService) private readonly polishesFirestoreService: PolishesFirestoreService,
    @Inject(EnvironmentFirestoreService) private readonly environmentFirestoreService: EnvironmentFirestoreService,
    @Inject(TextMaterialsFirestoreService) private readonly textMaterialsFirestoreService: TextMaterialsFirestoreService,
    @Inject(TextFontsFirestoreService) private readonly textFontsFirestoreService: TextFontsFirestoreService,
    @Inject(FirestoreFolderSelectionMigrationService) private readonly folderSelectionMigrationService: FirestoreFolderSelectionMigrationService,
    @Inject(FirestoreDuplicatesRemovalService) private readonly firestoreDuplicatesRemovalService: FirestoreDuplicatesRemovalService
  ) { }

  public setPlateTichkness(tichkness) {
    this.plateThichkness = tichkness;
  }

  public setCurrentModel(model) {
    this.currentModel = model;
    this.currentModel$.next(model);
  }

  public getCurrentModel() {
    return this.currentModel;
  }

  public getJSONData(data) {
    return this.http.get(data);
  }

  public setFilters(category: string, filters) {
    this.filters[category] = filters;
  }

  public getMeshByUID(node, uid) {
    if (node.children.length === 0) {
      return;
    }
    for (let i = 0; i < node.children.length; i++) {
      if (node.children[i].userData.uid === uid) {
        return node.children[i];
      }
    }
  }

  public createDeepClone(oldValue) {
    return cloneDeep(oldValue);
  }

  public intersectObjects(first: any, second: any) {
    return diff(second, first) as any;
    // if (this.isEmpty(second)) {
    //   return first;
    // }
    // const result = {};
    // for (const prop in first) {
    //   if (typeof first[prop] === 'function') {
    //     continue;
    //   } else if (typeof first[prop] === 'object' && !Array.isArray(first[prop])) {
    //     const secondLayer = this.intersectObjects(first[prop], second[prop]);
    //     if (!this.isEmpty(secondLayer)) {
    //       result[prop] = secondLayer;
    //     }
    //   } else if (Array.isArray(first[prop])) {
    //     if (first[prop] && second[prop]) {
    //       if (first[prop].toString() !== second[prop].toString()) {
    //         result[prop] = first[prop];
    //       }
    //     }
    //   } else if (second[prop] === undefined) {
    //     result[prop] = first[prop];
    //   } else if (first[prop] !== second[prop]) {
    //     result[prop] = first[prop];
    //   }
    // }
    // return result;
  }

  public getMouseIntersections(objects, event) {
    this.setClickEntities(this._raycasterFromCamera, objects);
    const intersections = this._raycasterFromCamera.castFromCamera(event);
    if (intersections && intersections.length) {
      return intersections[0];
    }
    return undefined;

  }

  public getObjectIntersections(objects, origin, direction) {
    this.setClickEntities(this._raycasterFromObject, objects);
    return this._raycasterFromObject.castFromOrigin(origin, direction);
  }

  public setControls(ctrl, renderer, scene, camera, container) {
    this._controls = ctrl;
    this._renderer = renderer;
    this._scene = scene;
    this._camera = camera;
    this._container = container;
    this._raycasterFromCamera.setFromCamera(this._camera, this._container);
  }

  public enableControls() {
    this._controls.enableRotate = true;
  }

  public disableControls() {
    this._controls.enableRotate = false;
  }

  public setAppData(appData: AppData) {
    this.appData = appData;
  }

  observeCurrentModel(): Observable<any> {
    return this.currentModel$.asObservable();
  }

  // todo: used for data migration, don't uncomment
  migrateDataToFirestore(appData: AppData) {
    // this.migrationService.migrate(appData);
    // this.projectMigrationService.migrate();
    // this.folderSelectionMigrationService.migrate();
    // this.firestoreDuplicatesRemovalService.removeDuplicates();
    // this.testKeywordsGenerator();
  }

  initFirestoreCaches() {
    this.materialsFirestoreService.updateCache();
    this.polishesFirestoreService.updateCache();
    this.textMaterialsFirestoreService.updateCache();
    this.textFontsFirestoreService.updateCache();
  }

  initFirestoreCaches$(): Observable<any> {
    return this.environmentFirestoreService.updateCache$();
  }

  public deleteItem(file) {
    // todo: refactor this
    // for (const prop in this.appData) {
    //   if (this.appData[prop]) {
    //     for (let i = 0; i < this.appData[prop].length; i++) {
    //       if (this.appData[prop][i].uuid === file.uuid) {
    //         this.appData[prop].splice(i, 1);
    //         this.wasUpdated = true;
    //       }
    //     }
    //   }
    // }

  }

  getAppData(clone?): AppData {
    if (clone) {
      return this.createDeepClone(this.appData);
    } else {
      return this.appData;
    }

  }

  public getStoneDimensions() {
    const node = this.getNodeByName('stone');
    const bbox = this.getBoundingBoxOfGroup(node);
    return {
      gravestoneWidth: node.children.length ? Math.round(-bbox.min.x + bbox.max.x) : undefined,
      gravestoneHeigth: node.children.length ? Math.round(bbox.max.z - bbox.min.z) : undefined,
      gravestoneLength: node.children.length ? Math.round(-bbox.min.y + bbox.max.y) : undefined
    };
  }

  public setNode(obj) {
    this._node = obj;
  }

  public isEmpty(obj) {
    if (obj == null) {
      return true;
    }
    if (obj.length > 0) {
      return false;
    }
    if (obj.length === 0) {
      return true;
    }
    if (typeof obj !== 'object') {
      return true;
    }
    for (const key in obj) {
      if (obj.hasOwnProperty.call(obj, key)) {
        return false;
      }
    }
    return true;
  }

  public getNodeByName(name) {
    return this._scene.getObjectByName(name);
  }

  public setGroundSize(width, length, height) {
    this.groundSize.width = width;
    this.groundSize.length = length;
    this.groundSize.height = height;
    const plateFilters = [];
    plateFilters.push('any');
    if (width === 60 && length === 100) {
      plateFilters.push('60x100');
    }
    if (width === 100 && length === 100) {
      plateFilters.push('100x100');
    }
    if (width === 100 && length === 200) {
      plateFilters.push('100x200');
    }
    this.setFilters('plate', plateFilters);
  }

  public setDefaultBorderSize() {
    this.borderSize = {
      length: 0,
      borderTickness: 0,
      borderHeight: 0,
      monumentHolderWidth: 0,
      monumentHolderLength: 0,
      monumentHolderHeight: 0,
      withoutBorders: true
    };
  }

  public setBorderSize(value) {
    this.borderSize = value;
  }

  public getBorderSize() {
    if (!this.borderSize || this.borderSize.monumentHolderHeight === undefined) {
      this.setDefaultBorderSize();
      return this.borderSize;
    }
    return {
      length: this.borderSize.length,
      borderTickness: this.borderSize.borderTickness,
      borderHeight: this.borderSize.borderHeight,
      monumentHolderWidth: this.borderSize.monumentHolderWidth,
      monumentHolderLength: this.borderSize.monumentHolderLength,
      monumentHolderHeight: this.borderSize.monumentHolderHeight,
      withoutBorders: false
    };
  }

  public addSphere(point, remove?) {
    if (remove && this._sphereDimensions1) {
      this._scene.remove(this._sphereDimensions1);
      this._sphereDimensions1 = null;
      return;
    }
    if (this._sphereDimensions1) {
      this._sphereDimensions1.position.set(point.x, point.y, point.z);
    } else {
      const geometry = new SphereGeometry(1, 32, 32);
      const material = new MeshBasicMaterial({ color: 0xff0000 });
      this._sphereDimensions1 = new Mesh(geometry, material);
      this._sphereDimensions1.position.set(point.x, point.y, point.z);
      this._scene.add(this._sphereDimensions1);
    }

  }

  public getMonumentHolderCenter() {
    let center;
    this.getNodeByName('border').traverse(elem => {
      if (elem.name.includes('MonumentHolder') && elem.visible) {
        center = this.getCenterOfObject(elem);
      }
    });
    return center;
  }

  public getGroundSize() {
    return {
      width: this.groundSize.width,
      length: this.groundSize.length,
      height: this.groundSize.height
    };
  }

  public disposeElement(object: any): void {
    const i = object.children.length - 1;
    while (object.children.length > 0) {
      object.children.forEach(element => {
        element.traverse((child) => {
          if (child.material && child.material.length) {
            child.material.forEach(e => this.disposeMaterial(e));
          } else if (child.material) {
            this.disposeMaterial(child.material);
          }

          if (child.geometry) {
            child.geometry.dispose();
          }

          object.remove(child);
        });
      });
    }
  }

  public disposeMaterial(material) {
    if (Array.isArray(material)) {
      material.forEach((element) => {
        this._disposeMaterialMap(element);
      });
    } else {
      this._disposeMaterialMap(material);
    }
  }

  public getBoundingBox(geometry: any) {
    geometry.computeBoundingBox();
    return geometry.boundingBox;
  }

  public getTransformedBBoxOfGroup(object: Scene | Object3D) {
    let bbox = new Box3();
    object.traverse((child) => {
      if (child.type === 'Mesh') {
        const tempBBox = this._getTransformedBBoxOfMesh(child as Mesh);
        if (bbox.max.x < tempBBox.max.x) {
          bbox.max.x = tempBBox.max.x;
        }
        if (bbox.max.y < tempBBox.max.y) {
          bbox.max.y = tempBBox.max.y;
        }
        if (bbox.max.z < tempBBox.max.z) {
          bbox.max.z = tempBBox.max.z;
        }

        if (bbox.min.x > tempBBox.min.x) {
          bbox.min.x = tempBBox.min.x;
        }
        if (bbox.min.y > tempBBox.min.y) {
          bbox.min.y = tempBBox.min.y;
        }
        if (bbox.min.z > tempBBox.min.z) {
          bbox.min.z = tempBBox.min.z;
        }
      }
    });
    return bbox;
  }

  public getTransformedSizesOfGroup(object: Scene | Object3D) {
    const bbox = this.getTransformedBBoxOfGroup(object);
    return {
      width: -bbox.min.x + bbox.max.x,
      height: -bbox.min.y + bbox.max.y,
      length: -bbox.min.z + bbox.max.z
    };
  }

  public getSizes(object: Mesh) {
    if (!object) {
      return;
    }
    object.updateMatrixWorld();
    const tempGeo = object.geometry.clone();
    // let mtrx = new Matrix4();
    // if (parent) mtrx = parent.matrix;
    tempGeo.applyMatrix4(object.matrix);
    const bBox = this.getBoundingBox(tempGeo);

    // const bBox = this.getBoundingBox(object.geometry);
    const width = -bBox.min.x + bBox.max.x;
    const height = -bBox.min.z + bBox.max.z;
    const length = -bBox.min.y + bBox.max.y;
    return {
      width: width,
      height: height,
      length: length
    };
  }

  public getCenterOfGeometry(geometry: any) {
    geometry.computeBoundingBox();
    const center = geometry.boundingBox.getCenter(new Vector3());
    return center;
  }

  public getCenterOfObject(mesh: any) {
    const geometry = mesh.geometry;
    geometry.computeBoundingBox();
    const center = geometry.boundingBox.getCenter(new Vector3());
    mesh.localToWorld(center);
    return center;
  }

  public getMeshByName(name: string, node?: any) {
    let children;
    const obj = node ? node : this._scene;
    obj.traverse((child) => {
      if (child.name === name) {
        children = child;
      }
    });
    return children;
  }

  public getIndexFromUid(array, obj) {
    for (let i = 0; i < array.length; i++) {
      if (array[i] && array[i].uid === obj.uid) {
        return i;
      }
    }
  }

  public getOriginalDimensionsFromBBox(group) {
    const bbox = new Box3();
    group.traverse((child) => {
      if (child.type === 'Mesh') {
        child.updateMatrixWorld();
        let tempMesh = child.clone();
        tempMesh.position.set(0, 0, 0);
        // tempMesh.rotation.set(0, 0, 0);
        tempMesh.scale.set(1, 1, 1);
        tempMesh.updateMatrixWorld();
        const tempGeo = tempMesh.geometry.clone();
        tempGeo.applyMatrix4(tempMesh.matrixWorld);
        const tempBBox = this.getBoundingBox(tempGeo);

        if (bbox.max.x < tempBBox.max.x) {
          bbox.max.x = tempBBox.max.x;
        }
        if (bbox.max.y < tempBBox.max.y) {
          bbox.max.y = tempBBox.max.y;
        }
        if (bbox.max.z < tempBBox.max.z) {
          bbox.max.z = tempBBox.max.z;
        }

        if (bbox.min.x > tempBBox.min.x) {
          bbox.min.x = tempBBox.min.x;
        }
        if (bbox.min.y > tempBBox.min.y) {
          bbox.min.y = tempBBox.min.y;
        }
        if (bbox.min.z > tempBBox.min.z) {
          bbox.min.z = tempBBox.min.z;
        }
        tempMesh.geometry.dispose();
        tempMesh.material.dispose();
        tempMesh = null;
      }
    });
    return {
      width: -bbox.min.x + bbox.max.x,
      height: -bbox.min.y + bbox.max.y,
      length: -bbox.min.z + bbox.max.z
    };
  }

  public getDimensionsFromBBox(object) {

    const bbox = this.getBoundingBoxOfGroup(object);
    return {
      width: -bbox.min.x + bbox.max.x,
      length: -bbox.min.y + bbox.max.y,
      height: -bbox.min.z + bbox.max.z
    };
  }

  public getBoundingBoxOfTransformedMesh(mesh: Mesh) {

    mesh.updateMatrixWorld();
    let tempGeo = mesh.geometry.clone();
    tempGeo.applyMatrix4(mesh.matrixWorld);

    const bbox = new Box3();
    const tempBBox = this.getBoundingBox(tempGeo);

    if (bbox.max.x < tempBBox.max.x) {
      bbox.max.x = tempBBox.max.x;
    }
    if (bbox.max.y < tempBBox.max.y) {
      bbox.max.y = tempBBox.max.y;
    }
    if (bbox.max.z < tempBBox.max.z) {
      bbox.max.z = tempBBox.max.z;
    }

    if (bbox.min.x > tempBBox.min.x) {
      bbox.min.x = tempBBox.min.x;
    }
    if (bbox.min.y > tempBBox.min.y) {
      bbox.min.y = tempBBox.min.y;
    }
    if (bbox.min.z > tempBBox.min.z) {
      bbox.min.z = tempBBox.min.z;
    }
    tempGeo.dispose();
    tempGeo = null;

    return bbox;
  }

  public getAbstractDimensionsFromBBox(object) {

    const bbox = this.getBoundingBoxOfGroup(object);
    return {
      x: -bbox.min.x + bbox.max.x,
      y: -bbox.min.y + bbox.max.y,
      z: -bbox.min.z + bbox.max.z
    };
  }

  public getBoundingBoxOfGroup(group: any) {
    const bbox = new Box3();
    group.traverse((child) => {
      if (child.type === 'Mesh') {
        const tempBBox = this.getBoundingBox(child.geometry);

        if (bbox.max.x < tempBBox.max.x) {
          bbox.max.x = tempBBox.max.x;
        }
        if (bbox.max.y < tempBBox.max.y) {
          bbox.max.y = tempBBox.max.y;
        }
        if (bbox.max.z < tempBBox.max.z) {
          bbox.max.z = tempBBox.max.z;
        }

        if (bbox.min.x > tempBBox.min.x) {
          bbox.min.x = tempBBox.min.x;
        }
        if (bbox.min.y > tempBBox.min.y) {
          bbox.min.y = tempBBox.min.y;
        }
        if (bbox.min.z > tempBBox.min.z) {
          bbox.min.z = tempBBox.min.z;
        }
      }
    });
    return bbox;
  }

  private _getTransformedBBoxOfMesh(mesh: Mesh) {
    mesh.updateMatrixWorld();
    let tempGeo = mesh.geometry.clone();
    tempGeo.applyMatrix4(mesh.matrixWorld);
    const bbox = new Box3();
    const tempBBox = this.getBoundingBox(tempGeo);
    return tempBBox;
  }

  private testKeywordsGenerator() {
    const obj = { name: 'Hola1 Johnny', filters: ['100x100'] };
    const filtersExtractor: FilterValueExtractor = (obj, key) => obj[key][0];
    const definitions = [FilterDefinition.fulltext('name'), FilterDefinition.value('filters', filtersExtractor)];
  }

  private setClickEntities(raycaster, names) {
    raycaster.onClickEntities = [];
    for (let i = 0; i < names.length; i++) {
      raycaster.onClickEntities.push(this._node[names[i]]);
    }
  }

  private _disposeMaterialMap(material) {
    if (material.map != null) { material.map.dispose(); }

    if (material.envMap != null) { material.envMap.dispose(); }

    if (material.normalMap != null) { material.normalMap.dispose(); }

    if (material.alphaMap != null) { material.alphaMap.dispose(); }

    if (material.bumpMap != null) { material.bumpMap.dispose(); }

    if (material.emissiveMap != null) { material.emissiveMap.dispose(); }

    if (material.lightMap != null) { material.lightMap.dispose(); }

    if (material.roughnessMap != null) { material.roughnessMap.dispose(); }

    if (material.aoMap != null) { material.aoMap.dispose(); }

    if (material.specularMap != null) { material.specularMap.dispose(); }

    material.dispose();
  }

  private readonly currentModel$: BehaviorSubject<any> = new BehaviorSubject<any>(this.currentModel);
  private _controls;
  private _renderer;
  private _scene;
  private _camera;
  private _container;
  private _node;
  private _raycasterFromCamera = new _Raycaster();
  private _raycasterFromObject = new _Raycaster();
  private _materialsData;
  private _sphereDimensions1;
}

