import { concat, Observable, of, OperatorFunction, SchedulerLike } from 'rxjs';
import { debounceTime, publish, switchMap, take, tap } from 'rxjs/operators';
import { async } from 'rxjs/internal/scheduler/async';

export function debounceTimeAfter<T>(
  amount: number,
  dueTime: number,
  scheduler: SchedulerLike = async
): OperatorFunction<T, T> {
  return publish((value: Observable<T>) =>
    concat(
      value.pipe(take(amount)),
      value.pipe(debounceTime(dueTime, scheduler))
    )
  );
}

export function debounceTimeAfterFirst<T>(
  dueTime: number,
  scheduler: SchedulerLike = async
): OperatorFunction<T, T> {
  return debounceTimeAfter<T>(1, dueTime, scheduler);
}

export function startWithTap<T>(callback: () => void) {
  return (source: Observable<T>) =>
    of({}).pipe(tap(callback), switchMap((o) => source));
}
