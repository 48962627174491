import { Inject, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserProject } from '../../../../models/firestore/users/user-project.model';
import { FirestoreUserResourceService } from '../../../core/firebase/firestore/firestore-user-resource.service';

@Injectable({ providedIn: 'root' })
export class UserProjectsFirestoreService extends FirestoreUserResourceService<UserProject> {
  constructor(@Inject(AngularFirestore) protected readonly firestore: AngularFirestore) { super(firestore); }

  protected get resourcePath(): string {
    return 'projects';
  }

  protected get collectionGroupPath(): string {
    return this.resourcePath;
  }
}
