import { NgModule } from '@angular/core';
import { SharedLibsModule } from './modules/shared-libs.module';
import { DeleteDialogComponent } from './dialogs/delete-dialog.component';
import { ThumbnailOldComponent } from './thumbnail/thumbnail-old/thumbnail-old.component';
import { CounterComponent } from './form/inputs/counter/counter.component';
import { SaveComponent } from './components/save/save.component';
import { InputDebounceDirective } from './form/input-debounce.directive';
import { MaterialNumberInputComponent } from './form/inputs/material-number-input/material-number-input.component';
import { MaterialTextInputComponent } from './form/inputs/material-text-input/material-text-input.component';
import {
  MaterialSelectComponent,
  MaterialSelectOptionValueTemplateDirective
} from './form/inputs/material-select/material-select.component';
import { BorderItemsListComponent } from './category/border/border-items-list.component';
import { StoneItemsListComponent } from './category/stone/stone-items-list.component';
import { PlateItemsListComponent } from './category/plate/plate-items-list.component';
import { AccessoryItemsListComponent } from './category/accessory/accessory-items-list.component';
import { StoneFoldersListComponent } from './category/stone/stone-folders-list.component';
import { PlateFoldersListComponent } from './category/plate/plate-folders-list.component';
import { AccessoryFoldersListComponent } from './category/accessory/accessory-folders-list.component';
import { ThumbnailCardComponent } from './thumbnail/thumbnail-card/thumbnail-card.component';
import { FulltextSearchComponent } from './list/list-search/fulltext-search.component';
import { ThumbnailCardListComponent } from './thumbnail/thumbnail-card-list/thumbnail-card-list.component';
import {
  FolderListTemplateComponent,
  FoldersListTemplateDirective,
  ItemsListTemplateDirective
} from './category/shared/folder-list-template.component';
import { StoneFolderCategoryFilterComponent } from './category/stone/stone-folder-category-filter.component';
import { FirestorePaginator } from './list/firestore-paginator.component';
import { MaterialAutocompleteComponent } from './form/inputs/material-autocomplete/material-autocomplete.component';
import { MaterialsListComponent } from './category/material/materials-list.component';
import { PolishesListComponent } from './category/polish/polishes-list.component';
import { FileUploadComponent, FileUploadZoneDirective } from './storage/file-upload/file-upload.component';
import { ColorPickerComponent } from './form/inputs/color-picker/color-picker.component';
import { ThumbnailComponent } from './thumbnail/thumbnail/thumbnail.component';
import { ScreenshotDialogComponent } from './storage/screenshot/screenshot-dialog.component';
import { ScreenshotUploadComponent } from './storage/screenshot/screenshot-upload.component';
import { PendingChangesDialogComponent } from './dialogs/pending-changes-dialog.component';
import { FullscreenHiddenDirective } from './layout/fullscreen-hidden.directive';
import { AccessoryFoldersAutocompleteComponent } from './category/accessory/accessory-folders-autocomplete.component';
import { PlateFoldersAutocompleteComponent } from './category/plate/plate-folders-autocomplete.component';
import { StoneFoldersAutocompleteComponent } from './category/stone/stone-folders-autocomplete.component';
import { MaterialPasswordInputComponent } from './form/inputs/material-password-input/material-password-input.component';
import { IfProjectLoadedDirective } from './project/if-project-loaded.directive';
import { TextFontsListComponent } from './category/text/text-fonts-list.component';
import { TutorialComponent } from './components/tutorial/tutorial.component';
import { RouterModule } from '@angular/router';
import {ChangeConfirmationDialogComponent} from './dialogs/change-confirmation-dialog.component';


@NgModule({
  declarations: [
    ThumbnailOldComponent,
    DeleteDialogComponent,
    CounterComponent,
    SaveComponent,
    TutorialComponent,
    InputDebounceDirective,
    MaterialNumberInputComponent,
    MaterialTextInputComponent,
    MaterialSelectComponent,
    BorderItemsListComponent,
    StoneItemsListComponent,
    PlateItemsListComponent,
    AccessoryItemsListComponent,
    StoneFoldersListComponent,
    PlateFoldersListComponent,
    AccessoryFoldersListComponent,
    ThumbnailCardComponent,
    FulltextSearchComponent,
    ThumbnailCardListComponent,
    FolderListTemplateComponent,
    ItemsListTemplateDirective,
    FoldersListTemplateDirective,
    StoneFolderCategoryFilterComponent,
    FirestorePaginator,
    MaterialSelectOptionValueTemplateDirective,
    MaterialAutocompleteComponent,
    MaterialsListComponent,
    PolishesListComponent,
    FileUploadComponent,
    FileUploadZoneDirective,
    ColorPickerComponent,
    ThumbnailComponent,
    ScreenshotDialogComponent,
    ScreenshotUploadComponent,
    PendingChangesDialogComponent,
    ChangeConfirmationDialogComponent,
    FullscreenHiddenDirective,
    AccessoryFoldersAutocompleteComponent,
    PlateFoldersAutocompleteComponent,
    StoneFoldersAutocompleteComponent,
    MaterialPasswordInputComponent,
    IfProjectLoadedDirective,
    TextFontsListComponent,

  ],
  imports: [SharedLibsModule,
    RouterModule],
  exports: [
    SharedLibsModule,
    ThumbnailOldComponent,
    CounterComponent,
    SaveComponent,
    TutorialComponent,
    InputDebounceDirective,
    MaterialNumberInputComponent,
    MaterialTextInputComponent,
    MaterialSelectComponent,
    BorderItemsListComponent,
    StoneItemsListComponent,
    PlateItemsListComponent,
    AccessoryItemsListComponent,
    StoneFoldersListComponent,
    PlateFoldersListComponent,
    AccessoryFoldersListComponent,
    ThumbnailCardComponent,
    FulltextSearchComponent,
    ThumbnailCardListComponent,
    FirestorePaginator,
    MaterialSelectOptionValueTemplateDirective,
    MaterialAutocompleteComponent,
    MaterialsListComponent,
    PolishesListComponent,
    FileUploadComponent,
    FileUploadZoneDirective,
    ColorPickerComponent,
    ThumbnailComponent,
    ScreenshotUploadComponent,
    FullscreenHiddenDirective,
    AccessoryFoldersAutocompleteComponent,
    PlateFoldersAutocompleteComponent,
    StoneFoldersAutocompleteComponent,
    MaterialPasswordInputComponent,
    IfProjectLoadedDirective,
    TextFontsListComponent
  ]
})
export class SharedModule {}
