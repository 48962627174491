import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { UiService } from '../../services/ui.service';
import { NavigationService } from '../../core/services/navigation.service';
import { NavTab } from './tab.model';
import { ProjectService } from '../../services/project.service';
import { UtilsService } from '../../three-view/three-utils/utils.service';
import { appRoutesNames } from '../../app-routes.names';
import { adminRoutesResolvers } from '../../modules/admin/admin-routes.resolver';
import { FullscreenService } from '../../core/services/fullscreen.service';
import { AuthService } from '../../core/auth/auth.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { ScreenshotService } from '../../shared/storage/screenshot/screenshot.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, AfterViewInit {

  isAdmin: boolean = false;
  tabs: NavTab[] = [];

  constructor(
    @Inject(ProjectService) private readonly projectService: ProjectService,
    @Inject(UiService) private readonly uiService: UiService,
    @Inject(UtilsService) private readonly utilsService: UtilsService,
    @Inject(NavigationService) private readonly navigationService: NavigationService,
    @Inject(FullscreenService) private readonly fullscreenService: FullscreenService,
    @Inject(AuthService) private readonly authService: AuthService,
    @Inject(AngularFireAnalytics) private readonly analytics: AngularFireAnalytics,
    @Inject(ScreenshotService) public readonly screenshotService: ScreenshotService
  ) {}

  ngOnInit() {
    this.uiService.initEvents();
    this.authService.hasAnyClaim(['admin']).subscribe(isAdmin => {
      this.isAdmin = isAdmin;
      this.tabs = this.buildTabs();
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.uiService.isJoomla) { this.uiService.isJoomla = false; }
    });
  }

  onTabClick(tab: NavTab, index: number) {
    this.analytics.logEvent('page_view', { page_path: tab.path, coming_from: window.location.pathname });
    this.checkClose(index);
    if (tab.action) {
      tab.action();
    }
  }

  printScreen() {
    // const tab = window.open();
    // this.uiService.getScreenShot(2048, true).then((result) => {
    //   tab.location.href = URL.createObjectURL(result);
    //   timer(500)
    //     .pipe(
    //       first(),
    //       filter(() => tab.closed)
    //     )
    //     .subscribe(() => this.errors$.next(this.adblockMessage));
    // });
    this.screenshotService.takeScreenshot(false, 2048, false)
      .pipe(
        filter((value: Blob | null) => !!value)
      )
      .subscribe(file => {});
  }

  private buildTabs(): NavTab[] {
    return [
      {
        path: `/${appRoutesNames.HELP}`,
        icon: 'help'
      },
      {
        path: `/${appRoutesNames.USER}`,
        icon: 'face'
      },
      {
        path: `/${appRoutesNames.EMAIL}`,
        icon: 'email'
      },
      {
        path: `/${appRoutesNames.PROJECT}`,
        label: 'Projekt'
      },
      {
        path: `/${appRoutesNames.GROUND}`,
        label: 'Einfassung'
      },
      {
        path: `/${appRoutesNames.STONE}`,
        label: 'Grabsteine',
        disable: this.doesNotAcceptStone
      },
      {
        path: `/${appRoutesNames.PLATE}`,
        label: 'Platten',
        disable: this.doesNotAcceptPlate
      },
      {
        path: `/${appRoutesNames.MATERIAL}`,
        label: 'Material'
      },
      {
        path: `/${appRoutesNames.TEXT}`,
        label: 'Beschriftung'
      },
      {
        path: `/${appRoutesNames.ACCESSORIES}`,
        label: 'Zubehör'
      },
      {
        path: `/${adminRoutesResolvers.ROOT()}`,
        label: 'Admin',
        hide: this.isNotAdmin
      },
      {
        action: this.printButton,
        icon: 'print'
      },
      {
        action: this.toggleFullscreen,
        icon: 'launch'
      }
    ];
  }

  private checkClose(val) {
    this.uiService.switchEvent(this._mappedTabs[val]);
  }

  private readonly isNotAdmin = () => !this.isAdmin;
  private readonly doesNotAcceptStone = () => this.utilsService.currentBorderValues && !this.utilsService.currentBorderValues.acceptStone;
  private readonly doesNotAcceptPlate = () => this.utilsService.currentBorderValues && !this.utilsService.currentBorderValues.acceptPlate;
  private readonly toggleFullscreen = () => this.fullscreenService.toggleFullscreen();
  private readonly printButton = () => this.printScreen();

  private readonly _mappedTabs = [
    'help',
    'user',
    'email',
    'project',
    'border',
    'stone',
    'plate',
    'material',
    'texts',
    'accessories',
    'admin',
    'fScreen'
  ];
}
