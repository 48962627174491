import { FilterDefinition, FilterStrategy } from './filter-definition';
import { arrayFlattener } from '../utils';
import { FilterGenerator } from './filter-generator';
import { FulltextFilterGenerator } from './fulltext-filter-generator';
import { ValueFilterGenerator } from './value-filter-generator';

export class FiltersGenerator {
  static generate(source: object, filterDefinitions: Array<FilterDefinition>): Array<string> {
    const filters = filterDefinitions
      .map(definition => FiltersGenerator.generateFilters(definition, source))
      .reduce(arrayFlattener, []);
    return Array.from(new Set<string>(filters));
  }

  private static generateFilters(definition: FilterDefinition, source: object): Array<string> {
    const generator: FilterGenerator = FiltersGenerator.getGenerator(definition.strategy);
    const value: string = definition.valueExtractor(source, definition.key);
    return value ? generator.generate(value) : [];
  }

  private static getGenerator(filterStrategy: FilterStrategy): FilterGenerator {
    switch (filterStrategy) {
      case FilterStrategy.FULLTEXT:
        return new FulltextFilterGenerator();
      case FilterStrategy.VALUE:
        return new ValueFilterGenerator();
    }
  }
}
