import { Inject, Injectable } from '@angular/core';
import { AccessoryFoldersFirestoreService } from '../../modules/accessories/services/accessory-folders-firestore.service';
import { AccessoryItemsFirestoreService } from '../../modules/accessories/services/accessory-items-firestore.service';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { CategoryFolder } from '../../../models/firestore/categories/category-folder.model';
import { Categorizable } from '../../../models/firestore/categories/categorizable.model';
import { Accessory } from '../../../models/firestore/categories/accessory/accessory.model';
import { PlateFoldersFirestoreService } from '../../modules/plate/services/plate-folders-firestore.service';
import { PlateItemsFirestoreService } from '../../modules/plate/services/plate-items-firestore.service';
import { StoneFoldersFirestoreService } from '../../modules/stone/services/stone-folders-firestore.service';
import { StoneItemsFirestoreService } from '../../modules/stone/services/stone-items-firestore.service';
import { FirestoreService } from '../../core/firebase/firestore/firestore.service';
import { Plate } from '../../../models/firestore/categories/plate/plate.model';
import { Stone } from '../../../models/firestore/categories/stone/stone.model';

@Injectable({
  providedIn: 'root'
})
export class FirestoreFolderSelectionMigrationService {

  constructor(
    @Inject(AccessoryFoldersFirestoreService) private readonly accessoryFolders: FirestoreService<CategoryFolder>,
    @Inject(AccessoryItemsFirestoreService) private readonly accessoryItems: FirestoreService<Accessory>,
    @Inject(PlateFoldersFirestoreService) private readonly plateFolders: FirestoreService<CategoryFolder>,
    @Inject(PlateItemsFirestoreService) private readonly plateItems: FirestoreService<Plate>,
    @Inject(StoneFoldersFirestoreService) private readonly stoneFolders: FirestoreService<CategoryFolder>,
    @Inject(StoneItemsFirestoreService) private readonly stoneItems: FirestoreService<Stone>
  ) { }

  migrate() {
    // this.migrateAccessories();
    // this.migratePlates();
    // this.migrateStones();
  }

  private migrateAccessories() {
    forkJoin([
      this.accessoryFolders.read().valueChanges().pipe(take(1)),
      this.accessoryItems.read().valueChanges().pipe(take(1))
    ])
      .subscribe(([folders, items]) => {
        this.addFolderSelectionToItems(items, folders).forEach(item => this.accessoryItems.update(Accessory.of(item)));
      });
  }

  private migratePlates() {
    forkJoin([
      this.plateFolders.read().valueChanges().pipe(take(1)),
      this.plateItems.read().valueChanges().pipe(take(1))
    ])
      .subscribe(([folders, items]) => {
        this.addFolderSelectionToItems(items, folders).forEach(item => this.plateItems.update(Plate.of(item)));
      });
  }

  private migrateStones() {
    forkJoin([
      this.stoneFolders.read().valueChanges().pipe(take(1)),
      this.stoneItems.read().valueChanges().pipe(take(1))
    ])
      .subscribe(([folders, items]) => {
        this.addFolderSelectionToItems(items, folders).forEach(item => this.stoneItems.update(Stone.of(item)));
      });
  }

  private addFolderSelectionToItems<C extends Categorizable>(items: Array<C>, folders: Array<CategoryFolder>): Array<C> {
    return items
      .filter(item => item['folder'] && (typeof item['folder'] === 'string'))
      .map(item => {
        const folder = this.findFolder(item['folder'], folders);
        item['folder'] = folder ? folder.uid : null;
        return item;
      });
  }

  private findFolder(name: string, folders: Array<CategoryFolder>): CategoryFolder {
    return folders.find(folder => folder.name === name);
  }
}
