import { Identifiable } from '../identifiable.model';
import { Filterable } from '../filterable.model';
import { FilterDefinition } from '../../../../commons/filter/filter-definition';

export class Material implements Identifiable, Filterable {
  uid: string = null;
  keywords: Array<string> = [];
  name: string = null;
  map: string = null;
  /** @deprecated **/
  filters: Array<string> = []; // not needed anymore, keeping it until all code that uses it is refactored
  thumbnail: string = null;
  type: MaterialType = MaterialType.Material;
  repeat: number = 1;

  static of(source: Material): Material {
    return Object.assign(new Material(), source);
  }

  static equals(value: Material, other: Material): boolean {
    if (!value && !other) { return true; }
    if (!value || !other) { return false; }
    return value.uid === other.uid
      && value.name === other.name
      && value.type === other.type
      && value.map === other.map
      && value.thumbnail === other.thumbnail;
  }

  getFilterDefinitions(): Array<FilterDefinition> {
    return [FilterDefinition.fulltext('name')];
  }
}

export const enum MaterialType {
  Material = 'material',
  Other = 'other'
}
