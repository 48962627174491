import { Inject, Injectable } from '@angular/core';
import { FirestoreService } from '../../../core/firebase/firestore/firestore.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { CategoryFolder } from '../../../../models/firestore/categories/category-folder.model';

@Injectable({
  providedIn: 'root'
})
export class StoneFoldersFirestoreService extends FirestoreService<CategoryFolder> {

  constructor(@Inject(AngularFirestore) protected readonly firestore: AngularFirestore) { super(firestore); }

  protected get path(): string {
    return 'categories/stone/folders';
  }
}
