import { Point3D } from '../../../point-3d.model';
import { Categorizable, CategoryModelType } from '../categorizable.model';
import { FilterDefinition } from '../../../../../commons/filter/filter-definition';
import { isEqual } from 'lodash-es';
import {Group} from '../shared/group.model';

export class Accessory implements Categorizable {
  uid: string = null;
  name: string = null;
  material: { [s: string]: string } | string = {};
  type: CategoryModelType = CategoryModelType.ACCESSORY;
  filters: Array<string> = [];
  polish: { [s: string]: string } | string = {};
  src: string = null;
  selected: boolean = false;
  thumbnail: string = null;

  groups: Array<Group> = [];
  position: Point3D = { x: 0, y: 0, z: 0 };
  folder: string = null;
  keywords: Array<string> = [];

  static of(source: Accessory): Accessory {
    return Object.assign(new Accessory(), source);
  }

  static equals(value: Accessory, other: Accessory): boolean {
    if (!value && !other) { return true; }
    if (!value || !other) { return false; }
    return value.uid === other.uid
      && value.name === other.name
      && value.type === other.type
      && value.src === other.src
      && value.thumbnail === other.thumbnail
      && isEqual(value.material, other.material)
      && isEqual(value.polish, other.polish)
      && isEqual(value.position, other.position)
      && value.folder === other.folder;
  }

  getFilterDefinitions(): Array<FilterDefinition> {
    return [FilterDefinition.fulltext('name')];
  }

  /** @deprecated **/
  setDefault() {
    this.name = '';
    this.type = CategoryModelType.ACCESSORY;
    this.position = { x: 0, y: 0, z: 0 };
    this.src = '';
    this.thumbnail = '';
    this.material = {};
    this.filters = [];
    this.polish = {};
    this.folder = null;
    this.uid = null;
    return this;
  }

  set(props) {
    this.uid = props.uid;
    this.name = props.name;
    this.type = props.type;
    this.position = props.position;
    this.selected = props.selected;
    this.src = props.src;
    this.groups = props.groups;
    this.thumbnail = props.thumbnail;
    this.material = props.material;
    this.filters = props.filters;
    this.polish = props.polish;
    this.folder = props.folder;
  }

  setMat(props) {
    this.material = props;
  }

  setPolish(props) {
    this.polish = props;
  }

  setPosition(position) {
    this.position.x = position.x;
    this.position.y = position.y;
    this.position.z = position.z;
  }
}
