import { Query, QueryDocumentSnapshot, QueryFn } from '@angular/fire/firestore';
import { FirestoreFilter } from './firestore-filter';
import { FirestoreSort } from './firestore-sort';

export class FirestoreQueryFnBuilder {

  private constructor() {}

  static withSort(value: FirestoreSort): FirestoreQueryFnBuilder {
    return new FirestoreQueryFnBuilder().withSort(value);
  }

  static withFilters(value: Array<FirestoreFilter>): FirestoreQueryFnBuilder {
    return new FirestoreQueryFnBuilder().withFilters(value);
  }

  withSort(value: FirestoreSort): FirestoreQueryFnBuilder {
    this._sort = value;
    return this;
  }

  withFilters(value: Array<FirestoreFilter>): FirestoreQueryFnBuilder {
    this._filters = value;
    return this;
  }

  withLimit(value: number): FirestoreQueryFnBuilder {
    this._limitToLast = null;
    this._limit = value;
    return this;
  }

  withLimitToLast(value: number): FirestoreQueryFnBuilder {
    this._limit = null;
    this._limitToLast = value;
    return this;
  }

  withStartAt(value: QueryDocumentSnapshot<any>, withoutEndCursor: boolean = true): FirestoreQueryFnBuilder {
    if (withoutEndCursor) {
      this.withoutEndCursor();
    }
    this._startAfter = null;
    this._startAt = value;
    return this;
  }

  withEndAt(value: QueryDocumentSnapshot<any>, withoutStartCursor: boolean = true): FirestoreQueryFnBuilder {
    if (withoutStartCursor) {
      this.withoutStartCursor();
    }
    this._endBefore = null;
    this._endAt = value;
    return this;
  }

  withStartAfter(value: QueryDocumentSnapshot<any>, withoutEndCursor: boolean = true): FirestoreQueryFnBuilder {
    if (withoutEndCursor) {
      this.withoutEndCursor();
    }
    this._startAt = null;
    this._startAfter = value;
    return this;
  }

  withEndBefore(value: QueryDocumentSnapshot<any>, withoutStartCursor: boolean = true): FirestoreQueryFnBuilder {
    if (withoutStartCursor) {
      this.withoutStartCursor();
    }
    this._endAt = null;
    this._endBefore = value;
    return this;
  }

  withoutStartCursor(): FirestoreQueryFnBuilder {
    this._startAt = null;
    this._startAfter = null;
    return this;
  }

  withoutEndCursor(): FirestoreQueryFnBuilder {
    this._endAt = null;
    this._endBefore = null;
    return this;
  }

  build(): QueryFn {
    return (query: Query) => {
      query = this.addSorting(query);
      query = this.addFiltering(query);
      query = this.addStartCursor(query);
      query = this.addEndCursor(query);
      return this.addLimit(query);
    };
  }

  private addSorting(query: Query): Query {
    return this._sort ? this._sort.appendTo(query) : query;
  }

  private addFiltering(query: Query): Query {
    return this._filters.reduce((q, filter) => filter.appendTo(q), query);
  }

  private addStartCursor(query: Query): Query {
    if (this._startAt) {
      return query.startAt(this._startAt);
    }
    if (this._startAfter) {
      return query.startAfter(this._startAfter);
    }
    return query;
  }

  private addEndCursor(query: Query): Query {
    if (this._endAt) {
      return query.endAt(this._endAt);
    }
    if (this._endBefore) {
      return query.endBefore(this._endBefore);
    }
    return query;
  }

  private addLimit(query: Query): Query {
    if (this._limit) {
      return query.limit(this._limit);
    }
    if (this._limitToLast) {
      return query.limitToLast(this._limitToLast);
    }
    return query;
  }

  private _sort: FirestoreSort = null;
  private _filters: Array<FirestoreFilter> = [];
  private _limit: number = null;
  private _limitToLast: number = null;
  private _startAt: QueryDocumentSnapshot<any> = null;
  private _startAfter: QueryDocumentSnapshot<any> = null;
  private _endAt: QueryDocumentSnapshot<any> = null;
  private _endBefore: QueryDocumentSnapshot<any> = null;
}
