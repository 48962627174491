import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { UploadMetadata } from '@angular/fire/storage/interfaces';
import { mergeMap, switchMap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AngularFireStorageReference } from '@angular/fire/storage/ref';

@Injectable({ providedIn: 'root' })
export class FirebaseStorageService {

  constructor(
    @Inject(AngularFireStorage) private readonly storage: AngularFireStorage,
    @Inject(HttpClient) private readonly http: HttpClient
  ) {}

  getDownloadUrl(path: string) {
    return this.storage.ref(path).getDownloadURL();
  }

  download(path: string): Observable<any> {
    return this.getDownloadUrl(path).pipe(mergeMap((url: string) => this.http.get(url)));
  }

  upload(path: string, data: File | Blob, metadata?: UploadMetadata): AngularFireUploadTask {
    return this.storage.upload(path, data, metadata);
  }

  uploadAndWaitToComplete(path: string, data: File | Blob, metadata?: UploadMetadata): Observable<string> {
    const uploadRef: AngularFireStorageReference = this.storage.ref(path);
    const uploadTask: AngularFireUploadTask = this.upload(path, data, metadata);
    return from(uploadTask.then()).pipe(switchMap(() => uploadRef.getDownloadURL()));
  }

  delete(path: string): Observable<any> {
    return this.storage.ref(path).delete();
  }
}
