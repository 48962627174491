import { Inject, Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject, PathReference } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FirebaseDbObjectService {
  constructor(@Inject(AngularFireDatabase) private readonly db: AngularFireDatabase) {}

  get<T>(path: PathReference): AngularFireObject<T> {
    return this.db.object<T>(path);
  }

  getCurrentValue<T>(path: PathReference): Observable<T> {
    return this.get<T>(path).valueChanges().pipe(take(1));
  }

  set<T>(path: PathReference, value: T): Promise<void> {
    return this.get(path).set(value);
  }

  update<T>(path: PathReference, obj: object): Promise<void> {
    return this.get(path).update(obj);
  }

  delete(path: PathReference): Promise<void> {
    return this.get(path).remove();
  }
}
