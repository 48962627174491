export const enum FilterStrategy {FULLTEXT, VALUE}

export declare type FilterValueExtractor = (source: object, key: string) => string;

export class FilterDefinition {
  private constructor(
    readonly key: string,
    readonly strategy: FilterStrategy,
    readonly valueExtractor: FilterValueExtractor
  ) {
    this.valueExtractor = this.valueExtractor || FilterDefinition.defaultValueExtractor;
  }

  static fulltext(key: string, valueExtractor?: FilterValueExtractor): FilterDefinition {
    return new FilterDefinition(key, FilterStrategy.FULLTEXT, valueExtractor);
  }

  static value(key: string, valueExtractor?: FilterValueExtractor): FilterDefinition {
    return new FilterDefinition(key, FilterStrategy.VALUE, valueExtractor);
  }

  private static readonly defaultValueExtractor = (source: object, key: string) => source[key];
}
