import { Categorizable, CategoryModelType } from '../categorizable.model';
import { Corner } from './corner.enum';
import { Group } from '../shared/group.model';
import { DimensionLine } from '../shared/dimension-line.model';
import { FilterDefinition } from '../../../../../commons/filter/filter-definition';
import { isEqual } from 'lodash-es';

// @ts-ignore
export class Plate implements Categorizable {
  uid: string = null;
  name: string = null;
  keywords: Array<string> = [];
  type: CategoryModelType = CategoryModelType.PLATE;
  filters: Array<string> = [];
  material: { [s: string]: string } | string = {};
  polish: { [s: string]: string } | string = {};

  src: string;
  thumbnail: string = null;

  corner: Corner = Corner.DEFAULT;
  fixed: boolean;
  innerPlacement: number;
  thickness: number;
  size: number;
  folder: string = null;
  width: number;
  length: number;

  appearance: {material: string, polish: string};

  groups: Array<Group> = []; // is this needed?
  dimensionLines: Array<DimensionLine> = []; // is this needed?

  static of(source: Plate): Plate {
    return Object.assign(new Plate(), source);
  }

  static equals(value: Plate, other: Plate): boolean {
    if (!value && !other) { return true; }
    if (!value || !other) { return false; }
    return value.uid === other.uid
      && value.name === other.name
      && value.type === other.type
      && value.src === other.src
      && isEqual(value.appearance, other.appearance)
      && value.thumbnail === other.thumbnail
      && isEqual(value.material, other.material)
      && isEqual(value.polish, other.polish)
      && isEqual(value.dimensionLines, other.dimensionLines)
      && value.corner === other.corner
      && value.innerPlacement === other.innerPlacement
      && value.thickness === other.thickness
      && value.size === other.size
      && value.width === other.width
      && value.length === other.length
      && value.folder === other.folder;
  }

  getFilterDefinitions(): Array<FilterDefinition> {
    return [FilterDefinition.fulltext('name')];
  }


  set(props) {
    this.name = props.name;
    this.src = props.src;
    this.size = props.size;
    this.thumbnail = props.thumbnail;
    this.corner = props.corner;
    this.material = props.material;
    this.appearance = {material: (props.appearance?.material === '') ? undefined : props.appearance?.material,
                       polish: (props.appearance?.polish === '') ?  undefined : props.appearance?.polish};
    this.type = props.type;
    this.fixed = props.fixed;
    this.innerPlacement = +props.innerPlacement;
    this.filters = props.filters;
    this.thickness = props.thickness;
    this.width = props.width;
    this.length = props.length;
    this.polish = props.polish;
    this.folder = props.folder;
    this.groups = props.groups || [];
    this.dimensionLines = props.dimensionLines || [];
  }

  setDefault() {
    this.name = '';
    this.src = '';
    this.thumbnail = '';
    this.corner = Corner.DEFAULT;
    this.appearance = {
      material: undefined,
      polish: undefined
    };
    this.material = '';
    this.type = CategoryModelType.PLATE;
    this.fixed = false;
    this.innerPlacement = 0;
    this.filters = [];
    this.thickness = 0;
    this.width = 0;
    this.length = 0;
    this.polish = {};
    this.folder = '';
    this.groups = [];
    this.dimensionLines = [];
    this.uid = null;
    return this;
  }

  setThick(thick) {
    this.thickness = thick;
  }

  setInnerPlacement(placement) {
    Object.assign(this.innerPlacement, placement);
  }

  /*****CORNER FUNCTIONS*****/


  setCorner(corner) {
    this.corner = corner;
  }


}
