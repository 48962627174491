import { adminRoutesNames, AdminRoutesNames } from './admin-routes.names';
import { appRoutesNames } from '../../app-routes.names';

export declare type RouteResolver<T> = { [key in keyof T]: (...args: Array<any>) => string };

export const adminRoutesResolvers: RouteResolver<AdminRoutesNames> = {
  ROOT: () => `${appRoutesNames.ADMIN}`,
  BORDERS: () => `${appRoutesNames.ADMIN}/${adminRoutesNames.BORDERS}`,
  STONES: () => `${appRoutesNames.ADMIN}/${adminRoutesNames.STONES}`,
  STONES_FOLDERS: () => `${appRoutesNames.ADMIN}/${adminRoutesNames.STONES_FOLDERS}`,
  PLATES: () => `${appRoutesNames.ADMIN}/${adminRoutesNames.PLATES}`,
  PLATES_FOLDERS: () => `${appRoutesNames.ADMIN}/${adminRoutesNames.PLATES_FOLDERS}`,
  ACCESSORIES: () => `${appRoutesNames.ADMIN}/${adminRoutesNames.ACCESSORIES}`,
  ACCESSORIES_FOLDERS: () => `${appRoutesNames.ADMIN}/${adminRoutesNames.ACCESSORIES_FOLDERS}`,
  MATERIALS: () => `${appRoutesNames.ADMIN}/${adminRoutesNames.MATERIALS}`,
  POLISHES: () => `${appRoutesNames.ADMIN}/${adminRoutesNames.POLISHES}`,
  TEXT_FONTS: () => `${appRoutesNames.ADMIN}/${adminRoutesNames.TEXT_FONTS}`,
};
