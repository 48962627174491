import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreCollectionGroup,
  AngularFirestoreDocument,
  QueryFn, QueryGroupFn,
  SetOptions
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { Identifiable } from '../../../../models/firestore/identifiable.model';
import { Filterable, isFilterable } from '../../../../models/firestore/filterable.model';
import { FiltersGenerator } from '../../../../../commons/filter/filters-generator';

export abstract class FirestoreService<T extends Identifiable> {

  protected constructor(protected readonly firestore: AngularFirestore) { }

  protected abstract get path(): string;

  protected get collectionGroupPath(): string {
    return this.path;
  }

  createId(): string {
    return this.firestore.createId();
  }

  get(uid: string): AngularFirestoreDocument<T> {
    return this.firestore.doc<T>(`${this.path}/${uid}`);
  }

  getCurrentValue(uid: string): Observable<T> {
    return this.get(uid).valueChanges().pipe(take(1));
  }

  read(queryFn?: QueryFn): AngularFirestoreCollection<T> {
    return this.firestore.collection<T>(this.path, queryFn);
  }

  // todo: see how this affects the system, changed from 'T' to 'any'
  readGroup(queryGroupFn?: QueryGroupFn): AngularFirestoreCollectionGroup<any> {
    return this.firestore.collectionGroup(this.collectionGroupPath, queryGroupFn);
  }

  add(data: T): Promise<void> {
    this.addUid(data);
    const payload = this.prepareToPersist(data);
    return this.read().doc(payload.uid).set(payload);
  }

  set(data: T, options?: SetOptions): Promise<void> {
    this.verifyUid(data);
    const payload = this.prepareToPersist(data);
    return this.get(payload.uid).set(payload, options);
  }

  update(data: T): Promise<void> {
    this.verifyUid(data);
    const payload = this.prepareToPersist(data);
    return this.get(payload.uid).update(payload);
  }

  delete(uid: string): Promise<void> {
    return this.get(uid).delete();
  }

  // todo: remove caching when threejs' services logic is refactored to be async
  updateCache() {
    this.updateCache$().subscribe();
  }

  // todo: remove caching when threejs' services logic is refactored to be async
  updateCache$() {
    return this.read().valueChanges()
      .pipe(
        take(1),
        tap((items: Array<T>) => this.cachedItems = items)
      );
  }

  // todo: remove caching when threejs' services logic is refactored to be async
  getCached(): Array<T> {
    return this.cachedItems;
  }

  private prepareToPersist(obj: any): any {
    if (isFilterable(obj)) {
      this.updateFilterableKeywords(obj);
    }
    return { ...obj }; // firebase can't save object instances
  }

  private addUid(obj: T) {
    obj.uid = this.createId();
  }

  private verifyUid(obj: T) {
    if (!obj.uid) {
      throw new Error(`Missing or empty value for 'uid' property for obj: ${JSON.stringify(obj)}`);
    }
  }

  private updateFilterableKeywords(obj: Filterable) {
    obj.keywords = FiltersGenerator.generate(obj, obj.getFilterDefinitions());
  }

  private cachedItems: Array<T> = [];
}
