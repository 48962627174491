import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { ThreeViewComponent } from './three-view/three-view.component';
import { appRoutesNames } from './app-routes.names';
import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { pipe } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './core/auth/auth.service';
import {RequiredRoleGuard} from './core/auth/required-role-guard';

const redirectUnauthorizedToLogin = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => pipe(
  redirectUnauthorizedTo([appRoutesNames.LOGIN]),
  tap(value => AuthService.REDIRECT_AFTER_AUTH_URL = (value === true) ? null : state.url)
);
const redirectLoggedInToHelp = () => redirectLoggedInTo([appRoutesNames.HELP]);

const routes: Routes = [
  {
    path: appRoutesNames.LOGIN,
    data: { authGuardPipe: redirectLoggedInToHelp },
    canActivate: [AngularFireAuthGuard],
    component: LoginComponent,
    children: [
      { path: ':token', component: LoginComponent },
      { path: '**', redirectTo: '' }
    ]
  },
  {
    path: appRoutesNames.ROOT,
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    canActivate: [AngularFireAuthGuard],
    component: ThreeViewComponent,
    children: [
      { path: appRoutesNames.HELP, loadChildren: () => import('./modules/help/help.module').then(m => m.HelpModule) },
      { path: appRoutesNames.USER, loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule) },
      { path: appRoutesNames.EMAIL, loadChildren: () => import('./modules/email/email.module').then(m => m.EmailModule) },
      { path: appRoutesNames.PROJECT, loadChildren: () => import('./modules/project/project.module').then(m => m.ProjectModule) },
      { path: appRoutesNames.GROUND, loadChildren: () => import('./modules/ground/ground.module').then(m => m.GroundModule) },
      { path: appRoutesNames.STONE, loadChildren: () => import('./modules/stone/stone.module').then(m => m.StoneModule) },
      { path: appRoutesNames.PLATE, loadChildren: () => import('./modules/plate/plate.module').then(m => m.PlateModule) },
      { path: appRoutesNames.MATERIAL, loadChildren: () => import('./modules/material/material.module').then(m => m.MaterialModule) },
      { path: appRoutesNames.TEXT, loadChildren: () => import('./modules/text/text.module').then(m => m.TextModule) },
      {
        path: appRoutesNames.ACCESSORIES,
        loadChildren: () => import('./modules/accessories/accessories.module').then(m => m.AccessoriesModule)
      },
      { path: appRoutesNames.ADMIN, canActivate:[RequiredRoleGuard], data:{ roles: ['admin']}, loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule) },
      { path: '', redirectTo: appRoutesNames.HELP, pathMatch: 'full' }
    ]
  },
  { path: '**', redirectTo: appRoutesNames.ROOT }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
