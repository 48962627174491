import { Query } from '@angular/fire/firestore';

const enum SortDirection {
  ASCENDING = 'asc',
  DESCENDING = 'desc'
}

export class FirestoreSort {
  private constructor(
    readonly property: string,
    readonly direction: SortDirection
  ) {}

  static ascending(property: string): FirestoreSort {
    return new FirestoreSort(property, SortDirection.ASCENDING);
  }

  static descending(property: string): FirestoreSort {
    return new FirestoreSort(property, SortDirection.DESCENDING);
  }

  appendTo(query: Query) {
    return query.orderBy(this.property, this.direction);
  }
}
