import { FirestoreService } from './firestore.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { LocalStorage } from 'ngx-webstorage';
import { AppUser } from '../../../modules/user/data/user.model';
import { Identifiable } from '../../../../models/firestore/identifiable.model';

export abstract class FirestoreUserResourceService<T extends Identifiable> extends FirestoreService<T> {
  protected constructor(protected readonly firestore: AngularFirestore) { super(firestore); }

  // todo: remove after migration
  /**
   * Retrieve resources of another user than the logged in one
   */
  set userUid(value: string) {
    this._userUid = value;
  }

  protected abstract get resourcePath(): string;

  protected get path(): string {
    const uid = this._userUid || this.appUser.uid;
    return `users/${uid}/${this.resourcePath}`;
  }

  @LocalStorage()
  private appUser: AppUser;
  private _userUid: string = null;
}
