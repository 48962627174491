export const appRoutesNames = {
  ROOT: '',
  LOGIN: 'login',
  HELP: 'help',
  USER: 'user',
  EMAIL: 'email',
  PROJECT: 'project',
  GROUND: 'ground',
  STONE: 'stone',
  PLATE: 'plate',
  MATERIAL: 'material',
  TEXT: 'text',
  ACCESSORIES: 'accessories',
  ADMIN: 'admin',
};
