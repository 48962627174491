import { Injectable } from '@angular/core';
import { MaterialService } from './material.service';
import { UtilsService } from '../three-utils/utils.service';
import { UiService } from '../../services/ui.service';
import { BorderGeneratedService } from './border-generated.service';


@Injectable()
export class WithoutBordersService {

  constructor(
    private uiService: UiService,
    private utilsService: UtilsService,
    private materialService: MaterialService,
    private borderGeneratedService: BorderGeneratedService
  ) { }

  public initWB() {
    const border = this.utilsService.getNodeByName('border').children[0];
    const bDim = this.utilsService.getBorderSize();
    const ground = this.utilsService.getNodeByName('ground');
    // ground.children[0].position.y = 0.1;
    // border.scale.y = 0.5;
    // bDim.monumentHolderHeight = 10;
    // bDim.borderHeight = 10;

    bDim.borderHeight = 10;
    bDim.monumentHolderHeight = 10;
    bDim.borderTickness = 10;
    // bDim.monumentHolderWidth = this.borderGeneratedService.groundWidth - 20;
    bDim.monumentHolderLength = 10;
    bDim.withoutBorders = true;

    this.utilsService.setBorderSize(bDim);
    const materialName = 'bushMaterial';
    this.materialService.applyMaterial(border, materialName);
    // this.utilsService.updateChain(["stone", "plate", "accessories"]);

    // this.borderGeneratedService.updateBorders(bDim);

    // for (let i = 0; i < border.children.length; i++) {
    //   if (border.children[i].name === "MonumentHolderModel") {
    //     border.children[i].scale.z = -0.1;
    //     bDim.monumentHolderHeight = -0.1;
    //     bDim.borderHeight = 10;
    //     this.utilsService.setBorderSize(bDim);
    //     let materialName = "bushMaterial";
    //     this.materialService.applyMaterial(border, materialName);
    //     this.utilsService.updateChain(["stone", "plate", "accessories"]);

    //     //filter plates on ui
    //     this.uiService.withoutBordersProps();

    //     //send to project
    //     this.uiService.projectService.eventChanged({
    //       action: "without-borders",
    //       value: bDim,
    //       object: border,
    //       material: materialName,
    //       wB: true
    //     })
    //   }
    // }
  }
}
