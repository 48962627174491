import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MaterialInputControlValueAccessor } from '../material-input.cva';
import { FormControl, NgControl } from '@angular/forms';

@Component({
  selector: 'app-material-text-input',
  template: `
    <mat-form-field>
      <mat-label *ngIf="label">{{label}}</mat-label>
      <input matInput type="text" placeholder="{{placeholder}}" [formControl]="control" [errorStateMatcher]="errorStateMatcher"/>

      <button *ngIf="icon" mat-icon-button matSuffix (click)="iconClick.emit()">
        <mat-icon>{{icon}}</mat-icon>
      </button>

      <mat-error>
        <fmx-errors [errors]="externalErrors$ | async" [controlLabel]="label || placeholder"></fmx-errors>
      </mat-error>
    </mat-form-field>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaterialTextInputComponent extends MaterialInputControlValueAccessor {
  @Input() readonly icon: string = null;
  @Output() readonly iconClick: EventEmitter<void> = new EventEmitter<void>();
  readonly control: FormControl = new FormControl();

  constructor(@Inject(NgControl) protected readonly ngControl: NgControl) { super(ngControl); }

  protected get defaultDebounceTime(): number {
    return 1000;
  }
}
