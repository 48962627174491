import { Inject, Injectable } from '@angular/core';
import { BorderItemsFirestoreService } from '../../modules/ground/services/border-items-firestore.service';
import { AppData } from '../../../models/app/app-data.model';
import { Border } from '../../../models/firestore/categories/border/border.model';
import { StoneItemsFirestoreService } from '../../modules/stone/services/stone-items-firestore.service';
import { StoneFoldersFirestoreService } from '../../modules/stone/services/stone-folders-firestore.service';
import { Stone } from '../../../models/firestore/categories/stone/stone.model';
import { CategoryFolder } from '../../../models/firestore/categories/category-folder.model';
import { Categorizable, CategoryModelType } from '../../../models/firestore/categories/categorizable.model';
import { ArrayUtils } from '../../shared/utils/array.utils';
import { Material } from '../../../models/firestore/materials/material.model';
import { MaterialsFirestoreService } from '../../modules/material/services/materials-firestore.service';
import { PolishesFirestoreService } from '../../modules/material/services/polishes-firestore.service';
import { Polish } from '../../../models/firestore/polishes/polish.model';
import { Environment } from '../../../models/firestore/environments/environment.model';
import { EnvironmentFirestoreService } from '../../modules/project/services/environment-firestore.service';
import { TextMaterialsFirestoreService } from '../../modules/text/services/text-materials-firestore.service';
import { TextFontsFirestoreService } from '../../modules/text/services/text-fonts-firestore.service';
import { AppDataText } from '../../../models/app/app-data-text.model';
import { PlateItemsFirestoreService } from '../../modules/plate/services/plate-items-firestore.service';
import { PlateFoldersFirestoreService } from '../../modules/plate/services/plate-folders-firestore.service';
import { Plate } from '../../../models/firestore/categories/plate/plate.model';
import { AccessoryItemsFirestoreService } from '../../modules/accessories/services/accessory-items-firestore.service';
import { AccessoryFoldersFirestoreService } from '../../modules/accessories/services/accessory-folders-firestore.service';
import { Accessory } from '../../../models/firestore/categories/accessory/accessory.model';

@Injectable({
  providedIn: 'root'
})
export class FirestoreMigrationService {

  constructor(
    @Inject(BorderItemsFirestoreService) protected readonly borderItems: BorderItemsFirestoreService,
    @Inject(StoneItemsFirestoreService) protected readonly stoneItems: StoneItemsFirestoreService,
    @Inject(StoneFoldersFirestoreService) protected readonly stoneFolders: StoneFoldersFirestoreService,
    @Inject(PlateItemsFirestoreService) protected readonly plateItems: PlateItemsFirestoreService,
    @Inject(PlateFoldersFirestoreService) protected readonly plateFolders: PlateFoldersFirestoreService,
    @Inject(AccessoryItemsFirestoreService) protected readonly accessoryItems: AccessoryItemsFirestoreService,
    @Inject(AccessoryFoldersFirestoreService) protected readonly accessoryFolders: AccessoryFoldersFirestoreService,
    @Inject(MaterialsFirestoreService) protected readonly materialItems: MaterialsFirestoreService,
    @Inject(PolishesFirestoreService) protected readonly polishItems: PolishesFirestoreService,
    @Inject(EnvironmentFirestoreService) protected readonly environmentItems: EnvironmentFirestoreService,
    @Inject(TextMaterialsFirestoreService) protected readonly textMaterialsItems: TextMaterialsFirestoreService,
    @Inject(TextFontsFirestoreService) protected readonly textFontsItems: TextFontsFirestoreService
  ) { }

  migrate(appData: AppData) {
    // this.migrateEnvironments(appData.Environment);
    // this.migrateText(appData.Text);

    // this.migrateMaterials(appData.Material);
    // this.migratePolishes(appData.Polish);
    //
    // this.migrateAccessories(appData.Accessories);
    // this.migratePlates(appData.Platten);
    // this.migrateBorders(appData.Einfassung);
    // this.migrateStones(appData.Grabsteine);
  }

  // BORDERS

  private migrateBorders(borders: Array<Border>) {
    borders
      // .filter((x, i) => i < 50)
      .forEach(border => this.borderItems.add(this.migrateBorder(border)));
  }

  private migrateBorder(border: Border): Border {
    this.updateFilters(border);
    const newBorder: Border = new Border();
    this.applyOnlyExistingProperties(newBorder, border);
    // border.keywords = [];
    // delete border['uuid'];
    // delete border['_comment'];
    return newBorder;
  }

  private updateFilters(border: Border) {
    if (ArrayUtils.isEmpty(border.filters)) {
      const dimensionsString: string = border.name.split(' ')[1];
      const dimensions: Array<string> = dimensionsString.split('x');
      border.filters = [Border.toGroundDimensionFilter(+dimensions[0], +dimensions[1])];
    }
    border.additionalFilters = { groundDimension: border.filters[0] };
  }

  // FOLDERS

  private migrateFolder(cat: Categorizable): CategoryFolder {
    const categories: Array<string> = ArrayUtils.isEmpty(cat.filters) ? [] : cat.filters.filter(
      filter => filter && (filter !== cat.name) && (filter !== 'folder'));
    const folderCopy = {
      name: cat.name,
      thumbnail: cat.thumbnail,
      category: ArrayUtils.isEmpty(categories) ? null : categories[0],
      keywords: []
    };
    const newFolder = new CategoryFolder();
    this.applyOnlyExistingProperties(newFolder, folderCopy);
    return newFolder;
  }

  // STONES

  private migrateStones(stones: Array<Stone>) {
    stones
      // .filter((x, i) => i < 50)
      .forEach(stone => stone.type === CategoryModelType.FOLDER
                        ? this.stoneFolders.add(this.migrateFolder(stone))
                        : this.stoneItems.add(this.migrateStone(stone)));
  }

  private migrateStone(stone: Stone): Stone {
    const newStone = new Stone();
    this.applyOnlyExistingProperties(newStone, stone);
    return newStone;
  }

  // PLATES

  private migratePlates(plates: Array<Plate>) {
    plates
      // .filter((x, i) => i < 50)
      .forEach(plate => plate.type === CategoryModelType.FOLDER
                        ? this.plateFolders.add(this.migrateFolder(plate))
                        : this.plateItems.add(this.migratePlate(plate)));
  }

  private migratePlate(plate: Plate): Plate {
    const newPlate = new Plate();
    this.applyOnlyExistingProperties(newPlate, plate);
    return newPlate;
  }

  // ACCESSORIES

  private migrateAccessories(accessories: Array<Accessory>) {
    accessories
      // .filter((x, i) => i < 50)
      .forEach(accessory => accessory.type === CategoryModelType.FOLDER
                            ? this.accessoryFolders.add(this.migrateFolder(accessory))
                            : this.accessoryItems.add(this.migrateAccessory(accessory)));
  }

  private migrateAccessory(accessory: Accessory): Accessory {
    const newAccessory = new Accessory();
    this.applyOnlyExistingProperties(newAccessory, accessory);
    return newAccessory;
  }

  // MATERIALS

  private migrateMaterials(materials: Array<Material>) {
    materials
      .filter((x, i) => i !== 0)
      .forEach(material => this.materialItems.add(this.migrateMaterial(material)));
  }

  private migrateMaterial(material: Material): Material {
    const newMaterial = new Material();
    this.applyOnlyExistingProperties(newMaterial, material);
    return newMaterial;
  }

  // POLISHES

  private migratePolishes(polishes: Array<Polish>) {
    polishes
      // .filter((x, i) => i !== 0 && i < 2)
      .forEach(polish => this.polishItems.add(this.migratePolish(polish)));
  }

  private migratePolish(polish: Polish): Polish {
    const newPolish = new Polish();
    this.applyOnlyExistingProperties(newPolish, polish);
    return newPolish;
  }

  // ENVIRONMENTS

  private migrateEnvironments(envs: Array<Environment>) {
    envs.forEach(env => this.environmentItems.add(this.migrateEnvironment(env)));
  }

  private migrateEnvironment(env: Environment): Environment {
    const newEnvironment = new Environment();
    this.applyOnlyExistingProperties(newEnvironment, env);
    return newEnvironment;
  }

  // TEXT

  private migrateText(text: AppDataText) {
    text.Materials.forEach(material => this.textMaterialsItems.add(material));
    text.Fonts.forEach(font => this.textFontsItems.add(font));
  }

  private applyOnlyExistingProperties<T>(object: T, value: T) {
    const keys = Object.keys(object);
    keys.forEach(key => {
      // console.log(`For key: ${key}, default value is: ${object[key]}, migration value is: ${value[key]}`);
      if (!this.isEmptyValue(value[key])) {
        object[key] = value[key];
        // console.log(`Migrated new value: ${value[key]}`);
      }
    });
  }

  private isEmptyValue(value): boolean {
    if (value === null || value === undefined) { return true;}
    if (Array.isArray(value) && value.length === 0) { return true; }
    if (value === {}) {return true; }
    return false;
  }
}
