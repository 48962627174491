import { FilterGenerator } from './filter-generator';
import { arrayFlattener } from '../utils';

export class FulltextFilterGenerator implements FilterGenerator {
  generate(value: string): Array<string> {
    return FulltextFilterGenerator.generateFilterableKeywords(value);
  }

  private static generateFilterableKeywords(value: string): Array<string> {
    const substrings: Array<string> = Array.from(Array(value.length))
      .map((_, i) => FulltextFilterGenerator.generateSubstrings(value.slice(i).toLowerCase()))
      .reduce(arrayFlattener, []);

    substrings.unshift('');
    return Array.from(new Set<string>(substrings));
  }

  private static splitIntoWords(phrase: string): Array<string> {
    const words = phrase.split(' ');
    return words.length ? [...words, phrase] : [phrase];
  }

  private static generateSubstrings(value: string): Array<string> {
    return Array.from(Array(value.length)).map((_, i: number) => value.substring(0, i + 1));
  }
}
