export enum Corner {
  NONE = 'none',
  DEFAULT = 'bottom-left',
  TOP = 'top',
  BOTTOM = 'bottom',
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
  FULL = 'full',
  LEFT = 'left',
  RIGHT = 'right',
  ALL = 'all'
}
