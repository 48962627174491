import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ThreeJsSceneSettingsService } from './services/settings/three-js-scene-settings.service';
import { ThreeViewService } from './three-view.service';
import { ProjectService } from '../services/project.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  templateUrl: './three-view.component.html',
  styleUrls: ['./three-view.component.scss']
})
export class ThreeViewComponent implements OnInit {
  @ViewChild('threeJS', { static: true }) threeJsDiv: ElementRef;
  @ViewChild('mainUi', { static: true }) mainUiDiv: ElementRef;

  constructor(
    @Inject(ThreeJsSceneSettingsService) private readonly threeJsService: ThreeJsSceneSettingsService,
    @Inject(ThreeViewService) private readonly threeViewService: ThreeViewService,
    @Inject(ProjectService) private readonly projectService: ProjectService
  ) { }

  ngOnInit() {
    this.threeJsService.init(this.threeJsDiv.nativeElement, this.mainUiDiv.nativeElement);
    this.projectService.bootApp(this.threeJsDiv.nativeElement);
    this.showLogoSubject.next(false);
  }

  private readonly showLogoSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  // tslint:disable-next-line:member-ordering
  readonly showLogo$: Observable<boolean> = this.showLogoSubject.asObservable();
}
