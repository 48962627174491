import {Inject, Injectable} from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/auth';
import {User, auth as fireAuth, app} from 'firebase';
import {delay, filter, map, mergeMap, switchMap, take, tap} from 'rxjs/operators';
import {NavigationService} from '../services/navigation.service';
import {UserStorageService} from './user-storage.service';
import {AbstractLocalStorageService} from '../services/abstract-local-storage.service';
import {AppUser, UserProfile} from '../../modules/user/data/user.model';
import {UserFirestoreService} from '../../modules/user/data/user-firestore.service';
import {FirestoreService} from '../firebase/firestore/firestore.service';
import {CookieService} from 'ngx-cookie';
import {AngularFireFunctions} from '@angular/fire/functions';
import {AuthRequest} from './auth.model';
import {JoyrideService} from 'ngx-joyride';
import UserCredential = firebase.auth.UserCredential;
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class AuthService {
  static REDIRECT_AFTER_AUTH_URL: string = null;

  readonly appUser$: Observable<AppUser | null> = this.auth.user.pipe(mergeMap(user => this.findAppUser(user)));

  constructor(
    @Inject(AngularFireAuth) private readonly auth: AngularFireAuth,
    @Inject(AngularFireFunctions) private readonly firebaseFunctions: AngularFireFunctions,
    @Inject(UserFirestoreService) private readonly userFirestoreService: FirestoreService<AppUser>,
    @Inject(UserStorageService) private readonly userStorage: AbstractLocalStorageService<AppUser>,
    @Inject(NavigationService) private readonly navigationService: NavigationService,
    @Inject(CookieService) private readonly cookieService: CookieService,
    @Inject(JoyrideService) private readonly tutorialService: JoyrideService,
  ) {
  }

  static toAppUser(user: User): AppUser {
    return {uid: user.uid, email: user.email, photoURL: user.photoURL, name: user.displayName};
  }

  static toUserProfile(appUser: AppUser): UserProfile {
    return {displayName: appUser.name, photoURL: appUser.photoURL};
  }

  signUpWithEmail(email: string, password: string) {
    return from(this.auth.createUserWithEmailAndPassword(email, password))
      .pipe(
        switchMap(() => from(this.auth.currentUser)),
        tap(data => console.log(data)),
        tap((user) => user.sendEmailVerification()),
        switchMap(() => from(this.auth.signOut()))
      );
  }

  signInWithEmailAndPassword(email: string, password: string): Observable<AppUser> {
    return from(this.auth.signInWithEmailAndPassword(email, password))
      .pipe(
        switchMap(userCredential => this.checkEmailVerified(userCredential)),
        switchMap(userCredential => this.findAppUser(userCredential.user)),
        tap(appUser => {
          this.userStorage.store(appUser);
          if (localStorage.getItem('tutorialDone') != 'true') {
            this.tutorialService.startTour({
              steps: ['firstStep@help', 'secondStep@help', 'thirdStep@project', 'fourthStep@project', 'fifthStep@ground', 'sixthStep@user', 'seventhStep@email'],
              waitingTime: 100
            });
            localStorage.setItem('tutorialDone', 'true');
          }
        })
      );
  }

  signInWithJoomlaToken(token: string): Observable<AppUser | null> {
    const decodeToken$ = this.firebaseFunctions.httpsCallable('decodeJoomlaToken');
    return decodeToken$(token).pipe(
      switchMap((request: AuthRequest | null) => request ? this.signInWithEmailAndPassword(request.email, request.password) : of(null))
    );
  }

  signInWithGoogle() {
    const googleAuthProvider = new fireAuth.GoogleAuthProvider();
    return from(this.auth.signInWithPopup(googleAuthProvider))
      .pipe(
        switchMap(userCredential => {
          if (userCredential.additionalUserInfo.isNewUser) {
            return of(AuthService.toAppUser(userCredential.user));
          } else {
            return this.findAppUser(userCredential.user);
          }
        }),
        tap(appUser => {
          this.userStorage.store(appUser);
          if (localStorage.getItem('tutorialDone') != 'true') {
            this.tutorialService.startTour({
              steps: ['firstStep@help', 'secondStep@help', 'thirdStep@project', 'fourthStep@project', 'fifthStep@ground', 'sixthStep@user', 'seventhStep@email'],
              waitingTime: 100
            });
            localStorage.setItem('tutorialDone', 'true');
          }
        })
      );
  }

  logout() {
    this.auth.signOut().then(() => {
      this.cookieService.remove('test_cookie');
      this.cookieService.remove('app_session', {path: '/', domain: '.lithosmarmor.de'}); // joomla cookie
      this.userStorage.clear();
      this.navigationService.goToLoginPage();
    });
  }

  sendPassReset(email: string) {
    return from(this.auth.sendPasswordResetEmail(email));
  }

  updateUser(appUser: AppUser): Observable<void> {
    return this.auth.user
      .pipe(
        take(1),
        filter(user => !!user),
        switchMap((user: User) => this.updateAuthUser(user, appUser)),
        switchMap(() => this.userFirestoreService.set(appUser, {merge: true})),
        tap(() => this.userStorage.store(appUser))
      );
  }

  hasAnyClaim(claims: Array<string>): Observable<boolean> {
    return this.auth.idTokenResult
      .pipe(
        take(1),
        map(tokenResult => tokenResult ? this.isMatchingAnyClaim(tokenResult.claims, claims) : false)
      );
  }

  private checkEmailVerified(userCredential: UserCredential) {
    if (userCredential.user.emailVerified) {
      return of(userCredential);
    }
    return throwError('Ihre Email-Adresse wurde noch nicht bestätigt.');
  }

  private findAppUser(user: User): Observable<AppUser | null> {
    if (!user) {
      return of(null);
    }
    return this.userFirestoreService.getCurrentValue(user.uid);
  }

  private updateAuthUser(user: User, appUser: AppUser): Observable<void> {
    const needsProfileUpdate = (user.photoURL !== appUser.photoURL) || (user.displayName !== appUser.name);
    return needsProfileUpdate ? from(user.updateProfile(AuthService.toUserProfile(appUser))) : of(null);
  }

  private isMatchingAnyClaim(userClaims: { [key: string]: any }, claimsToMatch: Array<string>): boolean {
    return Object.keys(userClaims).some(claim => claimsToMatch.includes(claim));
  }
}
