import { LocalStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';

export abstract class AbstractLocalStorageService<T> {
  protected constructor(
    protected readonly localStorageService: LocalStorageService,
    private readonly storageKey: string
  ) {}

  store(value: T): T {
    return this.localStorageService.store(this.storageKey, value);
  }

  retrieve(): T {
    return this.localStorageService.retrieve(this.storageKey);
  }

  clear() {
    this.localStorageService.clear(this.storageKey);
  }

  observe(): Observable<T> {
    return this.localStorageService.observe(this.storageKey);
  }
}
