import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { appRoutesNames } from '../../app-routes.names';

@Injectable({ providedIn: 'root' })
export class NavigationService {

  constructor(@Inject(Router) private readonly router: Router) {}

  goTo(path: string): Promise<boolean> {
    return this.router.navigate([path]);
  }

  goToHomePage(): Promise<boolean> {
    return this.goTo(appRoutesNames.ROOT);
  }

  goToLoginPage(): Promise<boolean> {
    return this.goTo(appRoutesNames.LOGIN);
  }

  goToUserPage(): Promise<boolean> {
    return this.goTo(appRoutesNames.USER);
  }

  goToEmailPage(): Promise<boolean> {
    return this.goTo(appRoutesNames.EMAIL);
  }
}
