import { Inject, Injectable } from '@angular/core';
import { FirestoreService } from '../../../core/firebase/firestore/firestore.service';
import { Border } from '../../../../models/firestore/categories/border/border.model';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class BorderItemsFirestoreService extends FirestoreService<Border> {

  constructor(@Inject(AngularFirestore) protected readonly firestore: AngularFirestore) { super(firestore); }

  protected get path(): string {
    return 'categories/border/items';
  }
}
