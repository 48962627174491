import { Inject, Injectable } from '@angular/core';
import { ProjectService } from './project.service';
import { EventsService } from './events.service';
import { UtilsService } from '../three-view/three-utils/utils.service';
import { UploadFileService } from './upload-file-service.service';
import { FirebaseDbObjectService } from '../core/firebase/firebase-db-object.service';
import { FirebaseStorageService } from '../core/firebase/firebase-storage.service';
import { ThreeJsNode } from './ui-service-addons';
import { OldCategoryName } from '../modules/admin/category-select/categories.enum';
import { ModelUtils } from '../../models/project/model.utils';
import {PlateService} from '../three-view/three-services/plate.service';
import {Corner} from '../../models/firestore/categories/plate/corner.enum';

@Injectable({ providedIn: 'root' })
export class UiService {

  public isJoomla: boolean;
  // public lastSelected = {
  //   border: undefined,
  //   stone: undefined,
  //   plate: undefined,
  //   environment: undefined,
  //   material: undefined,
  //   polish: undefined,
  //   project: undefined
  // };
  public uiOriginal = {
    border: [],
    stone: [],
    plate: [],
    material: [],
    accessories: []
  };
  isAdmin = false;
  public defaultFilter = 'folder';

  constructor(
    @Inject(FirebaseStorageService) private readonly storage: FirebaseStorageService,
    @Inject(FirebaseDbObjectService) private readonly dbObj: FirebaseDbObjectService,
    @Inject(ProjectService) private readonly projectService: ProjectService,
    @Inject(PlateService) private readonly plateService: PlateService,
    @Inject(EventsService) private readonly eventsService: EventsService,
    @Inject(UtilsService) private readonly utilsService: UtilsService,
    @Inject(UploadFileService) private readonly uploadFileService: UploadFileService
  ) {
  }

  public setCachedThumbs(thumb, value) {
    this._cachedThumbs[thumb] = value;
  }

  public setThumb(thumb) {
    return new Promise((resolve, reject) => {
      this.storage.getDownloadUrl(`restricted/${thumb}`)
        .subscribe(url => {
          this.setCachedThumbs(thumb, url);
          resolve(url);
        });
    });

  }

  public getCachedThumbs(thumb) {
    if (this._cachedThumbs[thumb]) {
      return this._cachedThumbs[thumb];
    }
  }

  public initEvents() {
    this.eventsService.initEvents();
  }

  switchEvent(name) {
    this._changeMateFolder = false;
    this.isAdmin = false;
    switch (name) {
      // case 'ground':
      //   this.lastSelected.environment = this.projectService.project.environment.uid;
      //   break;
      // case 'border':
      //   this.lastSelected[name] = this.projectService.project[name].uid;
      //   break;
      // case 'stone':
      //   this.lastSelected[name] = this.projectService.project[name].uid;
      //   break;
      // case 'plate':
      //   this.lastSelected[name] = this.plateService.getPlatesCopy()[0].uid;
      //   break;
      case 'menu':
        this.uiTextNeedsRefreshing = true;
        break;
      case 'material':
        this._changeMateFolder = true;
        break;
      // case 'project':
      //   this.lastSelected.project = this.projectService.project.uid;
      //   break;
      case 'admin':
        this.isAdmin = true;
        break;
    }
    this.eventsService.switchEvent(name);
  }

  public updateModel(values) {
    // if (values.type !== 'accessory' || values.type !== 'text') {
    //   this._changeMateFolder = true;
    //   this.setLastSelected(values.type, this.projectService.project[values.type].uid);
    // }
    if (values.type === 'plate') {
      values.size = this.checkPlateSize(values.size, values.corner);
      this.projectService.updateModel(values);
      return;
    }
    this.projectService.updateModel(values);
  }

  public update() {
    this.projectService.update();
  }

  // todo: remove skipAccessory flag after accessory rework uid conflicts with uuid, we need a client unique id prop, not uid
  public loadNewModel(values, skipAccessory: boolean = false, adminCall: boolean = false) {
    if (values.type === 'accessory' && !skipAccessory) { // kekw
      values.uid = ModelUtils.uuidv4(); // todo maybe null here? or a firestore uid?
      values.position = {x: 0, y: 0, z: 0};
    }
    this.setLastSelected(values.type, values.uid);
    this.projectService.loadNewModel(values, adminCall);
  }

  // admin v2
  removeCategoryNode(categoryName: OldCategoryName) {
    const nodeName: ThreeJsNode = ThreeJsNode[categoryName];
    this.removeNode(nodeName);
  }

  removeNode(nodeName: ThreeJsNode) {
    this.setLastSelected(nodeName, null);
    this.projectService.removeNode(nodeName);
  }

  public setDimensionsVisibilityPerObject(object) {
    this.projectService.setDimensionsVisibility(object);
  }

  public setLastSelected(type, value) {
    // this.lastSelected['material'] = null;
    switch (type) {
      case 'accessory':
      case 'environment':
      case 'border':
      case 'stone':
      case 'plate':
      case 'material':
        if (this.eventsService.subscription.isStopped && type == "material") {
          this.eventsService.addMaterialPolishHoverListener();
        }
      case 'polish':
        if (this.eventsService.subscription.isStopped && type == "polish") {
          this.eventsService.addMaterialPolishHoverListener();
        }
      case 'text':
        // this.lastSelected[type] = value;
        if (this._changeMateFolder) {
          this.projectService.setMaterialFolder(value);
        }
        break;
      case 'folder':
        // this.lastSelected[type] = value;
        if (this._changeMateFolder) {
          this.projectService.setMaterialFolder(value);
        }
        break;

      case 'project':
        // this.lastSelected[type] = value;
        break;
    }
  }

  public getScreenShot(size, dimensions) {
    return new Promise((resolve) => {
      this.projectService.makeScreenShot(size, dimensions, false).then((thumb) => {
        resolve(thumb);
      });
    });
  }

  public saveProject(): void {
    const path = `${this.uploadFileService.getBasePath()}/${this.projectService.project.uid}`;
    this.dbObj.getCurrentValue(path).subscribe(() => {
      this.getScreenShot(256, false).then((response) => {
        this.projectService.saveScene(response, this.projectService.project.uid);
        this.projectService.needsSave = false;
      });
    });
  }

  private checkPlateSize(size, corner: Corner) {
    const plate = this.plateService.getPlate(corner);
    if (!plate) {
      return size;
    }

    const transforms = {
      ground: this.utilsService.getGroundSize(),
      border: this.utilsService.getBorderSize()
    };

    const tempValues = this.utilsService.currentBorderValues;
    let z = 0;
    let max = 0;
    if (tempValues) {
      switch (tempValues.category) {
        case 'editable-1':
        case 'editable-3':
        case 'editable-4':
        case 'editable-6':
          z = transforms.border.borderTickness * (plate.innerPlacement == 0 ? 1 : 0);
          break;
        case 'editable-2':
          z = transforms.border.monumentHolderLength;
          break;
        case 'editable-5':
          z = 0;
          break;
      }
    }

    switch (plate.corner) {
      case 'top':
      case 'bottom':
        max = transforms.ground.length - z - transforms.border.borderTickness * (plate.innerPlacement == 0 ? 1 : 0);
        // if (Object.keys(plate.corners).length > 1) {
        //   max = max / 2;
        // }

        size = size < 10 ? 10 : size;
        size = size > max ? max : size;
        break;
      case 'top-left':
      case 'bottom-left':
      case 'top-right':
      case 'bottom-right':
        const lengthDim = transforms.ground.length - z - transforms.border.borderTickness * (plate.innerPlacement == 0 ? 1 : 0);
        const widthDim = transforms.ground.width - 2 * transforms.border.borderTickness * (plate.innerPlacement == 0 ? 1 : 0);

        max = widthDim < lengthDim ? widthDim : lengthDim;
        // if (Object.keys(plate.corners).length > 1) {
        //   if (Object.keys(plate.corners).length > 2) {
        //     max = max / 2;
        //   } else {
        //     const filter_bottom = Object.keys(plate.corners).filter(key => key.indexOf('bottom') !== -1).length;
        //     const filter_left = Object.keys(plate.corners).filter(key => key.indexOf('left') !== -1).length;
        //
        //     if ([0, 2].includes(filter_bottom)) {
        //       max = widthDim / 2 < lengthDim ? widthDim / 2 : lengthDim;
        //     }
        //     if ([0, 2].includes(filter_left)) {
        //       max = widthDim < lengthDim / 2 ? widthDim : lengthDim / 2;
        //     }
        //   }
        // }

        size = size > max ? max : size;
        break;
      case 'full':
      case 'left':
      case 'right':
        break;
    }

    return size;
  }

  private compare(a, b) {
    const bandA = a.name.toUpperCase();
    const bandB = b.name.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  private _maxValues = 4 * 3;
  private uiTextNeedsRefreshing = false;
  private readonly uiFiltered = {
    border: [],
    stone: [],
    plate: [],
    material: [],
    accessories: []
  };
  private _cachedThumbs = {};
  private _changeMateFolder = false;
}
