import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MaterialInputControlValueAccessor } from '../material-input.cva';
import { FormControl, NgControl } from '@angular/forms';

@Component({
  selector: 'app-material-password-input',
  template: `
    <mat-form-field>
      <mat-label *ngIf="label">{{label}}</mat-label>
      <input matInput type="password" placeholder="{{placeholder}}" [formControl]="control" [errorStateMatcher]="errorStateMatcher"/>
      <mat-error>
        <fmx-errors [errors]="externalErrors$ | async" [controlLabel]="label || placeholder"></fmx-errors>
      </mat-error>
    </mat-form-field>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaterialPasswordInputComponent extends MaterialInputControlValueAccessor {
  readonly control: FormControl = new FormControl();

  constructor(@Inject(NgControl) protected readonly controlDirective: NgControl) { super(controlDirective); }
}
