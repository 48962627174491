import { AbstractControl, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormUtils } from '../form.utils';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  constructor(private readonly externalControl: AbstractControl) {}

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return FormUtils.showControlErrors(this.externalControl, form);
  }
}
