import { Inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { map, switchMap, take } from 'rxjs/operators';
import { ScreenshotDialogComponent } from './screenshot-dialog.component';
import { ProjectService } from '../../../services/project.service';

@Injectable({
  providedIn: 'root'
})
export class ScreenshotService {
  constructor(
    @Inject(MatDialog) private readonly dialog: MatDialog,
    @Inject(ProjectService) private readonly projectService: ProjectService,
  ) { }

  takeScreenshot(checked, size, isThumbnail): Observable<Blob | null> {
    return from(this.projectService.makeScreenShot(size, false, checked))
      .pipe(switchMap(blob => this.openDialog(blob, isThumbnail)));
  }

  private openDialog(blob: Blob, isThumbnail: boolean): Observable<Blob | null> {
    const dialogRef = this.dialog.open(ScreenshotDialogComponent, { data: { blob, isThumbnail } });
    return dialogRef.afterClosed().pipe(take(1), map(value => value || null));
  }
}
