import { Inject, Injectable } from '@angular/core';
import { FirestoreService } from '../../../core/firebase/firestore/firestore.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { TextMaterial } from '../../../../models/firestore/text/text-material.model';

@Injectable({
  providedIn: 'root'
})
export class TextMaterialsFirestoreService extends FirestoreService<TextMaterial> {

  constructor(@Inject(AngularFirestore) protected readonly firestore: AngularFirestore) { super(firestore); }

  protected get path(): string {
    return 'text/material/items';
  }
}
