import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { PlateService } from '../three-view/three-services/plate.service';
import { GroundService } from '../three-view/three-services/ground.service';
import { StoneService } from '../three-view/three-services/stone.service';
import { BorderGeneratedService } from '../three-view/three-services/border-generated.service';
import { AccessoriesService } from '../three-view/three-services/accessories.service';
import { UtilsService } from '../three-view/three-utils/utils.service';
import { MaterialService } from '../three-view/three-services/material.service';
import { FileUpload } from '../../models/fileupload';
import { UploadFileService } from './upload-file-service.service';
import { TextsService } from '../three-view/three-services/texts.service';
import { Matrix3 } from 'three';
import * as dateFormat from 'dateformat';
import { MeasurementsService } from '../three-view/three-services/measurements.service';
import { ThreeViewService } from '../three-view/three-view.service';
import { Project } from '../../models/project/project.class';
import { Accessory } from '../../models/firestore/categories/accessory/accessory.model';
import { Accessories } from '../../models/project/accessories.model';
import { Text } from '../../models/project/text.model';
import { ModelUtils } from '../../models/project/model.utils';
import { Texts } from '../../models/project/texts.model';
import { Ground } from '../../models/project/ground.model';
import { Plate } from '../../models/firestore/categories/plate/plate.model';
import { Stone } from '../../models/firestore/categories/stone/stone.model';
import { Environment } from '../../models/firestore/environments/environment.model';
import { Border } from '../../models/firestore/categories/border/border.model';
import { filter, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { MaterialsFirestoreService } from '../modules/material/services/materials-firestore.service';
import { PolishesFirestoreService } from '../modules/material/services/polishes-firestore.service';
import { Polish } from '../../models/firestore/polishes/polish.model';
import { Material } from '../../models/firestore/materials/material.model';
import { EnvironmentFirestoreService } from '../modules/project/services/environment-firestore.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import {cloneDeep} from 'lodash-es';
import {Corner} from '../../models/firestore/categories/plate/corner.enum';
import {AbstractObject3DService} from '../three-view/three-services/abstract-object3d.service';


@Injectable({ providedIn: 'root' })
export class ProjectService implements OnDestroy {
  project: Project = new Project();
  isImported: boolean = false; // set to true when a project is opened by url or uid

  public threeSolver: BehaviorSubject<Project> = new BehaviorSubject(this.project);
  readonly project$: Observable<Project> = this.threeSolver.asObservable().pipe(shareReplay(1));
  public lastSelectedAccessory;
  public lastSelectedText;
  public activeObjects = {
    border: false,
    stone: false,
    plate: false,
    accesories: false,
    texts: false
  };
  public isAdmin;
  public allowMultipleAccessories = true;
  public isLoaded = false;
  public isLoadedSubject = new BehaviorSubject<boolean>(this.isLoaded);
  public readonly plateLoaded: Subject<void> = new Subject<void>();
  public readonly plateDeleted: Subject<void> = new Subject<void>();
  public readonly textsChanged: BehaviorSubject<Text[]> = new BehaviorSubject<Text[]>(this.project.texts.list);
  public currentCameraOffset;
  /** @deprecated not queried anywhere, just initialized, i think it can be deleted **/
  needsSave = false;
  public shouldReload = false;
  public currentProjectName = '';
  public dimensionsVisibility = {
    border: false,
    stone: false,
    plate: false
  };
  public sceneIsLoaded = false;
  private hasSecondGround = false;
  constructor(
    private plateService: PlateService,
    private groundService: GroundService,
    private stoneService: StoneService,
    private accessoriesService: AccessoriesService,
    private borderGeneratedService: BorderGeneratedService,
    private textsService: TextsService,
    @Inject(UtilsService) private readonly utilsService: UtilsService,
    @Inject(MaterialService) private readonly materialService: MaterialService,
    @Inject(MaterialsFirestoreService) private readonly materialsFirestoreService: MaterialsFirestoreService,
    @Inject(PolishesFirestoreService) private readonly polishesFirestoreService: PolishesFirestoreService,
    @Inject(EnvironmentFirestoreService) private readonly environmentFirestoreService: EnvironmentFirestoreService,
    private threeViewService: ThreeViewService,
    private uploadFileService: UploadFileService,
    private measurementsService: MeasurementsService,
    @Inject(AngularFireAnalytics) private readonly analytics: AngularFireAnalytics,
  ) {}

  public updateDummy(values?) {
    this.threeViewService.addDummyMonumentHolder(values);
  }

  /**
   * SHOULD BE CALLED ONLY ONCE (for now)
   */
  bootApp(threeViewContainer: HTMLElement) {
    this.threeViewService.init(threeViewContainer);
    this.utilsService.initFirestoreCaches$().subscribe(() => {
      this.utilsService.initFirestoreCaches();
      this.newDefaultProject();
      this.observeProjectChanges();
    });
  }

  public offsetCamera(value, callback?) {
    if (callback) {
      this.threeViewService.offsetCamera(value, callback);
      this.currentCameraOffset = value;
    } else {
      this.threeViewService.offsetCamera(value);
      this.currentCameraOffset = value;
    }
  }

  public changeCameraPos(pos, callback?) {
    if (callback) {
      this.threeViewService.changeCameraPos(pos, callback);
    } else {
      this.threeViewService.changeCameraPos(pos);
    }
  }

  public placeMaterial(ev) {
    const intersect = this.utilsService.getMouseIntersections(['border', 'stone', 'plate', 'accessories', 'texts'], ev);
    if (intersect && intersect.object.userData.type === 'accessory') {
      if (intersect.object.name.includes('accessoryHolder') || intersect.object.name.includes('Kreuz')) {
        if (this._lastMaterial) {
          this.analytics.logEvent('material_placed', {name: this._lastMaterial, object: intersect.object.userData.type, part: intersect.object.name})
          this.accessoriesService.changeAppearance({
            appearance: {material: this._lastMaterial},
            uid: intersect.object.userData.uid,
            meshName: intersect.object.name
          });
        }
        if (this._lastPolish) {
          this.analytics.logEvent('polish_placed', {name: this._lastPolish, object: intersect.object.userData.type, part: intersect.object.name})
          this.accessoriesService.changeAppearance({
            appearance: {polish: this._lastPolish},
            uid: intersect.object.userData.uid,
            meshName: intersect.object.name
          });
        }
      }
    } else if (intersect) {
      const cachedFirestorePolishes: Array<Polish> = this.polishesFirestoreService.getCached();
      for (let index = 0; index < cachedFirestorePolishes.length; index++) {
        if (cachedFirestorePolishes[index].name === intersect.object.userData.polish[intersect.object.name]) {
          if (cachedFirestorePolishes[index].type === 'special') {
            return;
          }
        }
      }
      const type = intersect.object.userData.type;
      if (this._lastMaterial) {
        this.analytics.logEvent('material_placed', {name: this._lastMaterial, object: type, part: intersect.object.name});
        if (type === 'plate') {
          this.plateService.changeAppearance({appearance: {material: this._lastMaterial}, corner: intersect.object.userData.corner});
        } else if (type === 'border') {
          this.borderGeneratedService.changeAppearance({appearance: {material: this._lastMaterial}, meshName: intersect.object.name});
        } else if (type === 'stone') {
          this.stoneService.changeAppearance({appearance: {material: this._lastMaterial}, meshName: intersect.object.name});
        }
      }
      if (this._lastPolish) {
        this.analytics.logEvent('polish_placed', {name: this._lastPolish, object: type, part: intersect.object.name});
        if (type === 'plate') {
          this.plateService.changeAppearance({appearance: {polish: this._lastPolish}, corner: intersect.object.userData.corner});
        } else if (type === 'border') {
          this.borderGeneratedService.changeAppearance({appearance: {polish: this._lastPolish}, meshName: intersect.object.name});
        } else if (type === 'stone') {
          this.stoneService.changeAppearance({appearance: {polish: this._lastPolish}, meshName: intersect.object.name});
        }
      }
    }
  }

  public selectStone(ev) {
    this.utilsService.disableControls();
    this.threeViewService.needToRender();
  }

  public updateStoneFromMonumentHolder(overwite?) {
    const threshold = 0.001;
    if (!this.project.stone.position) {
      return;
    }
    if (!this.project.stone.snapToMonumentHolder) {
      return;
    }

    if (this.project.border && this.project.border.monumentHolderHeight) {
      const center = this.utilsService.getMonumentHolderCenter();
      if (center) {
        this.project.stone.position.x = center.x + threshold;
        this.project.stone.position.y = this.project.border.monumentHolderHeight + threshold;
        this.project.stone.position.z = center.z - this.project.border.monumentHolderLength / 2 + threshold;
      } else {
        this.project.stone.position.x = threshold;
        this.project.stone.position.y = this.project.border.monumentHolderHeight + threshold;
        this.project.stone.position.z = threshold;
      }
    } else {
      this.project.stone.position.x = threshold;
      this.project.stone.position.y = threshold;
      this.project.stone.position.z = threshold;
    }

    this.stoneService.requestUpdate(this.project.stone);
  }

  public rotateStone() {
    this.rotateIncrementStone();
    this.threeViewService.needToRender();
  }

  public centerStone() {
    if(!this.project.stone?.name)
    this.project.stone.snapToMonumentHolder = !this.project.stone.snapToMonumentHolder;
    this.updateStoneFromMonumentHolder(true);
    this.threeViewService.needToRender();
  }

  public showMeasures(objectType: string) {
    this.measurementsService.showMeasurements(this.dimensionsVisibility);
    switch(objectType) {
      case 'border': {
        this.borderGeneratedService.updateMeasurements();
        break;
      }
      case 'plate': {
        this.plateService.updateMeasurements();
        break;
      }
      case 'stone': {
        this.stoneService.updateMeasurements();
        break;
      }
      default: {
        return;
      }
    }
    this.threeViewService.needToRender(100);
  }

  public moveStone(ev) {
    const intersect = this.utilsService.getMouseIntersections(['environment', 'ground', 'border', 'plate'], ev);
    if (intersect) {
      this.project.stone.position.x = intersect.point.x;
      this.project.stone.position.y = intersect.point.y;
      this.project.stone.position.z = intersect.point.z;
      this.stoneService.requestUpdate(this.project.stone);
      this.project.stone.snapToMonumentHolder = false;
      this.threeViewService.needToRender();
    }
  }

  public deselectStone(ev) {
    this.utilsService.canMoveStone = false;
    this.utilsService.enableControls();
    this.threeViewService.needToRender();
  }

  public selectText(ev) {
    const node = this.utilsService.getNodeByName('texts');
    if (this.lastSelectedText) {
      this.utilsService.enableControls();
      this.materialService.overwriteSelectedMaterial(node, this.lastSelectedText, false);
      this.lastSelectedText.selected = false;
      this.activeObjects.texts = this.lastSelectedText = undefined;
    }

    const intersect = this.utilsService.getMouseIntersections(['texts'], ev);
    if (intersect) {
      this.utilsService.disableControls();
      this.lastSelectedText = this.project.texts.select(intersect.object.userData.uid);
      this.lastSelectedText.selected = true;
      this.activeObjects.texts = true;
      this.materialService.overwriteSelectedMaterial(node, this.lastSelectedText, true);
    }
    this.textsChanged.next(this.project.texts.list);
    this.threeViewService.needToRender();
  }

  public selectTextFromList(text: Text) {
    const node = this.utilsService.getNodeByName('texts');
    this.utilsService.disableControls();
    if (this.lastSelectedText) {
      this.lastSelectedText.selected = false;
    }
    this.lastSelectedText = this.project.texts.select(text.uid);
    this.lastSelectedText.selected = true;
    this.activeObjects.texts = true;
    this.materialService.overwriteSelectedMaterial(node, this.lastSelectedText, true);
    this.textsChanged.next(this.project.texts.list);
    this.threeViewService.needToRender();
  }


  public moveText(ev) {

    if (this.lastSelectedText) {
      this.utilsService.disableControls();
      const intersect = this.utilsService.getMouseIntersections(['border', 'plate', 'stone'], ev);
      if (intersect) {
        const normalMatrix = new Matrix3().getNormalMatrix(intersect.object.matrixWorld);
        const worldNormal = intersect.face.normal.clone().applyMatrix3(normalMatrix);
        this.lastSelectedText.position = intersect.point;
        this.lastSelectedText.direction = worldNormal;
        this.textsService.update(this.lastSelectedText);
        this.threeViewService.needToRender();
        // this.threeSolver.next(this.project);
      }
    }
  }

  public deselectText(ev) {
    this.utilsService.enableControls();
    this.threeViewService.needToRender();
  }

  public removeText() {
    if (this.lastSelectedText) {
      const index = this.utilsService.getIndexFromUid(this.project.texts.list, this.lastSelectedText);
      this.textsService.markedForDeletion.push(this.lastSelectedText.uid); // avoids premature deletion
      this.project.texts.list.splice(index, 1);
      this.lastSelectedText = {uid: this.lastSelectedText.uid}; // mark for deletion in text service execute
      this.activeObjects.texts = false;
      this.textsChanged.next(this.project.texts.list);
      this.updateProjectTexts();
      this.threeViewService.needToRender();
    }
  }

  public newProject() {
    this.project = new Project();
    this.threeSolver.next(this.project);
  }

  public toggleSecondGround(){
    if(!this.hasSecondGround){
      this.groundService.addSecondGround();
    }
    else{
      this.groundService.removeSecondGround();
    }
    this.hasSecondGround = !this.hasSecondGround;
  }

  public update() {
    this.threeSolver.next(this.project);
  }

  public updateModel(values) {
    switch (values.type) {
      case 'ground':
        if (this.project.ground) {
          if (values.width && values.length) {
            this.project.ground.set(values);
          } else {
            this.project.ground.setDefault();
          }
        }
        break;
      case 'plate':  // RAZVAN_TODO: fix this mess
        if (values.delete) {
          this.plateService.removePlateFromCorner(values.corner);
          break;
        }
        this.updateProjectPlate(values);
        break;
      case 'environment':
        if (values.name) {
          this.project.environment.set(values);
        }
        break;
      case 'stone':
        this.updateProjectStone(values);
        break;
      case 'border': //this bit is only temporary (hopefully)
        if(this.project.border) {
          if (values.name) {
            this.project.border.set({...values});
          } else {
            this.project.border.setDim(values);
          }
          this.updateProjectBorder(this.project.border);
        }
        break;
      case 'text':
        if (!this.project.texts) {
          this.project.texts = new Texts();
        }
        const text = new Text();
        if (values.name) {
          text.set(this.utilsService.createDeepClone(values));
        }
        this.project.texts.add(text);
        this.lastSelectedText = text;
        break;
      case 'accessory':
        const accessory = new Accessory();
        if (values.name) {
          accessory.set(this.utilsService.createDeepClone(values));
        }
        this.accessoriesService.requestUpdate(accessory);
        break;
    }
    this.threeSolver.next(this.project);
  }

  public detectIfMaterialIsAppliable(evt){
    let intersection = this.utilsService.getMouseIntersections(["border", "plate", "stone", "accessories"], evt);
    try{
      if(intersection.object.userData){
        return true;
      }
      else{
        return false;
      }
    }catch(e){

    }
  }

  setMaterialFolder(folder) {
    const cachedFirestoreMaterials: Array<Material> = this.materialsFirestoreService.getCached();
    for (let i = 0; i < cachedFirestoreMaterials.length; i++) {
      const mat = cachedFirestoreMaterials[i];
      if (mat.uid === folder) {
        this._materialFolder = mat.name;
      }
    }
  }

  public loadNewModel(values, adminCall?: boolean) {
    if (values.open === undefined && values.type !== 'file') {
      this.needsSave = true;
    }
    this._lastMaterial = null;
    switch (values.type) {
      case 'file':
        const modifiedValues = this.utilsService.createDeepClone(values);
        this._openProject(modifiedValues);
        break;
      case 'ground':
        this.project.ground = new Ground();
        if (values.width && values.length) {
          this.project.ground.set(values);
          // this.utilsService.canEditBorder = false;
        } else {
          this.project.ground.setDefault();
        }

        this.threeSolver.next(this.project);
        break;
      case 'plate':
        if(values.corners && values.corners !== {}) {
          this.plateService.loadOld(values);
        } else if(values.remove) {
          this.plateService.removePlate(values);
        } else {
          this.plateService.addPlate(values, adminCall);
        }
        this.plateLoaded.next();
        break;
      case 'stone':
        this.project.stone = new Stone();
        if (values.remove) {
          this.stoneService.purge();
          break;
        }
        if (values.open) {
          this.project.stone.set(values);
          this.utilsService.currentStoneValues = values;
        } else if (values.name) {
          this.project.stone.set(values);
          this.utilsService.currentStoneValues = values;
          if (this._lastMaterial) {
            this.project.stone.material = this._lastMaterial;
          }
        }
        this.updateStoneFromMonumentHolder();
        this.stoneService.addStone(this.project.stone);
        break;
      case 'environment':
        this.project.environment = new Environment();
        if (values.name) {
          this.project.environment.set(values);
        }
        this.threeSolver.next(this.project);
        break;
      case 'border':
        this.utilsService.canEditBorder = false;

        if (values.remove) {
          this.borderGeneratedService.purge();
          break;
        }

        if (values.category != null && values.category !== 'fixed') {
          this.utilsService.canEditBorder = true;
        }

        if (values.name) {
          this.project.border = new Border();
          this.project.border.set(values);
          this.utilsService.currentBorderValues = values;
        }

        if (this._lastMaterial && values.material) {
          this.project.border.material = this._lastMaterial;
        }
        if (values.acceptStone === false) {
          this.removeNode('stone');
        }
        if (values.acceptPlate === false) {
          this.removeNode('plate');
        }
        this.borderGeneratedService.addBorder(this.project.border);
        break;
      case 'accessories':
        if (values.remove) {
          this.accessoriesService.removeSelectedAccessory();
        }
        break;
      case 'accessory':
        this.accessoriesService.addAccessory(values);
        break;
      case 'texts':
        if (values.remove) {
          this.removeText();
        }
        this.threeSolver.next(this.project);
        break;
      case 'text':
        if (!this.project.texts) {
          this.project.texts = new Texts();
        }
        const text = new Text();
        if (this.lastSelectedText) {
          const node = this.utilsService.getNodeByName('texts');
          this.lastSelectedText.selected = false;
          this.materialService.overwriteSelectedMaterial(node, this.lastSelectedText, false);
          this.lastSelectedText = undefined;
        }
        if (values.new) {
          text.setDefault();
        }

        if (values.open) {
          if (!values.uid) {
            values.uid = ModelUtils.uuidv4();
          }
          text.set(values);
        }
        this.project.texts.add(text);
        this.textsChanged.next(this.project.texts.list);
        this.lastSelectedText = text;
        this.threeSolver.next(this.project);
        break;
      case 'sub-material':
      case 'material':
        this._lastPolish = null;
        this._lastMaterial = this._materialFolder;
        // this.threeSolver.next(this.project);
        break;
      case 'polish':
        this._lastMaterial = null;
        this._lastPolish = values.name;
        break;
      case 'project':
        this._openProject(values);
        this.threeSolver.next(this.project);
        break;
    }
  }

  public updatePolishSphere(polish: Polish) {
    if (!this._polishingSphere) {
      this._polishingSphere = this.threeViewService.updatePolishSphere();
      this.materialService.applyPolish(this._polishingSphere, polish).then(_ => {
        this.threeViewService.needToRender(100);
      });
    } else {
      this.materialService.updatePolish(this._polishingSphere, polish);
      this.threeViewService.needToRender(100);
    }
  }

  public loadForAdmin(values) {

    if (this.shouldReload) {
      this.shouldReload = false;
      this.project = new Project();
      this.project.accesories = [];
      this.project.plates = [];
      this.project.ground = new Ground();
      this.project.ground.setDefault();
      this.project.border = new Border();
      this.project.stone = new Stone();
      this.project.environment = new Environment();
      this.project.texts = new Texts();
      const defaultEnv = this.utilsService.appData;
      this.project.environment.set(defaultEnv.Environment[0]);
      this.accessoriesService.initNode();
      this.lastSelectedText = undefined;
      this.textsService.execute(undefined);
      this.project[values.type].set(values);
      this.threeSolver.next(this.project);
    }

  }

  public removeNode(nodeName) {
    this.loadNewModel({ type: nodeName, remove: true });
    this.activeObjects[nodeName] = false;
  }

  defaultAll() {
    this.newProject$.next();
    this.project = new Project();
    this.utilsService.canEditBorder = false;
    const cachedEnvironment = this.environmentFirestoreService.getCached()[0];
    cachedEnvironment && this.project.environment.set(cachedEnvironment); // temporary fix for async loading firestore elements
    const tempServices: AbstractObject3DService<any>[] = [this.borderGeneratedService, this.plateService, this.stoneService, this.accessoriesService];
    tempServices.forEach(child => {
      child.initNode();
      child.purge();
      child.needsRender.pipe(takeUntil(this.newProject$)).subscribe(() => {
        this._updateProjectComponent(child.parentName);
        this.utilsService.setCurrentModel(this.utilsService.getNodeByName(child.parentName));
        this.threeViewService.needToRender();
      });
    })
    this.textsService.execute(undefined);
    this.lastSelectedText = undefined;
    this.removePolishSphere();
    this.updateDummy();
    try{
      this.groundService.removeSecondGround();
    }catch{

    }
    this.threeViewService.removeAllMeasurements();
    this.threeViewService.needToRender(20);
  }

  public setDimensionsVisibility(object) {
    this.dimensionsVisibility[object] = !this.dimensionsVisibility[object];
  }

  public getDimensionsVisibility() {
    return this.dimensionsVisibility;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public updateChain(type) {
    setTimeout(() => {
      switch (type) {
        case 'border':
          // this.updateProjectBorder(this.project.border);
          break;
        case 'stone':
          if(this.project.stone?.name) {
            this.centerStone();
            this.updateStoneFromMonumentHolder();
          }
          // this.updateProjectStone(this.project.stone);
          break;
        case 'plate':
          if(this.project.plates?.length) {
            this.updateProjectPlate(undefined, true);
          }
          // this.plateService.update(this.project.plate);
          break;
        case 'accessories':
          break;
        case 'texts':
          this.textsService.update();
          break;
      }
    }, 150);
  }

  saveScene(thumbBlob, name): void {
    const now = new Date();
    this.project.lastModified = dateFormat(now, 'd-mm-yyyy, h:MM:ss TT');

    const blob = JSON.stringify(this.project);
    const f = new File([blob], name + '.JSON');
    const currentProject = new FileUpload(f);
    currentProject.name = this.project.uid; // todo: asta sigur e bine asa?
    currentProject.displayName = this.project.name;
    currentProject.uuid = this.project.uid;
    currentProject.lastModified = this.project.lastModified;
    currentProject.lastOrdered = this.project.lastOrdered || '';
    currentProject.thumbnail = 'thumbSrc';
    currentProject.type = 'file';
    const renderedFile = new File([thumbBlob], this.project.uid + '.png');
    const currentThumbFile = new FileUpload(renderedFile);
    this.uploadFileService.saveProjectFilesToStorage(currentProject, currentThumbFile);
    this.threeViewService.needToRender(100);
  }

  // todo: extract to Service
  public resize() {
    this.threeViewService.resizeCamera();
  }

  public makeScreenShot(size, dimensions, checked) {
    this.measurementsService.showMeasurements({
      border: dimensions,
      stone: dimensions,
      plate: dimensions
    });
    this.borderGeneratedService.updateMeasurements();
    this.stoneService.updateMeasurements();
    this.plateService.updateMeasurements();
    // this.plateService.update(this.project.plate);

    this.threeViewService.needToRender(100);
    return this.threeViewService.makeProjectThumbnail(size, checked);
  }

  public textChanged() {
    this.updateProjectTexts();
  }

  private removePolishSphere() {
    if (this._polishingSphere) {
      this.threeViewService.removePolishSphere();
      this.threeViewService.needToRender(100);
      this._polishingSphere = null;
    }
  }

  private newDefaultProject() {
    this.groundService.resetEnvironment();
    this.defaultAll();
    this.threeSolver.next(this.project);
  }

  private updateProjectGround(ground: Ground) {
    if (!ground.width) {
      ground.width = 10;
    }
    if (!ground.length) {
      ground.length = 10;
    }
    this.groundService.execute(ground).then(() => {
      // todo: is timeout really needed?
      setTimeout(() => {
        // this.updateChain('border');  // RAZVAN_TODO: check this ( I commented )
        // this.updateChain('plate');
        // this.updateChain('accessories');
      }, 100);
    });
  }

  private updateProjectBorder(border: Partial<Border>) {
    this.borderGeneratedService.requestUpdate(border).then(() => {
      setTimeout(() => {
        // this.updateStoneFromMonumentHolder(true);
        this.updateChain('plate');
        this.updateChain('stone');
        this.activeObjects.border = true;
        this.utilsService.setCurrentModel(this.utilsService.getNodeByName('border'));
        this.isLoadedSubject.next(true);
        this.borderGeneratedService.updateMeasurements();
      }, 100);
    });
  }

  private updateProjectPlate(plate: Plate, simpleUpdate: boolean = false) {
    this.plateService.requestUpdate(plate, simpleUpdate).then(() => {
      setTimeout(() => {
        this.activeObjects.plate = true;
        this.project.plates = this.plateService.getPlatesCopy();
        this.utilsService.setCurrentModel(this.utilsService.getNodeByName('plate'));
        this.showMeasures('plate');
        this.isLoadedSubject.next(true);
      }, 100);
    });
  }

  private updateProjectStone(stone: Stone) {
    this.stoneService.requestUpdate(stone).then(() => {
      this.updateStoneFromMonumentHolder();
      this.activeObjects.stone = true;
      this.utilsService.setCurrentModel(this.utilsService.getNodeByName('stone'));
      this.isLoadedSubject.next(true);
      this.stoneService.updateMeasurements();
    });
  }

  private updateProjectEnvironment(environment: Environment) {
    this.groundService.executeEnv(environment).then(() => {
      setTimeout(() => {
        this.sceneIsLoaded = true;
        this.threeViewService.needToRender();
      }, 100);
    });
  }

  private updateProjectTexts() {
    if (this.lastSelectedText) {
      this.textsService.execute(this.lastSelectedText).then(() => {
        this.isLoadedSubject.next(true);
        this.textsChanged.next(this.project.texts.list);
        this.threeViewService.needToRender(100);
      });
    }
  }

  private updateProjectData(project: Project) {
    project.ground && this.updateProjectGround(project.ground);
    project.border && this.updateProjectBorder(project.border);
    this.updateProjectPlate(undefined, true);
    project.stone && this.updateProjectStone(project.stone);
    project.environment && this.updateProjectEnvironment(project.environment);
    project.texts && this.updateProjectTexts();
  }

  private _updateProjectComponent(componentType: string): void {
    switch(componentType) {
      case 'border':
        this.project.border = this.borderGeneratedService.getObjectCopy();
        this.updateProjectPlate(undefined, true);
        break;
      case 'stone':
        this.project.stone = this.stoneService.getObjectCopy();
        break;
      case 'plate':
        this.project.plates = this.plateService.getPlatesCopy();
        break;
      case 'accessories':
        this.project.accesories = this.accessoriesService.getAccessoriesCopy();
        break;
    }
  }

  private observeProjectChanges() {
    if (!this.projectSubscription$) {
      this.projectSubscription$ = this.threeSolver
        .pipe(
          takeUntil(this.destroyed$),
          filter((project: Project) => !!project),
          tap((project: Project) => this.updateProjectData(project))
        );
      this.projectSubscription$.subscribe();
    }
  }

  private rotateIncrementStone() {
    if (!this.project.stone.position) {
      return;
    }
    if (!this.project.stone.rotation) {
      this.project.stone.rotation = 0;
    }
    this.project.stone.rotation += 15;
    this.stoneService.requestUpdate(this.project.stone);
  }

  private _openProject(values) {
    this.currentProjectName = values.name;
    this._lastMaterial = null;
    this._lastPolish = null;
    this.utilsService.canEditBorder = true;
    this.utilsService.currentBorderValues = undefined;
    this.utilsService.getJSONData(values.url).subscribe((vals) => {
      this.defaultAll();
      for (const prop in vals) {
        if (prop === 'accesories') {
          const accList = vals[prop].list ?? vals[prop];
          for (let i = 0; i < accList.length; i++) {
            accList[i].open = true;
            this.loadNewModel(accList[i]);
          }
        } else if (prop === 'texts') {
          if (vals[prop].list) {
            for (let i = 0; i < vals[prop].list.length; i++) {
              vals[prop].list[i].open = true;
              this.loadNewModel(vals[prop].list[i]);
            }
            this.lastSelectedText = undefined;
          } else {
            if (Array.isArray(vals[prop])) {
              for (let i = 0; i < vals[prop].length; i++) {
                vals[prop][i].open = true;
                this.loadNewModel(vals[prop][i]);
              }
              this.lastSelectedText = undefined;
            }
          }
        } else if (typeof vals[prop] === 'object' && !Array.isArray(vals[prop])) {
          vals[prop].open = true;
          this.loadNewModel(vals[prop]);
        } else if (Array.isArray(vals[prop])) {
          for (let i = 0; i < vals[prop].length; i++) {
            vals[prop][i].open = true;
            this.loadNewModel(vals[prop][i]);
          }
        }
      }
      if (values.name === 'Default1' || values.name === 'Default2' || values.name === 'Default3') {
        this.project.uid = null;
        this.project.message = 'Geben Sie die Nachricht hier ein';
      } else {
        this.project.name = vals['name'];

        // `|| vals['uuid']` because the data is loaded from a file stored in the storage, probably only needed until all projects are saved once after the migration
        this.project.uid = vals['uid'] || vals['uuid'];

        this.project.message = vals['message'];
      }
      this.project.lastModified = vals['lastModified'];
      this.project.lastOrdered = vals['lastOrdered'];
      if (this.isImported) {
        this.project.uid = null;
        this.isImported = false;
      }
      this.threeSolver.next(this.project);
    });
  }

  translateCorner(corner: string): string {
    let translatedCorner = "";
    switch (corner) {
      case "top":
        translatedCorner = "Unterseite";
        break;
      case "bottom":
        translatedCorner = "Oben";
        break;
      case "top-left":
        translatedCorner = "Oben links";
        break;
      case "top-right":
        translatedCorner = "Oben rechts";
        break;
      case "bottom-left":
        translatedCorner = "Unten links";
        break;
      case "bottom-right":
        translatedCorner = "Unten rechts";
        break;
      case "full":
        translatedCorner = "Fest";
        break;
      // case "full":
      //   translatedCorner = "Gestreckt";
      //   break;
    }
    return translatedCorner
  }

  private _polishingSphere = null;
  private readonly destroyed$: Subject<void> = new Subject<void>();
  private readonly newProject$: Subject<void> = new Subject<void>();
  private _lastIntersectPoint;
  private _lastMaterial;
  private _lastPolish;
  private _materialFolder;
  private projectSubscription$: Observable<Project> = null;

}
