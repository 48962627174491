import { Inject, Injectable } from '@angular/core';
import { FirestoreService } from '../../../core/firebase/firestore/firestore.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Stone } from '../../../../models/firestore/categories/stone/stone.model';

@Injectable({
  providedIn: 'root'
})
export class StoneItemsFirestoreService extends FirestoreService<Stone> {

  constructor(@Inject(AngularFirestore) protected readonly firestore: AngularFirestore) { super(firestore); }

  protected get path(): string {
    return 'categories/stone/items';
  }
}
