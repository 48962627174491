import { Inject, Injectable } from '@angular/core';
import { UtilsService } from './three-utils/utils.service';
import {
  BoxGeometry,
  Color,
  CylinderGeometry,
  Fog,
  GammaEncoding,
  HemisphereLight,
  IUniform,
  Mesh,
  MeshBasicMaterial,
  MeshPhysicalMaterial,
  Object3D,
  PCFSoftShadowMap,
  PerspectiveCamera,
  Scene,
  SphereGeometry,
  SpotLight,
  Vector2,
  Vector3,
  WebGLRenderer,
  BufferGeometry,
  LoadingManager,
  HalfFloatType
} from 'three';
import { Sky } from 'three/examples/jsm/objects/Sky';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { fromEvent, Observable } from 'rxjs';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils'
// import { EffectComposer, EffectPass, RenderPass, SMAAEffect, SMAAImageLoader, OutlineEffect, KernelSize, BlendFunction  } from "postprocessing";
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { SSRPass } from 'three/examples/jsm/postprocessing/SSRPass.js';
import { ReflectorForSSRPass } from 'three/examples/jsm/objects/ReflectorForSSRPass.js';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js';
import { SSAOPass  } from 'three/examples/jsm/postprocessing/SSAOPass.js';

@Injectable({ providedIn: 'root' })
export class ThreeViewService {

  constructor(@Inject(UtilsService) private readonly utilsService: UtilsService) { }

  get threeJsContainer(): HTMLElement {
    return this.container;
  }

  onContainerEvent(eventName: string): Observable<Event> {
    return fromEvent(this.container, eventName);
  }

  init(threeJsContainer: HTMLElement) {
    this.container = threeJsContainer;
    const width = this.container.clientWidth;
    const height = this.container.clientHeight;

    this.camera = new PerspectiveCamera(50, width / height, 5, 5000);
    this.offsetCamera(0);

    this.camera.position.set(10, 150, 300);
    this.camera.name = 'Camera';


    this.scene = new Scene();
    this.scene.add(this.camera);
    this.setLights(this.scene);
    const fogColor = 0xdbdfdf;
    this.scene.fog = new Fog(fogColor, 500, 5000);

    this.renderer = new WebGLRenderer({ antialias: true });
    this.renderer.setClearColor(0xe5e5e5, 0);
    this.renderer.autoClear = false;
    this.renderer.outputEncoding = GammaEncoding;
    // this.renderer.gammaInput = true; // todo: ThreeJS Migration
    // this.renderer.gammaOutput = true; // todo: ThreeJS Migration

    // this.renderer.toneMapping = Uncharted2ToneMapping;

    this.renderer.gammaFactor = this.utilsService.overallGamma; // todo: ThreeJS Migration - deprecated

    // this.renderer.toneMappingWhitePoint = 1.0;
    this.renderer.toneMappingExposure = 1.0;
    this.renderer.setSize(width, height);
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = PCFSoftShadowMap;
    // this.renderer.physicallyCorrectLights = true;

    const sky = new Sky();
    sky.scale.setScalar(45000);
    this.scene.add(sky);
    const sunSphere = new Mesh(
      new SphereGeometry(20000, 16, 8),
      new MeshBasicMaterial({ color: 0xffffff })
    );
    sunSphere.position.y = -7000;
    sunSphere.visible = false;
    this.scene.add(sunSphere);
    this.uniforms = sky.material.uniforms;

    this.uniforms['sunPosition'].value.copy(new Vector3(800, 900, 800));

    Object.keys(this.components).forEach((prop: string) => {
      this.components[prop].name = prop;
      this.scene.add(this.components[prop]);
    });

    this.utilsService.setNode(this.components);

    this.cameraControl = this.setControls(this.camera, this.container);
    this.utilsService.setControls(this.cameraControl, this.renderer, this.scene, this.camera, this.container);
    const measurmentsBorder = new Object3D();
    measurmentsBorder.name = 'measurements-border';

    const measurmentsStone = new Object3D();
    measurmentsStone.name = 'measurements-stone';

    const measurmentsPlate = new Object3D();
    measurmentsPlate.name = 'measurements-plate';

    const measurementsPlateExtra1 = new Object3D();
    measurmentsPlate.add(measurementsPlateExtra1);

    const measurementsPlateExtra2 = new Object3D();
    measurmentsPlate.add(measurementsPlateExtra2);

    const measurementsPlateExtra3 = new Object3D();
    measurmentsPlate.add(measurementsPlateExtra3);

    const measurementsPlateExtra4 = new Object3D();
    measurmentsPlate.add(measurementsPlateExtra4);

    const measurmentsAccessories = new Object3D();
    measurmentsAccessories.name = 'measurements-accessory';

    this.components.measurements.add(measurmentsBorder);
    this.components.measurements.add(measurmentsStone);
    this.components.measurements.add(measurmentsPlate);
    this.components.measurements.add(measurmentsAccessories);

    this.effectsComposer = new EffectComposer(this.renderer)
    let ssrPass = new SSRPass( {
      renderer: this.renderer,
      scene: this.scene,
      camera: this.camera,
      width: width,
      height: height,
      encoding: GammaEncoding,
      selects: this.scene.children
    });
    this.effectsComposer.addPass( ssrPass );
    let resolution = new Vector2( 256, 256 )
    this.outlinePass = new OutlinePass(resolution, this.scene, this.camera, [])
    this.outlinePass.edgeGlow = 1;
		this.outlinePass.edgeThickness = 3;
		this.outlinePass.edgeStrength = 5;
    this.effectsComposer.addPass( this.outlinePass );


    this.fxaa = new ShaderPass( FXAAShader );
    const pixelRatio = this.renderer.getPixelRatio();

    this.fxaa.material.uniforms[ 'resolution' ].value.x = 1 / ( this.container.clientWidth * pixelRatio );
    this.fxaa.material.uniforms[ 'resolution' ].value.y = 1 / ( this.container.clientHeight * pixelRatio );

    this.effectsComposer.addPass( this.fxaa );

    let smaaPass = new SMAAPass(width * window.devicePixelRatio, height * window.devicePixelRatio)
    this.effectsComposer.addPass(smaaPass) 

    window.addEventListener('resize', () => this.resize(this.camera));
  }

  public getRenderer(){
    return this.renderer;
  }

  public removePolishSphere() {
    const sphere = this.scene.getObjectByName('polishSphere');
    if (sphere) {
      this.scene.remove(sphere);
    }

  }

  public removeAllMeasurements() {
    let mb = this.scene.getObjectByName('measurements-border');
    for (var i = mb.children.length - 1; i >= 0; i--) {
      mb.remove(mb.children[i]);
    }

    let ms = this.scene.getObjectByName('measurements-stone');
    for (var i = ms.children.length - 1; i >= 0; i--) {
      ms.remove(ms.children[i]);
    }
    let mp = this.scene.getObjectByName('measurements-plate');
    for (var i = mp.children.length - 1; i >= 0; i--) {
      mp.remove(mp.children[i]);
    }
  }

  public updatePolishSphere() {
    let sphere = this.scene.getObjectByName('polishSphere');
    if (!sphere) {
      const geometry = new SphereGeometry(40, 64, 64);
      // geometry.computeFaceNormals();
      geometry.computeVertexNormals();
      const material = new MeshPhysicalMaterial({ color: 0xffffff });
      sphere = new Mesh(geometry, material);
      sphere.position.set(0, 40, 50);
      sphere.name = 'polishSphere';
      sphere.castShadow = true;
      sphere.receiveShadow = true;
      this.scene.add(sphere);
      // this.renderScene = true;
    }
    return sphere;
  }

  addDummyMonumentHolder(values?) {
    if (!values) {
      this.components.dummy.remove(this.components.dummy.children[0]);
      this.components.dummy.remove(this.components.dummy.children[0]);
      this.components.dummy.remove(this.components.dummy.children[0]);
      return;
    }

    if (values.width === 0) {
      values.width = 0.01;
    }
    if (values.height === 0) {
      values.height = 0.01;
    }
    if (values.length === 0) {
      values.length = 0.01;
    }
    if (values.borderTickness === 0) {
      values.borderTickness = 0.01;
    }
    if (values.borderHeight === 0) {
      values.borderHeight = 0.01;
    }

    if (this.components.dummy.children.length) {
      this.components.dummy.children[0].scale.set(values.width, values.height, values.length);
      this.components.dummy.children[0].position.z = values.length / 2;
      this.components.dummy.children[0].position.y = values.height / 2;
      this.components.dummy.children[1].position.set(0, values.height, values.length / 2);
      this.components.dummy.children[2].scale.set(
        values.groundWidth - 2 * values.borderTickness, values.borderHeight, values.groundLength - 2 * values.borderTickness);
      this.components.dummy.children[2].position.y = values.borderHeight / 2;
      this.components.dummy.children[2].position.z = (values.groundWidth - 2 * values.borderTickness) / 2 + values.borderTickness;
      this.components.dummy.children[0].visible = values.showMH;
      this.components.dummy.children[1].visible = values.showMH;
      this.components.dummy.children[2].visible = values.showB;
    } else {
      const geometry = new BoxGeometry(1, 1, 1);
      const material = new MeshBasicMaterial({ color: 0x00ff00, wireframe: true });
      const cube = new Mesh(geometry, material);
      cube.scale.set(values.width, values.height, values.length);
      cube.position.z = values.length / 2;
      cube.position.y = values.height / 2;
      cube.visible = values.showMH;
      this.components.dummy.add(cube);

      const geometry2 = new CylinderGeometry(5, 5, 0.1, 32);
      const material2 = new MeshBasicMaterial({ color: 0xff0000 });
      const cylinder = new Mesh(geometry2, material2);
      cylinder.position.set(0, values.height, values.length / 2);
      cylinder.visible = values.showMH;
      this.components.dummy.add(cylinder);

      const geometry3 = new BoxGeometry(1, 1, 1);
      const material3 = new MeshBasicMaterial({ color: 0xff0000, wireframe: true });
      const cube3 = new Mesh(geometry3, material3);
      cube3.scale.set(values.groundWidth - 2 * values.borderTickness, values.borderHeight, values.groundLength - 2 * values.borderTickness);
      cube3.position.z = (values.groundWidth - 2 * values.borderTickness) / 2 + values.borderTickness;
      cube3.position.y = values.borderHeight / 2;
      cube3.visible = values.showB;
      this.components.dummy.add(cube3);

    }
  }

  changeEnv(name) {
    switch (name) {
      case 'Sommer':
        this.uniforms['turbidity'].value = 0.1;
        this.uniforms['rayleigh'].value = 0.01;
        this.uniforms['mieCoefficient'].value = 0.5;
        this.uniforms['mieDirectionalG'].value = 0.2;
        // this.uniforms['luminance'].value = 0.1;
        this.uniforms['sunPosition'].value.copy(new Vector3(611, 900, 611));
        this.spot.position.set(611, 900, 611);

        this.ambient.intensity = 0.9;
        this.ambient.color = new Color(0xededeb);
        this.ambient.groundColor = new Color(0xc9c6bf);
        break;
      case 'Herbst':
        this.uniforms['turbidity'].value = 16;
        this.uniforms['rayleigh'].value = 0.22;
        this.uniforms['mieCoefficient'].value = 0.02;
        this.uniforms['mieDirectionalG'].value = 0.2;
        // this.uniforms['luminance'].value = 0.15;
        this.uniforms['sunPosition'].value.copy(new Vector3(800, 220, 800));
        this.spot.position.set(800, 350, 800);

        this.ambient.intensity = 1;
        this.ambient.color = new Color(0xccc6bc);
        this.ambient.groundColor = new Color(0xc9c6bf);
        break;
      case 'Standard':
        this.uniforms['turbidity'].value = 1.6;
        this.uniforms['rayleigh'].value = 1;
        this.uniforms['mieCoefficient'].value = 0.9;
        this.uniforms['mieDirectionalG'].value = 0.1;
        // this.uniforms['luminance'].value = 1;
        this.uniforms['sunPosition'].value.copy(new Vector3(200, 900, 350));
        this.spot.position.set(200, 900, 350);
        this.ambient.color = new Color(0xd8d9d4);
        this.ambient.intensity = 0.85;
        this.ambient.groundColor = new Color(0xc9c6bf);
        break;
    }
  }

  animate() {
    this.container.appendChild(this.renderer.domElement);
    const animate = () => {
      requestAnimationFrame(animate);
      if (this.renderScene) {
        if (this.renderTime >= 1) {
          if (this.renderTime > 1) {
            this.renderTime -= 1;
          } else {
            this.renderScene = false;
          }
        }
        this.cameraControl.update();
        // this.renderer.render(this.scene, this.camera);
        this.effectsComposer.render()
      }
    };
    animate();
  }

  resizeCamera() {
    this.resize(this.camera);
  }

  public prePositionCameraForSnapshot() {
    this.camera.position.set(100, 100, 150);
    this.controls.target.set(0, 30, 50);
    this.controls.update();
  }

  setCameraPosition() {

    let objectsSphereGeometry = new BufferGeometry();
    let geometries: Array<BufferGeometry> = []
    let matrices = []
    let accessories = this.components.accessories;
    let border = this.components.border;
    let plate = this.components.plate;
    let stone = this.components.stone;
 
    border.traverse((children) => {
      if (children.type === 'Mesh') {
        geometries.push(children['geometry'].clone())
        geometries[geometries.length-1].applyMatrix4(children.matrixWorld)
      }
    });
    accessories.traverse((children) => {
      if (children.type === 'Mesh') {
        geometries.push(children['geometry'].clone())
        geometries[geometries.length-1].applyMatrix4(children.matrixWorld)
      }
    });
    plate.traverse((children) => {
      if (children.type === 'Mesh') {
        geometries.push(children['geometry'].clone())
        geometries[geometries.length-1].applyMatrix4(children.matrixWorld)
      }
    });
    stone.traverse((children) => {
      if (children.type === 'Mesh') {
        geometries.push(children['geometry'].clone())
        geometries[geometries.length-1].applyMatrix4(children.matrixWorld)
      }
    });

    for(let j = 0; j < geometries.length; j++){
      //check if there are any other attributes other then normal, position and uv and remove them
      let att = Object.keys(geometries[j].attributes)
      for(let i = 0; i < att.length; i++){
        if(att[i] != 'position'){
          geometries[j].deleteAttribute(att[i])
        }
      }
      geometries[j].index = null
    }
    
    if(geometries.length > 0){
      objectsSphereGeometry = BufferGeometryUtils.mergeBufferGeometries(geometries)
    }
    objectsSphereGeometry.computeBoundingSphere();

    let radius = objectsSphereGeometry.boundingSphere.radius;
    let sin = Math.sin(0.436332);
    let distance = radius / sin;
    let sphereCenter = objectsSphereGeometry.boundingSphere.center;
    let groundPoint = new Vector3(sphereCenter.x, sphereCenter.y, sphereCenter.z);
    let direction = new Vector3(1, 1, 1).normalize();
    let cameraPosition = direction.clone().multiplyScalar(distance * 1.3);

    objectsSphereGeometry.dispose();
    for(let i = 0; i < geometries.length; i++){
      geometries[i].dispose()
    }

    return { cameraPosition, groundPoint };
  }

  makeProjectThumbnail(size, checked): Promise<Blob> {

    return new Promise((resolve) => {
      this.components.dummy.visible = false;
      this.components.ground.visible = false;

      this.renderer.setSize(size, size);
      this.camera.aspect = 1;
      this.camera.updateProjectionMatrix();
     
      let oldCameraPos = this.camera.position.clone();
      let oldCameraTarget = this.controls.target.clone();
      if(checked || typeof checked == typeof undefined){
        let points = this.setCameraPosition();
        this.camera.position.set(points.cameraPosition.x, points.cameraPosition.y, points.cameraPosition.z);
        this.controls.target.set(points.groundPoint.x, points.groundPoint.y, points.groundPoint.z);
        this.controls.update();
      }

      this.renderer.render(this.scene, this.camera);

      this.renderer.domElement.toBlob((blob) => {
        this.components.dummy.visible = true;
        this.components.ground.visible = true;
        this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
        this.camera.aspect = this.container.clientWidth / this.container.clientHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.render(this.scene, this.camera);
        resolve(blob);
      }, 'image/png', 1.0);

      this.camera.position.set(oldCameraPos.x, oldCameraPos.y, oldCameraPos.z);
      this.controls.target.set(oldCameraTarget.x, oldCameraTarget.y, oldCameraTarget.z);
      this.controls.update();
    });
  }

  needToRender(value: number = 1) {
    this.renderTime = value;
    this.renderScene = true;
  }

  offsetCamera(value, callback?) {
    // const time = 500;
    // this.tween = new Tween({ x: this._lastValue }).to({ x: value }, time).easing(Easing.Quadratic.Out).start();
    // this.tween.on('update', (coords) => {
    //   this.camera.setViewOffset(
    //   this.container.clientWidth,
    //   this.container.clientHeight,
    //   -this.container.clientWidth * coords.x,
    //   this.container.clientHeight * 0,
    //   this.container.clientWidth,
    //   this.container.clientHeight
    //   );
    // });
    //
    //
    // if (callback) {
    //   setTimeout(() => {
    //     callback('done');
    //     this.tween.off('update');
    //   }, time);
    // } else {
    //   // if (value !== 0) {
    //   //   this.camera.lookAt(new Vector3(this.orbitControlsTarget.x, this.orbitControlsTarget.y, this.orbitControlsTarget.z));
    //   // }
    // }
    //
    // this._lastValue = value;
  }

  changeCameraPos(pos, callback?) {
    // let time = 1000;
    // this.tween = new Tween(this.camera.position).to(new Vector3(pos.x, pos.y, pos.z), time).easing(Easing.Quadratic.Out).start();
    // this.tween.on('update', (coords) => {
    //   this.camera.lookAt(new Vector3(this.orbitControlsTarget.x, this.orbitControlsTarget.y + pos.h, this.orbitControlsTarget.z));
    //   this.utilsService.getControls().target =
    //   new Vector3(this.orbitControlsTarget.x, this.orbitControlsTarget.y, this.orbitControlsTarget.z);
    // });

    // if (callback) {
    //   setTimeout(() => {
    //     callback('done');
    //     this.tween.off('update');
    //   }, time);
    // }
  }

  setQuality(value: number) {
    switch (value) {
      case 0:
        // this.spot.castShadow = false;
        // this.spot1.castShadow = false;
        // this.spot2.castShadow = false;
        this.spot.shadow.mapSize = new Vector2(1, 1);
        this.renderer.setPixelRatio(1);
        break;
      case 1:
        // this.spot.castShadow = true;
        // this.spot1.castShadow = false;
        // this.spot2.castShadow = false;
        this.spot.shadow.mapSize.width = 1024;
        this.spot.shadow.mapSize.height = 1024;
        //  = new Vector2(1024, 1024);
        // this.spot.shadow.bias = -0.0000001;
        this.renderer.setPixelRatio(1);
        break;
      case 2:
        // this.spot1.castShadow = true;
        // this.spot2.castShadow = true;
        this.spot.shadow.mapSize.width = 2048;
        this.spot.shadow.mapSize.height = 2048;
        // this.spot.castShadow = true;
        // this.spot.shadow.bias = -0.00000001;
        // this.spot.shadow.update();
        this.renderer.setPixelRatio(1.3);
        break;
      case 3:
        // this.spot1.castShadow = true;
        // this.spot2.castShadow = true;
        // this.spot.castShadow = true;
        this.spot.shadow.mapSize = new Vector2(4096, 4096);
        // this.spot.shadow.bias = -0.000000001;
        this.renderer.setPixelRatio(1.6);
        break;
      case 4:
        // this.spot1.castShadow = true;
        // this.spot2.castShadow = true;
        // this.spot.castShadow = true;
        this.spot.shadow.mapSize = new Vector2(8192, 8192);
        // this.spot.shadow.bias = -0.000000000001;
        this.renderer.setPixelRatio(2.2);
        break;
    }
  }

  private setLights(scene) {
    const shadowSize = 2048;
    this.ambient = new HemisphereLight(0xEFEFEF, 0xBDBDBD, 1.0);
    scene.add(this.ambient);
    this.spot = new SpotLight(0xf9fcfc, 0.4);
    this.spot.position.set(800, 900, 800);
    this.spot.castShadow = true;
    this.spot.shadow.camera.near = 1;
    this.spot.shadow.camera.far = 2000;
    this.spot.penumbra = 0.999;
    this.spot.angle = 0.4;
    this.spot.shadow.mapSize.width = shadowSize;
    this.spot.shadow.mapSize.height = shadowSize;

    this.spot.shadow.bias = -0.000000001;

    scene.add(this.spot);
  }

  private setControls(camera, container): OrbitControls {
    this.controls = new OrbitControls(camera, container);
    this.controls.enablePan = true;
    this.controls.maxDistance = 900;
    this.controls.minDistance = 20;
    this.controls.maxPolarAngle = 1.45;
    this.controls.screenSpacePanning = true;
    this.controls.enableKeys = false;
    this.controls.target.set(this.orbitControlsTarget.x, this.orbitControlsTarget.y, this.orbitControlsTarget.z);
    this.controls.addEventListener('change', () => this.needToRender());
    this.controls.update();
    return this.controls;
  }

  private resize(camera: any) {
    const width = this.container.clientWidth;
    const height = this.container.clientHeight;
    // const stretchValue = this.interpolate(3000, window.innerWidth, 900, 0.01, 0.2);
    // if (window.innerWidth / window.innerHeight > 1) this.offsetCamera(stretchValue);
    // else this.offsetCamera(0)
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
    this.renderer.setSize(width, height);

    this.effectsComposer.setSize( width, height );
    const pixelRatio = this.renderer.getPixelRatio();
    
		this.fxaa.material.uniforms[ 'resolution' ].value.x = 1 / ( width * pixelRatio );
    this.fxaa.material.uniforms[ 'resolution' ].value.y = 1 / ( height * pixelRatio );

    this.needToRender();
  }

  private controls;
  private readonly components = {
    ground: new Object3D(),
    border: new Object3D(),
    stone: new Object3D(),
    plate: new Object3D(),
    accessories: new Object3D(),
    texts: new Object3D(),
    measurements: new Object3D(),
    environment: new Object3D(),
    dummy: new Object3D()
  };
  private container: HTMLElement;
  private camera: PerspectiveCamera;
  private scene: Scene;
  private renderer: WebGLRenderer;
  private ambient: HemisphereLight;
  private spot: SpotLight;
  private spot1: SpotLight;
  private spot2: SpotLight;
  private uniforms: { [uniform: string]: IUniform };
  private renderScene = true;
  private renderTime = 1;
  private orbitControlsTarget = { x: 0, y: 10, z: 50 };
  private cameraControl: OrbitControls;
  private effectsComposer: EffectComposer
  private fxaa: FXAAShader
  outlinePass: OutlinePass
}
