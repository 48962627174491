import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { ThreeViewComponent } from './three-view/three-view.component';
import 'hammerjs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PlateService } from './three-view/three-services/plate.service';
import { BorderGeneratedService } from './three-view/three-services/border-generated.service';
import { GroundService } from './three-view/three-services/ground.service';
import { AccessoriesService } from './three-view/three-services/accessories.service';
import { TextsService } from './three-view/three-services/texts.service';
import { StoneService } from './three-view/three-services/stone.service';
import { MeasurementsService } from './three-view/three-services/measurements.service';
import { LoginComponent } from './components/login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { WithoutBordersService } from './three-view/three-services/without-borders.service';
import { EventsService } from './services/events.service';
import { NumericComponent } from './components/numeric/numeric.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { JoyrideModule } from 'ngx-joyride';


@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    ThreeViewComponent,
    LoginComponent,
    NumericComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    JoyrideModule.forRoot(),
  ],
  providers: [
    BorderGeneratedService,
    PlateService,
    GroundService,
    AccessoriesService,
    TextsService,
    EventsService,
    StoneService,
    MeasurementsService,
    WithoutBordersService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
