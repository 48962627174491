import { Categorizable, CategoryModelType } from '../categorizable.model';
import { Dimension3D, Point3D } from '../../../point-3d.model';
import { DimensionLine } from '../shared/dimension-line.model';
import { Group } from '../shared/group.model';
import { FilterDefinition } from '../../../../../commons/filter/filter-definition';
import { isEqual } from 'lodash-es';

export class Stone implements Categorizable {
  uid: string = null;
  name: string = null;
  filters: Array<string> = [];
  type: CategoryModelType = CategoryModelType.STONE;
  polish: { [s: string]: string } | string = {};
  material: { [s: string]: string } | string = {};

  src: string = null;
  thumbnail: string = null;

  label: string = null;
  folder: string = null;
  category: string = null;

  snapToMonumentHolder: boolean = true;
  position: Point3D = { x: 0, y: 0, z: 0 };
  rotation: number = 0;
  push: number = 0;
  dimensions: Dimension3D = { width: 0, length: 0, height: 0 };

  dimensionLines: Array<DimensionLine> = [];
  groups: Array<Group> = [];

  keywords: Array<string> = [];

  objectsList = undefined;

  static of(source: Stone): Stone {
    return Object.assign(new Stone(), source);
  }

  static equals(value: Stone, other: Stone): boolean {
    if (!value && !other) { return true; }
    if (!value || !other) { return false; }
    return value.uid === other.uid
      && value.name === other.name
      && value.type === other.type
      && value.src === other.src
      && value.thumbnail === other.thumbnail
      && value.folder === other.folder
      && isEqual(value.material, other.material)
      && isEqual(value.polish, other.polish)
      && isEqual(value.dimensionLines, other.dimensionLines)
      && value.category === other.category
      && value.snapToMonumentHolder === other.snapToMonumentHolder
      && isEqual(value.position, other.position)
      && value.rotation === other.rotation
      && value.push === other.push
      && isEqual(value.dimensions, other.dimensions);
  }

  getFilterDefinitions(): Array<FilterDefinition> {
    return [FilterDefinition.fulltext('name')];
  }

  setPosition(position) {
    this.position.x = position.x;
    this.position.y = position.y;
    this.position.z = position.z;
  }

  setDefault() {
    this.name = '';
    this.type = CategoryModelType.STONE;
    this.label = '';
    this.src = '';
    this.thumbnail = '';
    this.material = {};
    this.position = {
      x: 0,
      y: 0,
      z: 0
    };
    this.filters = [];
    this.polish = {};
    this.groups = [];
    this.snapToMonumentHolder = true;
    this.folder = '';
    this.rotation = 0;
    this.push = 0;
    this.dimensionLines = [];
    this.uid = null;
    return this;
  }

  set(props) {
    this.name = props.name;
    this.uid = props.uid;
    this.type = CategoryModelType.STONE;
    this.label = props.label;
    this.src = props.src;
    this.thumbnail = props.thumbnail;
    this.material = props.material;
    this.position = {
      x: props.position.x,
      y: props.position.y,
      z: props.position.z
    };
    this.filters = props.filters;
    this.polish = props.polish;
    this.groups = props.groups || [];
    this.snapToMonumentHolder = props.snapToMonumentHolder ? props.snapToMonumentHolder : false;
    this.folder = props.folder;
    this.rotation = props.rotation;
    this.push = props.push;
    this.dimensionLines = props.dimensionLines ? props.dimensionLines : [];
  }

  setMat(props, name?) {
    if (name && this.material[name]) {
      this.material[name] = props;
    } else {
      this.material = props;
    }
  }

  setPolish(props, name?) {
    if (name && this.polish[name]) {
      this.polish[name] = props;
    } else {
      this.polish = props;
    }
  }
}
