import { FilterDefinition } from '../../../commons/filter/filter-definition';

export interface Filterable {
  keywords: Array<string>;
  additionalFilters?: object;

  getFilterDefinitions(): Array<FilterDefinition>;
}

export function isFilterable(obj: any): boolean {
  return obj && obj.hasOwnProperty('keywords') && (typeof obj['getFilterDefinitions'] === 'function');
}

