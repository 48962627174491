import {Inject, Injectable} from '@angular/core';
import { UtilsService } from '../three-utils/utils.service';
import { AssetsLoadingManager } from './loader-service';
import { MaterialService } from './material.service';
import { MeasurementsService } from './measurements.service';
import { Stone } from '../../../models/firestore/categories/stone/stone.model';
import { Object3D } from 'three';
import {AbstractObject3DService} from './abstract-object3d.service';
import {BorderItemsFirestoreService} from '../../modules/ground/services/border-items-firestore.service';
import {Border} from '../../../models/firestore/categories/border/border.model';

@Injectable()
export class StoneService extends AbstractObject3DService<Stone>{

  constructor(
    @Inject(MaterialService) protected materialsService: MaterialService,
    @Inject(BorderItemsFirestoreService) protected firestoreService: BorderItemsFirestoreService,
    @Inject(AssetsLoadingManager) protected _loader: AssetsLoadingManager,
    @Inject(UtilsService) protected utilsService: UtilsService,
    @Inject(MeasurementsService) protected measurementsService: MeasurementsService) {
    super(utilsService, materialsService, _loader, Stone, 'stone');
  }

  public requestUpdate(updateValues: Partial<Stone>, simpleUpdate?: boolean): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if(!this._objectRef || !updateValues) {return resolve();}
      this._update(updateValues);
      this.needsRender.next();
      resolve();
    });
  }

  public addStone(values: Stone) {
    if(!values?.name) {return;}
    if(this._objectRef) {
      this.measurementsService.drawStoneMeasurements(this._node, true);
      this.purge();
    }
    const stoneToAdd = new Stone();
    stoneToAdd.set({...values, uid: this.firestoreService.createId()});
    this._loader.load(stoneToAdd.src).then((obj) => {
      if(this._objectRef) {
        this.purge();
      }
      obj.traverse(child => {
        child.userData = this.utilsService.createDeepClone(stoneToAdd);
      });
      this._node.add(obj);
      this._update(stoneToAdd);
      this._applyMaterial();
      this._objectRef = stoneToAdd;
    });
  }

  public purge() {
    this.measurementsService.drawStoneMeasurements(this._node, true);
    this.removeFromScene();
    this._objectRef = undefined;
    this.needsRender.next();
  }

  public updateMeasurements() {
    this._node.updateMatrixWorld();
    this.measurementsService.drawStoneMeasurements(this._node);
  }

  public changeAppearance(updateValues: {appearance: {material?: string, polish?: string}, meshName: string}) {
    const groupIndex = this._findMeshInGroup(updateValues.meshName);
    let tempMeshes: Array<string>;
    if(groupIndex !== -1) {
      tempMeshes = [...this._objectRef.groups[groupIndex].models];
    } else {
      tempMeshes = [updateValues.meshName];
    }
    for(let tempMesh of tempMeshes) {
      const mesh = this.utilsService.getMeshByName(tempMesh, this._node);
      if(mesh) {
        mesh.userData.material[tempMesh] = updateValues.appearance.material ?? mesh.userData.material[tempMesh];
        this._objectRef.material[tempMesh] = mesh.userData.material[tempMesh];
      }
    }
    if(updateValues.appearance.polish) {
      const mesh = this.utilsService.getMeshByName(updateValues.meshName, this._node);
      if(mesh) {
        mesh.userData.polish[updateValues.meshName] = updateValues.appearance.polish;
        this._objectRef.polish[updateValues.meshName] = updateValues.appearance.polish;
      }
    }
    this._node.userData.material = this.utilsService.createDeepClone(this._objectRef.material);
    this._node.userData.polish = this.utilsService.createDeepClone(this._objectRef.polish);
    this._applyMaterial();
  }

  protected _update(values?) {
    this._node = this.utilsService.getNodeByName('stone');
    if(!this._node) {return;}
    if (this._node.children.length === 0) {
      this.measurementsService.drawStoneMeasurements(this._node, true);
      return;
    }

    if(values instanceof Stone) {
      this._objectRef = this.utilsService.createDeepClone(values);
    } else {
      Object.keys(values).forEach(key => {
        if (values[key] !== undefined) {
          this._objectRef[key] = values[key];
        }
      })
    }
    this._node.traverse(child => {
      child.userData = this.utilsService.createDeepClone(this._objectRef);
    })

    const dimensions = this.utilsService.getDimensionsFromBBox(this._node.children[0]);

    if (this._objectRef && this._objectRef.position && typeof this._objectRef.position.x === 'number') {
      this._node.children[0].position.x = this._objectRef.position.x;
    }

    if (this._objectRef && this._objectRef.position && typeof this._objectRef.position.y === 'number') {
      this._node.children[0].position.y = this._objectRef.position.y;
    }

    if (this._objectRef && this._objectRef.position && typeof this._objectRef.position.z === 'number') {
      this._node.children[0].position.z = this._objectRef.position.z + (this._objectRef.push ? this._objectRef.push : 0);
    }


    if (this._objectRef && (this._objectRef.rotation != null) && typeof this._objectRef.rotation === 'number') {
      //TO DO fix the rotation
      this._node.children[0].rotation.y = this._objectRef.rotation * Math.PI / 180;
    }

    this._objectRef.objectsList = {};

    this._node.children[0].userData = this._objectRef;


    if (!this._node.children[0].userData.dimensions) {
      this._node.children[0].userData.dimensions = {};
    }
    this._node.children[0].userData.dimensions.length = Math.round(dimensions.length);
    this._node.children[0].userData.dimensions.width = Math.round(dimensions.width);
    this._node.children[0].userData.dimensions.height = Math.round(dimensions.height);

    let count = 1;
    for (let index = 0; index < this._node.children[0].children.length; index++) {
      const element = this._node.children[0].children[index];
      const sizes = this.utilsService.getSizes(element);
      let name = element.name;
      if (element.name.includes('bject')) {
        name = 'Montageteil ' + count;
        count++;
      }
      this._objectRef.objectsList[element.name] = {
        name: name,
        size: {
          width: sizes.width.toFixed(1),
          length: sizes.length.toFixed(1),
          height: sizes.height.toFixed(1)
        },
        material: this._objectRef.material[element.name] ? this._objectRef.material[element.name] : this._objectRef.material,
        polish: this._objectRef.polish[element.name] ? this._objectRef.polish[element.name] : this._objectRef.polish
      };

    }
    this.updateMeasurements();
  }

  getObjectCopy() {
    return this.utilsService.createDeepClone(this._objectRef);
  }
}
