import { CustomControlValueAccessor } from '../custom.cva';
import { Directive, Input, OnInit } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { MyErrorStateMatcher } from '../errors/error-state-matcher';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class MaterialInputControlValueAccessor extends CustomControlValueAccessor implements OnInit {
  @Input() readonly label: string;
  @Input() readonly placeholder: string;
  @Input() errorStateMatcher: ErrorStateMatcher;

  ngOnInit() {
    super.ngOnInit();
    this.errorStateMatcher = new MyErrorStateMatcher(this.externalControl);
  }
}
