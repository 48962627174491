import { Directive, Input } from '@angular/core';
import { ControlValueAccessor } from '@vixncu/ngx-formx';
import { Observable } from 'rxjs';
import { debounceTimeAfterFirst } from '../rxjs-utils/debounce-time-after';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class CustomControlValueAccessor extends ControlValueAccessor<any, any> {
  @Input() readonly debounceTime = this.defaultDebounceTime;

  protected get defaultDebounceTime(): number {
    return 0;
  }

  protected valueChanges$(): Observable<any> {
    return super.valueChanges$().pipe(debounceTimeAfterFirst(this.debounceTime));
  }
}
