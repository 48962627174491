// PROD
export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyAY0nPsk_On9inliFsKqw-XijVR9Ufy_KM',
    authDomain: 'lithos-app.firebaseapp.com',
    databaseURL: 'https://lithos-app.firebaseio.com',
    projectId: 'lithos-app',
    storageBucket: 'lithos-app.appspot.com',
    messagingSenderId: '19746151143',
    appId: '1:19746151143:web:3e2da51e7ba719c76fa042',
    measurementId: 'G-72TT6LMCY2'
  },
  appUrl: 'https://app.lithosmarmor.de'
};

// DEV
// export const environment = {
//   production: true,
//   firebase: {
//     apiKey: 'AIzaSyA9Sq7n1U43FwfwuJuNvRHc4cXlOOhPac0',
//     authDomain: 'lithos-app-dev.firebaseapp.com',
//     databaseURL: 'https://lithos-app-dev.firebaseio.com',
//     projectId: 'lithos-app-dev',
//     storageBucket: 'lithos-app-dev.appspot.com',
//     messagingSenderId: '968392968969',
//     appId: '1:968392968969:web:0ca9832cf41a19b1fb61d8',
//     measurementId: 'G-KMQZS34WZY'
//   },
//   appUrl: 'https://lithos-app-dev.web.app'
// };
