import { ChangeDetectionStrategy, Component, Inject, OnInit, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-screenshot-dialog',
  template: `
    <h3 mat-dialog-title>Screenshot Bestätigung</h3>
    <div mat-dialog-content>
      <ng-container *ngIf="screenshot">
        <img [src]="screenshot" [width]="500" [height]="500" alt="Screenshot"/>
      </ng-container>
    </div>
    <div mat-dialog-actions *ngIf="thumbnail">
      <button mat-raised-button color="primary" (click)="confirm()">Confirm</button>
      <button mat-raised-button color="warn" (click)="decline()">Decline</button>
    </div>
    <div *ngIf="!thumbnail">     
      Klicken Sie mit der rechten Maustaste, um das Bild zu speichern
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScreenshotDialogComponent implements OnInit {
  screenshot: SafeUrl;
  thumbnail: boolean;
  constructor(
    @Inject(MatDialogRef) private readonly dialogRef: MatDialogRef<ScreenshotDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: { blob: Blob, isThumbnail: boolean },
    @Inject(DomSanitizer) private readonly sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.thumbnail = this.data.isThumbnail;
    this.screenshot = this.toSanitizedUrl(this.data.blob);
  }

  confirm(): void {
    this.dialogRef.close(this.data.blob);
  }

  decline(): void {
    this.dialogRef.close(null);
  }

  private toSanitizedUrl(blob: Blob): SafeUrl {
    const objectURL = URL.createObjectURL(blob);
    return this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }
}
