import { Inject, Injectable, OnDestroy } from '@angular/core';
import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { filter, map, share, takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FullscreenService implements OnDestroy {

  constructor(@Inject(DOCUMENT) private readonly document: Document) {
    this.observeFullscreenTriggerEvents();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  observe(): Observable<boolean> {
    return this.sharedSubscription$;
  }

  toggleFullscreen() {
    this.isFullscreen() ? this.exitFullscreen() : this.enterFullscreen();
  }

  isFullscreen(): boolean {
    return !!this.document.fullscreenElement;
  }

  private observeFullscreenTriggerEvents() {
    merge(
      fromEvent(this.document, 'fullscreenchange'),
      fromEvent(this.document, 'keydown').pipe(filter((event: KeyboardEvent) => event.code === 'F11' || event.code === 'Escape'))
    ).pipe(
      takeUntil(this.destroyed$),
      map(() => this.isFullscreen())
    ).subscribe(isFullscreen => this.fullscreen$.next(isFullscreen));
  }

  private enterFullscreen() {
    this.document.body.requestFullscreen && this.document.body.requestFullscreen();
  }

  private exitFullscreen() {
    this.document.exitFullscreen && this.document.exitFullscreen();
  }

  private readonly destroyed$: Subject<void> = new Subject<void>();
  private readonly fullscreen$: Subject<boolean> = new Subject<boolean>();
  private readonly sharedSubscription$: Observable<boolean> = this.fullscreen$.asObservable().pipe(share());
}
