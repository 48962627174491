import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from './angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ColorPickerModule } from 'ngx-color-picker';
import { FmxErrorsModule, FmxSubmitModule } from '@vixncu/ngx-formx';


@NgModule({
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    FlexLayoutModule,
    Ng2SearchPipeModule,
    ColorPickerModule,
    FmxSubmitModule,
    FmxErrorsModule
  ]
})
export class SharedLibsModule {}
