export interface AdminRoutesNames {
  ROOT: string;
  BORDERS: string;
  STONES: string;
  STONES_FOLDERS: string;
  PLATES: string;
  PLATES_FOLDERS: string;
  ACCESSORIES: string;
  ACCESSORIES_FOLDERS: string;
  MATERIALS: string;
  POLISHES: string;
  TEXT_FONTS: string;
}

export const adminRoutesNames: AdminRoutesNames = {
  ROOT: '',
  BORDERS: 'borders',
  STONES: 'stones',
  STONES_FOLDERS: 'stones/folders',
  PLATES: 'plates',
  PLATES_FOLDERS: 'plates/folders',
  ACCESSORIES: 'accessories',
  ACCESSORIES_FOLDERS: 'accessories/folders',
  MATERIALS: 'materials',
  POLISHES: 'polishes',
  TEXT_FONTS: 'text/fonts',
};
