export class FileUpload {

  address: string; // email
  email: string; // duplicate of address
  key: string;
  name: string;
  url: string;
  file: File;
  type: string;
  thumbnail?: string;
  displayName: string;
  uuid: string;
  lastModified: string;
  lastOrdered: string;

  constructor(file: File, name?:string) {
    this.file = file;
    this.name = name;
  }
}
