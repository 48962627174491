export class Ground {
  width: number;
  length: number;
  height?: number;
  type: string;
  secondaryGround?: boolean;

  constructor() { }

  set(props) {
    this.width = props.width;
    this.length = props.length;
    this.height = props.height;
    this.type = 'ground';
    this.secondaryGround = props.secondaryGround ? props.secondaryGround : false;
  }

  setDefault() {
    this.width = 100;
    this.length = 100;
    this.height = 0;
    this.type = 'ground';
    this.secondaryGround = false;
  }
}
