import { Categorizable, CategoryModelType } from '../categorizable.model';
import { DimensionLine } from '../shared/dimension-line.model';
import { Group } from '../shared/group.model';
import { isEqual } from 'lodash-es';
import { FilterDefinition } from '../../../../../commons/filter/filter-definition';

export interface BorderAdditionalFilters {
  groundDimension: string;
}

export class Border implements Categorizable {
  uid: string = null;
  keywords: Array<string> = [];
  additionalFilters: BorderAdditionalFilters = null;
  name: string = null;
  type: CategoryModelType = CategoryModelType.BORDER;
  material: { [s: string]: string } | string = {};
  polish: { [s: string]: string } | string = {};
  /** @deprecated to be removed --> after migration use keywords **/
  filters: Array<string> = [];

  src: string = null;
  thumbnail: string = null;

  label: string = null;
  category: string = null;

  borderTickness: number = 0;
  borderHeight: number = 0;
  monumentHolderWidth: number = 0;
  monumentHolderLength: number = 0;
  monumentHolderHeight: number = 0;
  withoutBorders: boolean = false;
  acceptStone: boolean = false;
  acceptPlate: boolean = false;

  groups: Array<Group> = [];
  dimensionLines: Array<DimensionLine> = [];

  objectsList = undefined;

  static of(source: Border): Border {
    return Object.assign(new Border(), source);
  }

  static toGroundDimensionFilter(width: number, length: number): string {
    return `${width}x${length}`;
  }

  static equals(value: Border, other: Border): boolean {
    if (!value && !other) { return true; }
    if (!value || !other) { return false; }
    return value.uid === other.uid
      && value.name === other.name
      && value.type === other.type
      && value.src === other.src
      && value.thumbnail === other.thumbnail
      && isEqual(value.material, other.material)
      && isEqual(value.polish, other.polish)
      && isEqual(value.dimensionLines, other.dimensionLines)
      && value.category === other.category
      && value.acceptPlate === other.acceptPlate
      && value.acceptStone === other.acceptStone
      && value.borderHeight === other.borderHeight
      && value.borderTickness === other.borderTickness
      && value.monumentHolderHeight === other.monumentHolderHeight
      && value.monumentHolderLength === other.monumentHolderLength
      && value.monumentHolderWidth === other.monumentHolderWidth;
  }

  getFilterDefinitions(): Array<FilterDefinition> {
    return [FilterDefinition.fulltext('name'), FilterDefinition.value('filters', (obj, key) => obj[key][0])];
  }

  setDefault() {
    this.name = '';
    this.keywords = [];
    this.additionalFilters = { groundDimension: '100x100' };
    this.type = CategoryModelType.BORDER;
    this.src = '';
    this.thumbnail = '';
    this.borderTickness = 10;
    this.borderHeight = 20;
    this.monumentHolderWidth = 100;
    this.monumentHolderLength = 20;
    this.monumentHolderHeight = 20;
    this.withoutBorders = false;
    this.material = {};
    this.filters = ['file'];
    this.category = 'fixed';
    this.acceptStone = false;
    this.acceptPlate = false;
    this.polish = {};
    this.groups = [];
    this.dimensionLines = [];
    this.label = '';
    this.uid = null;
    return this;
  }

  set(props) {
    this.uid = props.uid;
    this.name = props.name;
    this.uid = props.uid;
    this.type = props.type;
    this.src = props.src;
    this.thumbnail = props.thumbnail;
    this.borderTickness = props.borderTickness;
    this.borderHeight = props.borderHeight;
    this.monumentHolderWidth = props.monumentHolderWidth;
    this.monumentHolderLength = props.monumentHolderLength;
    this.monumentHolderHeight = props.monumentHolderHeight;
    this.withoutBorders = props.withoutBorders ? props.withoutBorders : false;
    this.material = props.material;
    this.filters = props.filters;
    this.category = props.category;
    this.acceptStone = props.acceptStone;
    this.acceptPlate = props.acceptPlate;
    this.polish = props.polish;
    this.groups = props.groups ? props.groups : [];
    this.dimensionLines = props.dimensionLines ? props.dimensionLines : [];
    this.label = props.label ? props.label : '';
  }

  setDim(props) {
    if (props.borderTickness) {
      this.borderTickness = props.borderTickness;
    }
    if (props.borderHeight) {
      this.borderHeight = props.borderHeight;
    }
    if (props.monumentHolderWidth) {
      this.monumentHolderWidth = props.monumentHolderWidth;
    }
    if (props.monumentHolderLength) {
      this.monumentHolderLength = props.monumentHolderLength;
    }
    if (props.monumentHolderHeight) {
      this.monumentHolderHeight = props.monumentHolderHeight;
    }
    if (props.withoutBorders) {
      this.withoutBorders = props.withoutBorders;
    }
  }

  setMat(props, name?) {
    if (name && this.material[name]) {
      this.material[name] = props;
    } else {
      this.material = props;
    }
  }

  setPolish(props, name?) {
    if (name && this.polish[name]) {
      this.polish[name] = props;
    } else {
      this.polish = props;
    }
  }
}
