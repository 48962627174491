import { Inject, Injectable } from '@angular/core';
import { ErrorMessageResolver, FmxErrorsService } from '@vixncu/ngx-formx';

@Injectable({ providedIn: 'root' })
export class ErrorResolversRegistrationService {

  constructor(@Inject(FmxErrorsService) private readonly controlErrorsService: FmxErrorsService) { }

  init() {
    this.controlErrorsService.registerResolvers(this.resolvers);
  }

  private readonly resolvers: Array<ErrorMessageResolver> = [
    { errorKey: 'required', getMessage: (_, controlName) => `${controlName} ist erforderlich` },
    { errorKey: 'email', getMessage: () => `E-Mail-Format ist ungültig` },
    { errorKey: 'different', getMessage: () => `Passwörter stimmen nicht überein`},
  ];
}
