import { Identifiable } from '../identifiable.model';
import { Filterable } from '../filterable.model';
import { FilterDefinition } from '../../../../commons/filter/filter-definition';

export interface CategoryFolderSelection {
  uid: string;
  name: string;
}

export class CategoryFolder implements Identifiable, Filterable {
  uid: string = null;
  keywords: Array<string> = [];
  name: string = null;
  thumbnail: string = null;
  category: string = null;

  static of(source: CategoryFolder): CategoryFolder {
    return Object.assign(new CategoryFolder(), source);
  }

  static equals(value: CategoryFolder, other: CategoryFolder): boolean {
    if (!value && !other) { return true; }
    if (!value || !other) { return false; }
    return value.uid === other.uid
      && value.name === other.name
      && value.thumbnail === other.thumbnail
      && value.category === other.category;
  }

  static withDefaults(folder?: CategoryFolder): CategoryFolder {
    folder = folder || new CategoryFolder();
    folder.uid = folder.uid || null;
    folder.keywords = folder.keywords || [];
    folder.name = folder.name || null;
    folder.thumbnail = folder.thumbnail || null;
    folder.category = folder.category || null;
    return folder;
  }

  static toSelection(folder: CategoryFolder): CategoryFolderSelection {
    return { uid: folder.uid, name: folder.name };
  }

  getFilterDefinitions(): Array<FilterDefinition> {
    return [FilterDefinition.fulltext('name')];
  }
}
