import { Inject, Injectable } from '@angular/core';
import { UtilsService } from '../three-utils/utils.service';
import { Text } from '../../../models/project/text.model';
import { MaterialService } from './material.service';
import { FirebaseStorageService } from '../../core/firebase/firebase-storage.service';
import { FontLoader, Mesh, MeshPhysicalMaterial, TextGeometry, Vector3, BufferGeometry } from 'three';
import { TextFontsFirestoreService } from '../../modules/text/services/text-fonts-firestore.service';
import { TextFont } from '../../../models/firestore/text/text-font.model';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils'
import {take} from 'rxjs/operators';


@Injectable()
export class TextsService {
  markedForDeletion: string[] = []; // UIDs marked for deletion in order to avoid loading an already deleted object

  constructor(
    @Inject(FirebaseStorageService) private readonly storage: FirebaseStorageService,
    @Inject(UtilsService) private readonly utilsService: UtilsService,
    @Inject(TextFontsFirestoreService) private readonly textFontsFirestoreService: TextFontsFirestoreService,
    @Inject(MaterialService) private readonly materialsService: MaterialService
  ) { }

  private _setLastValues(currentValues: Text) {
    let text = new Text();
    text.set(this.utilsService.createDeepClone(currentValues));
    return text;
  }

  public execute(values: Text) {
    this._node = this.utilsService.getNodeByName('texts');
    return new Promise<void>( (resolve, reject) => {
      if (this.utilsService.isEmpty(values)) {
        this.utilsService.disposeElement(this._node);
        this._lastValues = {};
        return resolve();
      }

      /*If text is newly added*/
      if (!this._lastValues.hasOwnProperty(values.uid)) {
        this._lastValues[values.uid] = this._setLastValues(values);
        return resolve(this._createText(values));
      }
      const diff = this.utilsService.intersectObjects(values, this._lastValues[values.uid]);
      if (this.utilsService.isEmpty(diff) && Object.keys(values).length !== 1) {
        return resolve();
      }
      const mesh = this.utilsService.getMeshByUID(this._node, values.uid);
      if(mesh && Object.keys(values).length === 1) {
        this._node.remove(mesh);
        return resolve();
      } else if (mesh && diff.uid) {
        this._lastValues[diff.uid] = this._setLastValues(mesh.userData);
        return resolve();
      } else if (!mesh || diff.font) {
        if (diff.font) {
          this._node.remove(mesh);
        }
        this._lastValues[values.uid] = this._setLastValues(values);
        return resolve(this._createText(values));
      } else if (diff.rows) {
        this.utilsService.disposeMaterial(mesh.material);
        this._node.remove(mesh);
        this._buildText(values, this._fontCache[values.font]);
        this._lastValues[values.uid] = this._setLastValues(values);
        resolve();
      } else if (mesh && diff.polish) {
        this._lastValues[values.uid] = this._setLastValues(values);
        this._updateMaterial(mesh, values.polish);
        resolve();
      } else if (diff.fontSize || diff.thickness || diff.rotation !== undefined) {
        this._node.remove(mesh);
        this._lastValues[values.uid] = this._setLastValues(values);
        this._buildText(values, this._fontCache[values.font]);
        resolve();
      }
  });
  }

  public update(values?) {
    this._node = this.utilsService.getNodeByName('texts');
    if (!values) {
      values = this._lastValues;
    }
    if (values && values.position) {
      const uid = values.uid ? values.uid : this._lastValues.uid;
      const mesh = this.utilsService.getMeshByUID(this._node, uid);
      if (mesh) {
        mesh.position.copy(values.position);
        mesh.lookAt(
          new Vector3(
            values.position.x + values.direction.x, values.position.y + values.direction.y, values.position.z + values.direction.z));
      }
    }
  }

  private _getFontPaths(name) {
    const fonts: Array<TextFont> = this.textFontsFirestoreService.getCached();
    for (let index = 0; index < fonts.length; index++) {
      if (fonts[index].name === name) {
        return fonts[index].src;
      }
    }
  }

  private _updateMaterial(mesh: Mesh, material: string) {
    mesh.userData.polish = material;
    this.materialsService.applyMaterial(mesh);
  }

  private _buildText(values, font) {
    this._material = new MeshPhysicalMaterial({ color: 0xaeaeae });
    const genericValues = {
      font: font,
      size: values.fontSize / 10,
      height: values.thickness,
      curveSegments: 8,
      bevelEnabled: true,
      bevelThickness: 0.16,
      bevelSize: 0.16,
      bevelOffset: 0,
      bevelSegments: 2
    };

    let offset = 0;
    let singleGeometry = new BufferGeometry();
    let geometries: Array<BufferGeometry> = []
    if (values.rows) {
      values.rows.forEach((text: string, i: number) => {
        const geometry = new TextGeometry(text, genericValues);
        if (values.alignment === 'center') {
          offset = -this.utilsService.getCenterOfGeometry(geometry).x;
        }
        if (values.alignment === 'right') {
          offset = -this.utilsService.getCenterOfGeometry(geometry).x * 2;
        }
        geometry.translate(offset, i * -values.fontSize / 10 * 1.5, 0);
        geometry.rotateZ(values.rotation * (Math.PI / 180));
        //@ts-ignore
        geometries.push(geometry)
      });
      singleGeometry = BufferGeometryUtils.mergeBufferGeometries(geometries);
    }

    const fMesh = new Mesh(singleGeometry, this._material);

    fMesh.name = 'text';
    fMesh.castShadow = true;
    fMesh.receiveShadow = true;
    fMesh.uuid = values.uid;
    fMesh.userData = values;
    this._node.add(fMesh);
    this.materialsService.applyMaterial(fMesh, '');
    this.update(values);
  }

  /*Used when new text is added or font has been changed*/
  private _createText(values) {
    return new Promise<void>((resolve, reject) => {
      document.getElementById('loadingScreen').style.display = 'block';
      const fontPath = this._getFontPaths(values.font);
      if (this._fontCache[values.font]) {
        this._buildText(values, this._fontCache[values.font]);
        document.getElementById('loadingScreen').style.display = 'none';
        this._lastValues[values.uid] = this._setLastValues(values);
        return resolve();
      }
      if (!fontPath) { // todo: fix text selection on delete
        document.getElementById('loadingScreen').style.display = 'none';
        return resolve();
      }
      this.storage.getDownloadUrl('restricted/' + fontPath)
        .pipe(
          take(1)
        )
        .subscribe(url => {
          this.loader.load(url, (font) => {
            this._fontCache[values.font] = font;
            this._buildText(values, font);
            document.getElementById('loadingScreen').style.display = 'none';
            resolve();
          });
        });
    });
  }

  private _node;
  private _lastValues: {[s: string]: Text} = {};
  private _material;
  private loader = new FontLoader();
  private _fontCache = {};
}
