import { Inject, Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { ThreeViewService } from '../../three-view.service';

@Injectable({ providedIn: 'root' })
export class ThreeJsSceneSettingsService {

  constructor(
    @Inject(LocalStorageService) private readonly localStorage$: LocalStorageService,
    @Inject(ThreeViewService) private readonly threeViewService: ThreeViewService
  ) { }

  get brightness(): number {
    return Number(this.localStorage$.retrieve('brightness')) || 0;
  }

  set brightness(value: number) {
    this.localStorage$.store('brightness', value + '');
    this.setThreeJsSceneFilter();
  }

  get contrast(): number {
    return Number(this.localStorage$.retrieve('contrast')) || 0;
  }

  set contrast(value: number) {
    this.localStorage$.store('contrast', value + '');
    this.setThreeJsSceneFilter();
  }

  get saturation(): number {
    return Number(this.localStorage$.retrieve('saturation')) || 0;
  }

  set saturation(value: number) {
    this.localStorage$.store('saturation', value + '');
    this.setThreeJsSceneFilter();
  }

  get quality(): number {
    return Number(this.localStorage$.retrieve('quality')) || 1;
  }

  set quality(value: number) {
    this.localStorage$.store('quality', value + '');
    this.threeViewService.setQuality(value);
  }

  get zoom(): number {
    return Number(this.localStorage$.retrieve('zoom')) || 100;
  }

  set zoom(value: number) {
    this.localStorage$.store('zoom', value + '');
    this.setMainUiZoom();
  }

  init(threeJs: HTMLElement, mainUi: HTMLElement) {
    this.threeJsContainer = threeJs;
    this.mainUiContainer = mainUi;
    this.setThreeJsSceneFilter();
    this.setMainUiZoom();
  }

  private static getLinearMultiplier(value: number): number {
    return 1 + (value / 100);
  }

  private setThreeJsSceneFilter() {
    this.threeJsContainer.style.filter =
      `brightness(${ThreeJsSceneSettingsService.getLinearMultiplier(this.brightness)}) ` +
      `contrast(${ThreeJsSceneSettingsService.getLinearMultiplier(this.contrast)}) ` +
      `saturate(${ThreeJsSceneSettingsService.getLinearMultiplier(this.saturation)})`;
  }

  private setMainUiZoom() {
    this.mainUiContainer.style.zoom = `${this.zoom}%`;
    let computedHeight = 100 + (100 - this.zoom);
    document.getElementById("vertical-panel").style.height = `calc(${computedHeight}vh - 64px)`;
  }

  private threeJsContainer: HTMLElement;
  private mainUiContainer: HTMLElement;
}
