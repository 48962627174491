import { Injectable } from '@angular/core';
import { UtilsService } from '../three-utils/utils.service';
import { AssetsLoadingManager } from './loader-service';
import { Color, Mesh, MeshBasicMaterial, MeshStandardMaterial, PlaneGeometry, RepeatWrapping, MeshPhysicalMaterial } from 'three';
import { MaterialService } from './material.service';
import { ThreeViewService } from '../three-view.service';
import { Ground } from '../../../models/project/ground.model';
import { Environment } from '../../../models/firestore/environments/environment.model';

@Injectable()
export class GroundService {

  public secondGround: Ground;
  constructor(
    private materialsService: MaterialService,
    private utilsService: UtilsService,
    private _loader: AssetsLoadingManager,
    private threeViewService: ThreeViewService
  ) { }

  resetEnvironment() {
    this._lastEnv = new Environment();
    this._envLoaded = null;
  }

  public execute(values: Ground) {
    this._node = this.utilsService.getNodeByName('ground');

    this.utilsService.setGroundSize(values.width, values.length, values.height);
    return new Promise<void>((resolve, reject) => {
      this._load(values);
      resolve();
    });
  }

  public executeEnv(values: Environment) {
    return new Promise<void>((resolve, reject) => {
      this._nodeEnv = this.utilsService.getNodeByName('environment');
      this._loadEnv(values).then(_ => {
        resolve();
      });

    });

  }

  public addSecondGround(){
    this._node.userData.secondaryGround = true;
    this.secondGround = this._node.children[0].clone();
    // this.secondGround['material'] = new MeshPhysicalMaterial({color: 0xff0000});
    this.materialsService.applyMaterial(this.secondGround, "secondGround");
    this._node.children[0].add(this.secondGround);
    this.secondGround['position'].set(0, 0, 0);
    this.secondGround['rotation'].set(0, 0, 0);
  }

  public removeSecondGround(){
    this.utilsService.disposeElement(this._node.children[0]);
    this.threeViewService.needToRender(10);
  }

  private _loadEnv(values) {
    return new Promise<void>((resolve, reject) => {
      if (values.name === this._lastEnv.name) {
        return;
      }
      this.threeViewService.changeEnv(values.name);

      if (!this._envLoaded) {
        this._loader.load('models/environment/dome.glb', true).then((obj) => {
          this.utilsService.disposeElement(this._nodeEnv);
          this._loader.load('models/environment/ground.png').then((map) => {
            map.wrapS = RepeatWrapping;
            map.wrapT = RepeatWrapping;
            obj.scene.children[1].receiveShadow = true;
            obj.scene.children[1].material = new MeshStandardMaterial({
              map: map,
              transparent: false,
              opacity: 1,
              roughness: 1.0,
              metalness: 0.0,
              color: new Color('#dbdbdb')
            });
            obj.scene.position.y = -0.01;
            this._nodeEnv.add(obj.scene);
            this.threeViewService.animate();
            this._envLoaded = obj.scene.children[1].material;
            resolve();
          });
        });
      }

      this._lastEnv = values;
    });

  }

  private _load(values) {
    if (this._node.children.length === 0) {
      const groundGeom = new PlaneGeometry(100, 100);
      const geomMat = new MeshStandardMaterial({
        color: 0x000000,
        roughness: 0.95,
        metalness: 0.0,
        transparent: true,
        opacity: 0.05
      });
      const ground = new Mesh(groundGeom, geomMat);
      const groundShadow = new Mesh(new PlaneGeometry(100, 100), new MeshBasicMaterial({ color: 0x4f505f, wireframe: true }));
      groundShadow.position.set(0, 0, -0.16);

      ground.rotation.x = -Math.PI / 2;

      ground.receiveShadow = true;
      ground.castShadow = true;
      this._node.add(ground);
      // this.materialsService.applyMaterial(this._node.children[0], "ground");
    }

    this._update(values);

  }

  private _update(values) {
    if (this._node.children.length === 0) {
      return;
    }
    if (this.utilsService.isEmpty(values)) {
      return;
    }
    if (typeof values.width === 'number') {
      this._node.children[0].scale.x = values.width / 100;
    }
    if (typeof values.length === 'number') {
      this._node.children[0].scale.y = values.length / 100;
      this._node.children[0].position.z = values.length / 2;
    }
    if (typeof values.height === "number" && this._node.children[0].children[0]) {
      this._node.children[0].children[0].position.z = values.height;
    }
    this.utilsService.setGroundSize(values.width, values.length, values.height);
    this._node.userData = values;

  }

  private _node;
  private _nodeEnv;
  private _lastEnv = new Environment();
  private _envLoaded;
}
