import { Inject, Injectable } from '@angular/core';
import { FirestoreService } from '../../../core/firebase/firestore/firestore.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Environment } from '../../../../models/firestore/environments/environment.model';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentFirestoreService extends FirestoreService<Environment> {

  constructor(@Inject(AngularFirestore) protected readonly firestore: AngularFirestore) { super(firestore); }

  protected get path(): string {
    return 'environments';
  }
}
