import { Inject, Injectable } from '@angular/core';
import { AbstractLocalStorageService } from '../services/abstract-local-storage.service';
import { LocalStorageService } from 'ngx-webstorage';
import { AppUser } from '../../modules/user/data/user.model';

@Injectable({ providedIn: 'root' })
export class UserStorageService extends AbstractLocalStorageService<AppUser> {
  constructor(@Inject(LocalStorageService) protected readonly localStorageService: LocalStorageService) {
    super(localStorageService, 'appUser');
  }
}
