import {Inject, Injectable} from '@angular/core';
import { AssetsLoadingManager } from './loader-service';
import { MaterialService } from './material.service';
import { UtilsService } from '../three-utils/utils.service';
import { MeasurementsService } from './measurements.service';
import { Plate } from '../../../models/firestore/categories/plate/plate.model';
import {Corner} from '../../../models/firestore/categories/plate/corner.enum';
import {AbstractObject3DService} from './abstract-object3d.service';
import {ModelUtils} from '../../../models/project/model.utils';
import {Subject} from 'rxjs';
import {FirestoreService} from '../../core/firebase/firestore/firestore.service';
import {PlateItemsFirestoreService} from '../../modules/plate/services/plate-items-firestore.service';

/**
 * Plate service extends Object3D service, however
 * it doesn't use the objectRef field, using _plates instead
 */
@Injectable()
export class PlateService extends AbstractObject3DService<Plate> {
  /**@refactor*/
  public readonly plateLoaded: Subject<void> = new Subject<void>();
  /**@refactor*/
  public readonly plateDeleted: Subject<void> = new Subject<void>();

  constructor(
      @Inject(MaterialService) protected materialsService: MaterialService,
      @Inject(PlateItemsFirestoreService) protected firestoreService: PlateItemsFirestoreService,
      @Inject(AssetsLoadingManager) protected _loader: AssetsLoadingManager,
      @Inject(UtilsService) protected utilsService: UtilsService,
      @Inject(MeasurementsService) protected measurementsService: MeasurementsService) {
    super(utilsService, materialsService, _loader, Plate, 'plate');
  }

  public requestUpdate(updateValues: Partial<Plate>, simpleUpdate: boolean = false): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if(simpleUpdate || !updateValues) {
        this._updateAll();
        return resolve();
      }
      this._objectRef = this.getPlate(updateValues.corner);
      if(!this._objectRef) {return resolve();}
      const diff = this.utilsService.intersectObjects(this._objectRef, updateValues);
      if (!Object.keys(diff).length) {
        return resolve();
      }
      if (diff.appearance) {
        this._applyMaterial();
      }
      if (diff.thickness || diff.size || diff.innerPlacement || diff.corner || diff.dimensionLines) {
        this._update(updateValues);
      }
      resolve();
    });
  }

  public addPlate(values: Plate, forceCorner?: boolean) {
    if(forceCorner) {
      this.selectedCorner = values.corner;
      this.purge();
    }
    const currentPlate = this.getPlate(this.selectedCorner);
    const plateToAdd = new Plate();
    plateToAdd.set({...values, corner: this.selectedCorner === Corner.NONE ? values.corner : this.selectedCorner});
    if(!plateToAdd.uid) {
      plateToAdd.uid = this.firestoreService.createId();
    }
    this._plates.push(plateToAdd);
    this.plateLoaded.next();
    this._createObject3D(plateToAdd.src).then(obj => {
      if(!this._plates.includes(plateToAdd)) {return;}
      obj.traverse(child => {
        child.userData = this.utilsService.createDeepClone(plateToAdd);
      });
      this.removePlate(currentPlate, false);
      this._node.add(obj);
      this._updateAll();
    });
  }

  public removePlateFromCorner(corner: Corner) {
    const plate = this.getPlate(corner);
    this.removePlate(plate);
    this.plateLoaded.next();
  }

  public removePlate(plate: Plate, forwardEvent: boolean = true) {
    if(plate instanceof Plate) {
      this._node.children = this._node.children.filter(child => {
        if (child.userData.uid !== plate.uid) {
          return true;
        } else {
          this.utilsService.disposeElement(child);
          return false;
        }
      });
      this._plates.splice(this._plates.indexOf(plate), 1);
      if(forwardEvent) {
        this.plateDeleted.next();
      }
      this.needsRender.next();
    }
  }

  public purge() {
    this.removeFromScene();
    this._plates = [];
    this.selectedCorner = Corner.NONE;
    this.plateLoaded.next();
    this.plateDeleted.next();
  }

  public updateMeasurements() {
    this._node.updateMatrixWorld();
    this.measurementsService.drawPlateMeasurements(this._node);
    this.needsRender.next();
  }

  public changeAppearance(updateValues: {appearance: {material?: string, polish?: string}, corner: Corner}) {
    const plate = this.getPlate(updateValues.corner);
    if(plate) {
      plate.appearance.material = updateValues.appearance.material ?? plate.appearance.material;
      plate.appearance.polish = updateValues.appearance.polish ?? plate.appearance.polish;
      this._updateAll();
      this._applyMaterial();
    }
  }

  /**
   * If a plate is fixed dimensions should not be manually editable
   * @param corner
   */
  public canBeEdited(corner: Corner): boolean {
    const plate = this.getPlate(corner);
    if(!plate) {return false;}
    return !plate.fixed;
  }

  /**
   * Exclusive for admin page
   * Needed to change corner in admin edit preview
   * @param corner -> new corner
   */
  public adminChangeCorner(corner: Corner) {
    if(this._plates.length) {
      this._plates[0].corner = corner;
      this._node.children[0].traverse(child => {
        child.userData.corner = corner;
      })
      this._updateAll();
    }
  }

  /**
   * Converts and loads old project's plates
   * this needs to go
   * @param values
   */
  public loadOld(values: any) {
    if(typeof values.src !== 'string') {
      for (let corner of Object.keys(values.corners)) {
        let plate = new Plate();
        plate.set({
          ...plate,
          src: values.src[corner],
          size: values.size[corner],
          innerPlacement: values.innerPlacement[corner],
          corner: corner,
          appearance: {
            material: values.appearance?.material[corner],
            polish: values.appearance?.polish[corner]
          },
          name: values.corners[corner],
          thickness: values.thickness[corner],
        });
        this.selectedCorner = Corner.NONE;
        this.addPlate(plate);
      }
    } else {
      for (let corner of Object.keys(values.corners)) {
        let plate = new Plate();
        plate.set({
          ...plate,
          src: values.src,
          size: values.size,
          innerPlacement: values.innerPlacement,
          corner: corner,
          appearance: {
            material: Object.values(values.material).length > 0 ? Object.values(values.material)[0] : 'Aura',
            polish: Object.values(values.polish).length > 0 ? Object.values(values.polish)[0] : 'Poliert'
          },
          name: values.name,
          thickness: values.thickness,
        });
        this.selectedCorner = Corner.NONE;
        this.addPlate(plate);
      }
    }
  }

  protected _update(updateValues: Partial<Plate>) {
    if(updateValues instanceof Plate) {
      this._objectRef = this.utilsService.createDeepClone(updateValues);
    } else {
      Object.keys(updateValues).forEach(key => {
        if(updateValues[key] !== undefined) {
          this._objectRef[key] = updateValues[key];
        }
      })
    }
    this._updateAll();
  }

  /**
   * Updates scene objects based on _plates
   */
  private _updateAll() {
    for (let index = 0; index < this._node.children.length; index++) {
      this._node.children[index].traverse(child => {
        child.userData = this.utilsService.createDeepClone(this.getPlate(child.userData.corner));
      });
      this.pushInCorner(this._node.children[index].userData, this._node.children[index]);
    }
    this.updateMeasurements();
    this.needsRender.next();
  }

  /**
   * One day this will get fixed
   */
  private pushInCorner(values, model) {
    const transforms = {
      ground: this.utilsService.getGroundSize(),
      border: this.utilsService.getBorderSize()
    };
    const bbox = this.utilsService.getDimensionsFromBBox(model);
    const bboxOriginal = this.utilsService.getOriginalDimensionsFromBBox(model);
    let plateElevation = 0;
    if (transforms.border && transforms.border.borderHeight > 0) {
      plateElevation = transforms.border.borderHeight + values.thickness * values.innerPlacement;
    } else if (transforms.ground.height > 0) {
      plateElevation = transforms.ground.height + values.thickness;
    } else {
      plateElevation = values.thickness ? values.thickness : bbox.height;
    }

    const tempValues = this.utilsService.currentBorderValues;

    let scaleX = 1, scaleZ = 1;
    switch (values.corner) {
      case 'top':
        let z = 0;
        if (tempValues) {
          switch (tempValues.category) {
            case 'editable-1':
            case 'editable-3':
            case 'editable-4':
            case 'editable-6':
              z = transforms.border.borderTickness * (values.innerPlacement == 0 ? 1 : 0);
              break;
            case 'editable-2':
              z = transforms.border.monumentHolderLength;
              break;
            case 'editable-5':
              z = 0;
              break;
          }
        }
        if (values.size <= 10) {
          values.size = 10;
        }
        this._handleModelTransforms(model, {
          x: 0,
          z: z,
          y: plateElevation,
          sx: (transforms.ground.width - 2 * transforms.border.borderTickness + 2 * values.innerPlacement *
            transforms.border.borderTickness) / bbox.width,
          sz: values.size ? -values.size / bboxOriginal.length : -1,
          sy: values.thickness ? values.thickness / 4 : model.scale.y,
          ry: 0
        });
        break;
      case 'bottom':
        this._handleModelTransforms(model, {
          x: 0,
          z: transforms.ground.length - transforms.border.borderTickness * (values.innerPlacement == 0 ? 1 : 0),
          y: plateElevation,
          sx: -(transforms.ground.width - 2 * transforms.border.borderTickness + 2 * values.innerPlacement *
            transforms.border.borderTickness) / bbox.width,
          sz: values.size ? values.size / bboxOriginal.length : 1,
          sy: values.thickness ? values.thickness / 4 : model.scale.y,
          ry: 0
        });
        break;
      case 'top-left':
        if (transforms.ground.width < transforms.ground.length) {
          scaleX = tempValues && tempValues.category === 'editable-2' ? bbox.width > transforms.ground.width -
            (transforms.border.monumentHolderLength +
              transforms.border.borderTickness) ? (transforms.ground.width -
                (transforms.border.monumentHolderLength + transforms.border.borderTickness)) / bbox.width : 1 : bbox.width >
                  transforms.ground.width - 2 *
                  transforms.border.borderTickness
              ? (transforms.ground.width - 2 *
                transforms.border.borderTickness) / bbox.width
              : 1;
          scaleZ = scaleX;
        } else {
          scaleZ = tempValues && tempValues.category === 'editable-2' ? bbox.length > transforms.ground.length -
            (transforms.border.monumentHolderLength +
              transforms.border.borderTickness) ? (transforms.ground.length -
                (transforms.border.monumentHolderLength + transforms.border.borderTickness)) / bbox.length : 1 : bbox.length >
                  transforms.ground.length - 2 *
                  transforms.border.borderTickness
              ? (transforms.ground.length -
                2 * transforms.border.borderTickness) / bbox.length
              : 1;
          scaleX = scaleZ;
        }
        let z2 = 0;
        if (tempValues) {
          switch (tempValues.category) {
            case 'editable-1':
            case 'editable-3':
            case 'editable-4':
            case 'editable-6':
              z2 = transforms.border.borderTickness * (values.innerPlacement == 0 ? 1 : 0);
              break;
            case 'editable-2':
              z2 = transforms.border.monumentHolderLength;
              break;
            case 'editable-5':
              z2 = 0;
              break;
          }
        }

        if (values.size) {
          scaleX = scaleZ = values.size / bboxOriginal.width;
        }
        this._handleModelTransforms(model, {
          x: -transforms.ground.width / 2 + transforms.border.borderTickness * (values.innerPlacement == 0 ? 1 : 0),
          y: plateElevation,
          z: z2,
          sx: scaleX,
          sz: scaleZ,
          sy: values.thickness ? values.thickness / 4 : model.scale.y,
          ry: -Math.PI
        });
        break;
      case 'top-right':
        let z3 = 0;
        if (tempValues) {
          switch (tempValues.category) {
            case 'editable-1':
            case 'editable-3':
            case 'editable-4':
            case 'editable-6':
              z3 = transforms.border.borderTickness * (values.innerPlacement == 0 ? 1 : 0);
              break;
            case 'editable-2':
              z3 = transforms.border.monumentHolderLength;
              break;
            case 'editable-5':
              z3 = 0;
              break;
          }
        }
        if (transforms.ground.width < transforms.ground.length) {
          scaleX = tempValues && tempValues.category === 'editable-2' ? bbox.width > transforms.ground.width -
            (transforms.border.monumentHolderLength +
              transforms.border.borderTickness) ? (transforms.ground.width -
                (transforms.border.monumentHolderLength + transforms.border.borderTickness)) / bbox.width : 1 : bbox.width >
                  transforms.ground.width - 2 *
                  transforms.border.borderTickness
              ? (transforms.ground.width - 2 *
                transforms.border.borderTickness) / bbox.width
              : 1;
          scaleZ = scaleX;
        } else {
          scaleZ = tempValues && tempValues.category === 'editable-2' ? bbox.length > transforms.ground.length -
            (transforms.border.monumentHolderLength +
              transforms.border.borderTickness) ? (transforms.ground.length -
                (transforms.border.monumentHolderLength + transforms.border.borderTickness)) / bbox.length : 1 : bbox.length >
                  transforms.ground.length - 2 *
                  transforms.border.borderTickness
              ? (transforms.ground.length -
                2 * transforms.border.borderTickness) / bbox.length
              : 1;
          scaleX = scaleZ;
        }

        if (values.size) {
          scaleX = scaleZ = values.size / bboxOriginal.width;
        }
        this._handleModelTransforms(model, {
          x: transforms.ground.width / 2 - transforms.border.borderTickness * (values.innerPlacement == 0 ? 1 : 0),
          y: plateElevation,
          z: z3,
          sx: scaleX,
          sz: scaleZ,
          sy: values.thickness ? values.thickness / 4 : model.scale.y,
          ry: Math.PI / 2
        });
        break;
      case 'bottom-left':
        if (transforms.ground.width < transforms.ground.length) {
          scaleX = tempValues && tempValues.category === 'editable-2' ? bbox.width > transforms.ground.width -
            (transforms.border.monumentHolderLength +
              transforms.border.borderTickness) ? (transforms.ground.width -
                (transforms.border.monumentHolderLength + transforms.border.borderTickness)) / bbox.width : 1 : bbox.width >
                  transforms.ground.width - 2 *
                  transforms.border.borderTickness
              ? (transforms.ground.width - 2 *
                transforms.border.borderTickness) / bbox.width
              : 1;
          scaleZ = scaleX;
        } else {
          scaleZ = tempValues && tempValues.category === 'editable-2' ? bbox.length > transforms.ground.length -
            (transforms.border.monumentHolderLength +
              transforms.border.borderTickness) ? (transforms.ground.length -
                (transforms.border.monumentHolderLength + transforms.border.borderTickness)) / bbox.length : 1 : bbox.length >
                  transforms.ground.length - 2 *
                  transforms.border.borderTickness
              ? (transforms.ground.length -
                2 * transforms.border.borderTickness) / bbox.length
              : 1;
          scaleX = scaleZ;
        }

        if (values.size) {
          scaleX = scaleZ = values.size / bboxOriginal.width;
        }

        this._handleModelTransforms(model, {
          x: -transforms.ground.width / 2 + transforms.border.borderTickness * (values.innerPlacement == 0 ? 1 : 0),
          y: plateElevation,
          z: transforms.ground.length - transforms.border.borderTickness * (values.innerPlacement == 0 ? 1 : 0),
          sx: scaleX,
          sz: scaleZ,
          sy: values.thickness ? values.thickness / 4 : model.scale.y,
          ry: -Math.PI / 2
        });
        break;
      case 'bottom-right':
        if (transforms.ground.width < transforms.ground.length) {
          scaleX = tempValues && tempValues.category === 'editable-2' ? bbox.width > transforms.ground.width -
            (transforms.border.monumentHolderLength +
              transforms.border.borderTickness) ? (transforms.ground.width -
                (transforms.border.monumentHolderLength + transforms.border.borderTickness)) / bbox.width : 1 : bbox.width >
                  transforms.ground.width - 2 *
                  transforms.border.borderTickness
              ? (transforms.ground.width - 2 *
                transforms.border.borderTickness) / bbox.width
              : 1;
          scaleZ = scaleX;
        } else {
          scaleZ = tempValues && tempValues.category === 'editable-2' ? bbox.length > transforms.ground.length -
            (transforms.border.monumentHolderLength +
              transforms.border.borderTickness) ? (transforms.ground.length -
                (transforms.border.monumentHolderLength + transforms.border.borderTickness)) / bbox.length : 1 : bbox.length >
                  transforms.ground.length - 2 *
                  transforms.border.borderTickness
              ? (transforms.ground.length -
                2 * transforms.border.borderTickness) / bbox.length
              : 1;
          scaleX = scaleZ;
        }

        if (values.size) {
          scaleX = scaleZ = values.size / bboxOriginal.width;
        }
        this._handleModelTransforms(model, {
          x: transforms.ground.width / 2 - transforms.border.borderTickness * (values.innerPlacement == 0 ? 1 : 0),
          y: plateElevation,
          z: transforms.ground.length - transforms.border.borderTickness * (values.innerPlacement == 0 ? 1 : 0),
          sx: scaleX,
          sz: scaleZ,
          sy: values.thickness ? values.thickness / 4 : model.scale.y,
          ry: 0
        });
        break;
      case '60x100':
      case 'fixed':
      case 'standard':
      case 'full':
        if (values.fixed) {
          let mhL = 0;
          if (tempValues) {
            switch (tempValues.category) {
              case 'editable-1':
              case 'editable-3':
              case 'editable-4':
                mhL = transforms.border.borderTickness * (values.innerPlacement == 0 ? 1 : 0);
                break;
              case 'editable-2':
                mhL = transforms.border.monumentHolderLength;
                break;
              case 'editable-5':
                mhL = 0;
                break;
            }
          }

          this._handleModelTransforms(model, {
            x: 0,
            y: plateElevation,
            z: mhL,
            sx: 1,
            sz: 1,
            sy: values.thickness ? values.thickness / 4 : model.scale.y,
            rx: 0,
            ry: 0,
            rz: 0
          });
          break;
        }
        let mhL2 = 0;
        let szL = (transforms.ground.length - 2 * transforms.border.borderTickness + values.innerPlacement *
          transforms.border.borderTickness) / bboxOriginal.length;
        if (tempValues) {
          switch (tempValues.category) {
            case 'editable-1':
              mhL2 = transforms.border.borderTickness * (values.innerPlacement == 0 ? 1 : 0);
              szL = (transforms.ground.length - 2 * transforms.border.borderTickness * (values.innerPlacement == 0 ? 1 : 0)) /
                bboxOriginal.length;
              break;
            case 'editable-3':
            case 'editable-4':
            case 'editable-6':
              mhL2 = transforms.border.borderTickness * (values.innerPlacement == 0 ? 1 : 0);
              szL = (transforms.ground.length - 2 * transforms.border.borderTickness + 2 * values.innerPlacement *
                transforms.border.borderTickness) / bboxOriginal.length;
              break;
            case 'editable-2':
              mhL2 = transforms.border.monumentHolderLength;
              szL = (transforms.ground.length - transforms.border.borderTickness - transforms.border.monumentHolderLength +
                values.innerPlacement * transforms.border.borderTickness) / bboxOriginal.length;
              break;
            case 'editable-5':
              mhL2 = 0;
              szL = (transforms.ground.length - transforms.border.borderTickness + values.innerPlacement *
                transforms.border.borderTickness) / bboxOriginal.length;
              break;
          }
        }

        this._handleModelTransforms(model, {
          x: 0,
          y: plateElevation,
          z: mhL2,
          sx: ((transforms.ground.width - 2 * transforms.border.borderTickness + 2 * values.innerPlacement *
            transforms.border.borderTickness) / bbox.width),
          sz: szL,
          sy: values.thickness ? values.thickness / 4 : model.scale.y,
          rx: 0,
          ry: 0,
          rz: 0
        });
        break;
    }
    this._applyMaterial();
    model.userData = values;
  }

  private _handleModelTransforms(model, transforms) {
    if (typeof transforms.x === 'number') { model.position.x = transforms.x; } else { model.position.x = 0; }
    if (typeof transforms.y === 'number') { model.position.y = transforms.y; } else { model.position.y = 0; }
    if (typeof transforms.z === 'number') { model.position.z = transforms.z; } else { model.position.z = 0; }
    if (typeof transforms.sx === 'number') { model.scale.x = transforms.sx; } else { model.scale.x = 1; }
    if (typeof transforms.sy === 'number') { model.scale.y = transforms.sy; } else { model.scale.y = 1; }
    if (typeof transforms.sz === 'number') { model.scale.z = transforms.sz; } else { model.scale.z = 1; }
    if (typeof transforms.rx === 'number') { model.rotation.x = transforms.rx; } else { model.rotation.x = 0; }
    if (typeof transforms.ry === 'number') { model.rotation.y = transforms.ry; } else { model.rotation.y = 0; }
    if (typeof transforms.rz === 'number') { model.rotation.z = transforms.rz; } else { model.rotation.z = 0; }
  }

  /**
   * Returns existing corners
   * Needed for PlateCornerGridComponent
   */
  getActiveCorners(): Array<Corner> {
    let tempArray = [];
    for(let plate of this._plates) {
      tempArray.push(plate.corner);
    }
    return tempArray;
  }

  /**
   * Get plate from specific corner, returns undefined if corner is empty
   * @param corner -> corner to look in, should be of type Corner enum
   */
  getPlate(corner: Corner): Plate | undefined {
    for(let plate of this._plates) {
      if(plate.corner === corner) {
        return plate;
      }
    }
    return undefined;
  }

  getPlatesCopy() {
    return [...this._plates];
  }

  get selectedCorner() {
    return this._selectedCorner;
  }

  set selectedCorner(value: Corner) {
    this._selectedCorner = value;
  }

  private _plates: Array<Plate> = [];
  private _debouncer = setTimeout(() => { }, 100);
  private _selectedCorner: Corner = Corner.NONE;  // default value from corner position component
}


