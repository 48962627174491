import {Inject, Injectable} from '@angular/core';
import { AssetsLoadingManager } from './loader-service';
import { MaterialService } from './material.service';
import { UtilsService } from '../three-utils/utils.service';
import { MeasurementsService } from './measurements.service';
import { Border } from '../../../models/firestore/categories/border/border.model';
import {AbstractObject3DService} from './abstract-object3d.service';
import {PlateItemsFirestoreService} from '../../modules/plate/services/plate-items-firestore.service';
import {Plate} from '../../../models/firestore/categories/plate/plate.model';
import {BorderItemsFirestoreService} from '../../modules/ground/services/border-items-firestore.service';
import {group} from '@angular/animations';

/**
 * Border service still needs to be changed a bit
 * this.project.border ref needs to go
 */
@Injectable()
export class BorderGeneratedService extends AbstractObject3DService<Border>{
  constructor(
    @Inject(MaterialService) protected materialsService: MaterialService,
    @Inject(BorderItemsFirestoreService) protected firestoreService: BorderItemsFirestoreService,
    @Inject(AssetsLoadingManager) protected _loader: AssetsLoadingManager,
    @Inject(UtilsService) protected utilsService: UtilsService,
    @Inject(MeasurementsService) protected measurementsService: MeasurementsService) {
    super(utilsService, materialsService, _loader, Border, 'border');
  }

  public requestUpdate(updateValues: Partial<Border>): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if(!this._objectRef || !updateValues) {return resolve();}
      this.utilsService.setBorderSize(updateValues);
      this._update(updateValues);
      this.needsRender.next();
      resolve();
    });
  }

  public addBorder(values: Border) {
    if(this._objectRef) {
      this.purge();
    }
    this.utilsService.setBorderSize(values);
    const borderToAdd = new Border();
    borderToAdd.set({...values, uid: this.firestoreService.createId()});
    this._loader.load(borderToAdd.src).then((obj) => {
      if(this._objectRef) { // check again to prevent multiple borders if another was added in the meantime
        this.purge();
      }
      obj.traverse(child => {
        child.userData = this.utilsService.createDeepClone(borderToAdd);
      });
      this._node.add(obj);
      this._update(borderToAdd);
      this._applyMaterial();
      this._objectRef = borderToAdd;
    });
  }

  public purge() {
    this.measurementsService.drawBorderMeasurements(this._node, true);
    this.removeFromScene();
    this._objectRef = undefined;
    this.utilsService.setDefaultBorderSize();
    this.needsRender.next();
  }

  public updateMeasurements() {
    this._node.updateMatrixWorld();
    this.measurementsService.drawBorderMeasurements(this._node);
  }

  public changeAppearance(updateValues: {appearance: {material?: string, polish?: string}, meshName: string}) {
    const groupIndex = this._findMeshInGroup(updateValues.meshName);
    let tempMeshes: Array<string>;
    if(groupIndex !== -1) {
      tempMeshes = [...this._objectRef.groups[groupIndex].models];
    } else {
      tempMeshes = [updateValues.meshName];
    }
    for(let tempMesh of tempMeshes) {
      const mesh = this.utilsService.getMeshByName(tempMesh, this._node);
      if(mesh) {
        mesh.userData.material[tempMesh] = updateValues.appearance.material ?? mesh.userData.material[tempMesh];
        this._objectRef.material[tempMesh] = mesh.userData.material[tempMesh];
      }
    }
    if(updateValues.appearance.polish) {
      const mesh = this.utilsService.getMeshByName(updateValues.meshName, this._node);
      if(mesh) {
        mesh.userData.polish[updateValues.meshName] = updateValues.appearance.polish;
        this._objectRef.polish[updateValues.meshName] = updateValues.appearance.polish;
      }
    }
    this._node.userData.material = this.utilsService.createDeepClone(this._objectRef.material);
    this._node.userData.polish = this.utilsService.createDeepClone(this._objectRef.polish);
    this._applyMaterial();
  }

  /**
   * Kind of a big mess
   * Updates values in objectRef and passes them on to node and children
   * We keep it as is for now
   * @param values
   */
  protected _update(values: Partial<Border>) {
    this._node = this.utilsService.getNodeByName('border');
    if (this._node.children.length === 0) {
      return;
    }

    if(values instanceof Border) {
      this._objectRef = this.utilsService.createDeepClone(values);
    } else {
      Object.keys(values).forEach(key => {
        if (values[key] !== undefined) {
          this._objectRef[key] = values[key];
        }
      })
    }
    this._node.traverse(child => {
      child.userData = this.utilsService.createDeepClone(this._objectRef);
    })

    const groundSize = this.utilsService.getGroundSize();
    let models = this._getMeshes();
    switch (this._objectRef.category) {
      case 'editable-1':
        this.utilsService.borderSize.monumentHolderWidth = groundSize.width - 2 * this._objectRef.borderTickness < this._objectRef.monumentHolderWidth
          ? (groundSize.width - this._objectRef.borderTickness * 2) + 1
          : this._objectRef.monumentHolderWidth;
        if (this.utilsService.borderSize.monumentHolderWidth <= 0) {
          this.utilsService.borderSize.monumentHolderWidth = 10;
        }
        this.resize(models.frontModel, { sy: this._objectRef.borderHeight, sx: groundSize.width, sz: this._objectRef.borderTickness });
        this.resize(
          models.monumentHolderModel, {
            sy: this._objectRef.monumentHolderHeight,
            sx: this.utilsService.borderSize.monumentHolderWidth,
            sz: this._objectRef.monumentHolderLength,
            z: this._objectRef.borderTickness
          });
        this.resize(
          models.leftModel, {
            x: -groundSize.width / 2,
            z: this._objectRef.borderTickness,
            sx: groundSize.length - 2 * this._objectRef.borderTickness,
            sy: this._objectRef.borderHeight,
            sz: this._objectRef.borderTickness
          });
        this.resize(
          models.rightModel, {
            x: groundSize.width / 2,
            z: this._objectRef.borderTickness,
            sx: groundSize.length - 2 * this._objectRef.borderTickness,
            sy: this._objectRef.borderHeight,
            sz: this._objectRef.borderTickness
          });
        this.resize(models.backModel, { z: groundSize.length, sy: this._objectRef.borderHeight, sx: groundSize.width, sz: this._objectRef.borderTickness });
        break;
      case 'editable-2':
        // this._objectRef.monumentHolderWidth = 0;
        this.resize(models.monumentHolderModel, { sy: this._objectRef.monumentHolderHeight, sx: groundSize.width, sz: this._objectRef.monumentHolderLength });
        this.resize(
          models.leftModel, {
            x: -groundSize.width / 2,
            z: this._objectRef.monumentHolderLength,
            sx: groundSize.length - this._objectRef.borderTickness - this._objectRef.monumentHolderLength,
            sy: this._objectRef.borderHeight,
            sz: this._objectRef.borderTickness
          });
        this.resize(
          models.rightModel, {
            x: groundSize.width / 2,
            z: this._objectRef.monumentHolderLength,
            sx: groundSize.length - this._objectRef.borderTickness - this._objectRef.monumentHolderLength,
            sy: this._objectRef.borderHeight,
            sz: this._objectRef.borderTickness
          });
        this.resize(models.backModel, { z: groundSize.length, sy: this._objectRef.borderHeight, sx: groundSize.width, sz: this._objectRef.borderTickness });
        break;
      case 'editable-3':
        this.utilsService.borderSize.monumentHolderWidth = groundSize.width - 2 * this._objectRef.borderTickness < this._objectRef.monumentHolderWidth
          ? (groundSize.width - this._objectRef.borderTickness * 2) + 1
          : this._objectRef.monumentHolderWidth;
        if (this.utilsService.borderSize.monumentHolderWidth <= 0) {
          this.utilsService.borderSize.monumentHolderWidth = 10;
        }
        this.resize(
          models.leftModel, {
            x: -groundSize.width / 2,
            z: this._objectRef.borderTickness,
            sx: groundSize.length - 2 * this._objectRef.borderTickness,
            sy: this._objectRef.borderHeight,
            sz: this._objectRef.borderTickness
          });
        this.resize(
          models.rightModel, {
            x: groundSize.width / 2,
            z: this._objectRef.borderTickness,
            sx: groundSize.length - 2 * this._objectRef.borderTickness,
            sy: this._objectRef.borderHeight,
            sz: this._objectRef.borderTickness
          });
        this.resize(
          models.monumentHolderModel,
          { sy: this._objectRef.monumentHolderHeight, sx: this.utilsService.borderSize.monumentHolderWidth, sz: this._objectRef.monumentHolderLength }
        );
        this.resize(
          models.frontLeftModel, {
            x: -groundSize.width / 2,
            sy: this._objectRef.borderHeight,
            sx: groundSize.width / 2 - this.utilsService.borderSize.monumentHolderWidth / 2,
            sz: this._objectRef.borderTickness
          });
        this.resize(
          models.frontRightModel, {
            x: groundSize.width / 2,
            sy: this._objectRef.borderHeight,
            sx: groundSize.width / 2 - this.utilsService.borderSize.monumentHolderWidth / 2,
            sz: this._objectRef.borderTickness
          });
        this.resize(models.backModel, { z: groundSize.length, sy: this._objectRef.borderHeight, sx: groundSize.width, sz: this._objectRef.borderTickness });
        break;
      case 'editable-4':
        this.utilsService.borderSize.monumentHolderWidth = groundSize.width - 2 * this._objectRef.borderTickness < this._objectRef.monumentHolderWidth
          ? (groundSize.width - this._objectRef.borderTickness * 2) + 1
          : this._objectRef.monumentHolderWidth;
        if (this.utilsService.borderSize.monumentHolderWidth <= 0) {
          this.utilsService.borderSize.monumentHolderWidth = 10;
        }
        this.resize(models.frontModel, { sy: this._objectRef.borderHeight, sx: groundSize.width, sz: this._objectRef.borderTickness });
        // this.resize(models.monumentHolderModel, { sy: this._objectRef.monumentHolderHeight, sx: this.utilsService.borderSize.monumentHolderWidth, sz: this._objectRef.monumentHolderLength, z: this._objectRef.borderTickness });
        this.resize(
          models.leftModel, {
            x: -groundSize.width / 2,
            z: this._objectRef.borderTickness,
            sx: groundSize.length - 2 * this._objectRef.borderTickness,
            sy: this._objectRef.borderHeight,
            sz: this._objectRef.borderTickness
          });
        this.resize(
          models.rightModel, {
            x: groundSize.width / 2,
            z: this._objectRef.borderTickness,
            sx: groundSize.length - 2 * this._objectRef.borderTickness,
            sy: this._objectRef.borderHeight,
            sz: this._objectRef.borderTickness
          });
        this.resize(models.backModel, { z: groundSize.length, sy: this._objectRef.borderHeight, sx: groundSize.width, sz: this._objectRef.borderTickness });
        // this.utilsService.borderSize.monumentHolderWidth = groundSize.width - 2 * this._objectRef.borderTickness < this._objectRef.monumentHolderWidth ? groundSize.width - this._objectRef.borderTickness * 2 : this._objectRef.monumentHolderWidth;
        // if (this.utilsService.borderSize.monumentHolderWidth <= 0) this.utilsService.borderSize.monumentHolderWidth = 10;
        // this._objectRef.monumentHolderWidth = groundSize.width;
        // this._objectRef.monumentHolderLength = groundSize.length;
        // this.resize(models.monumentHolderModel, { sy: this._objectRef.monumentHolderHeight, sx: groundSize.width, sz: groundSize.length });
        break;
      case 'editable-5':
        this.resize(
          models.leftModel, {
            x: -groundSize.width / 2,
            z: 0,
            sx: groundSize.length - this._objectRef.borderTickness,
            sy: this._objectRef.borderHeight,
            sz: this._objectRef.borderTickness
          });
        this.resize(
          models.rightModel, {
            x: groundSize.width / 2,
            z: 0,
            sx: groundSize.length - this._objectRef.borderTickness,
            sy: this._objectRef.borderHeight,
            sz: this._objectRef.borderTickness
          });
        this.resize(models.backModel, { z: groundSize.length, sy: this._objectRef.borderHeight, sx: groundSize.width, sz: this._objectRef.borderTickness });

        // this.utilsService.borderSize.monumentHolderWidth = groundSize.width - 2 * this._objectRef.borderTickness < this._objectRef.monumentHolderWidth ? groundSize.width - this._objectRef.borderTickness * 2 : this._objectRef.monumentHolderWidth;
        // if (this.utilsService.borderSize.monumentHolderWidth <= 0) this.utilsService.borderSize.monumentHolderWidth = 10;
        // this.resize(models.monumentHolderModel, { sy: this._objectRef.monumentHolderHeight, sx: this.utilsService.borderSize.monumentHolderWidth, sz: this._objectRef.monumentHolderLength, z: 0 });
        // this._objectRef.borderTickness = 0;
        // this._objectRef.borderHeight = 0;
        break;
      case 'editable-6':
        this.utilsService.borderSize.monumentHolderWidth = groundSize.width - 2 * this._objectRef.borderTickness < this._objectRef.monumentHolderWidth
          ? (groundSize.width - this._objectRef.borderTickness * 2) + 1
          : this._objectRef.monumentHolderWidth;
        if (this.utilsService.borderSize.monumentHolderWidth <= 0) {
          this.utilsService.borderSize.monumentHolderWidth = 10;
        }
        this.resize(
          models.leftModel, {
            x: -groundSize.width / 2,
            z: this._objectRef.borderTickness,
            sx: groundSize.length - 2 * this._objectRef.borderTickness,
            sy: this._objectRef.borderHeight,
            sz: this._objectRef.borderTickness
          });
        this.resize(
          models.rightModel, {
            x: groundSize.width / 2,
            z: this._objectRef.borderTickness,
            sx: groundSize.length - 2 * this._objectRef.borderTickness,
            sy: this._objectRef.borderHeight,
            sz: this._objectRef.borderTickness
          });
        this.resize(models.monumentHolderModel, { y: -100 });
        this.resize(
          models.frontLeftModel, {
            x: -groundSize.width / 2,
            sy: this._objectRef.borderHeight,
            sx: groundSize.width / 2 - this.utilsService.borderSize.monumentHolderWidth / 2,
            sz: this._objectRef.borderTickness
          });
        this.resize(
          models.frontRightModel, {
            x: groundSize.width / 2,
            sy: this._objectRef.borderHeight,
            sx: groundSize.width / 2 - this.utilsService.borderSize.monumentHolderWidth / 2,
            sz: this._objectRef.borderTickness
          });
        this.resize(models.backModel, { z: groundSize.length, sy: this._objectRef.borderHeight, sx: groundSize.width, sz: this._objectRef.borderTickness });
        break;
      case 'editable-7':
        // this.utilsService.borderSize.monumentHolderWidth = groundSize.width - 2 * this._objectRef.borderTickness < this._objectRef.monumentHolderWidth ? groundSize.width - this._objectRef.borderTickness * 2 : this._objectRef.monumentHolderWidth;
        this.resize(models.monumentHolderModel, { sy: this._objectRef.monumentHolderHeight, sx: groundSize.width, sz: groundSize.length, z: 0 });
        break;
      case 'editable-8':
        this.resize(
          models.monumentHolderModel,
          { sy: this._objectRef.monumentHolderHeight, sx: this.utilsService.borderSize.monumentHolderWidth, sz: this._objectRef.monumentHolderLength }
        );
        break;
    }

    this._objectRef.objectsList = {};
    if (models && models.monumentHolderModel) {
      if (this._objectRef.category === "editable-7") {
        this._objectRef.objectsList.MonumentHolder = {
          name: 'Sockel',
          size: {
            width: this.utilsService.getGroundSize().length.toFixed(1),
            length: this.utilsService.getGroundSize().width.toFixed(1),
            height: this._objectRef.monumentHolderHeight.toFixed(1)
          },
          material: this._objectRef.material['MonumentHolder'],
          polish: this._objectRef.polish['MonumentHolder']
        };
      } else {
        this._objectRef.objectsList.MonumentHolder = {
          name: 'Sockel',
          size: {
            length: this.utilsService.getGroundSize().width.toFixed(1),
            width: this._objectRef.monumentHolderLength.toFixed(1),
            height: this._objectRef.monumentHolderHeight.toFixed(1)
          },
          material: this._objectRef.material['MonumentHolder'],
          polish: this._objectRef.polish['MonumentHolder']
        };
      }


    }

    if (models && models.leftModel) {
      const leftModelSize = this.utilsService.getSizes(models.leftModel);
      this._objectRef.objectsList.Left = {
        name: 'Seitenteil Links',
        size: {
          width: leftModelSize.width.toFixed(1),
          height: leftModelSize.length.toFixed(1),
          length: leftModelSize.height.toFixed(1)
        },
        material: this._objectRef.material['Left'],
        polish: this._objectRef.polish['Left']
      };
    }

    if (models && models.rightModel) {
      const rightModelSize = this.utilsService.getSizes(models.rightModel);
      this._objectRef.objectsList.Right = {
        name: 'Seitenteil Rechts',
        size: {
          width: rightModelSize.width.toFixed(1),
          height: rightModelSize.length.toFixed(1),
          length: rightModelSize.height.toFixed(1)
        },
        material: this._objectRef.material['Right'],
        polish: this._objectRef.polish['Right']
      };
    }

    if (models && models.frontModel) {
      /**/
      const frontModelSize = this.utilsService.getSizes(models.frontModel);
      this._objectRef.objectsList.Front = {
        name: 'Rückenteil',
        size: {
          length: frontModelSize.width.toFixed(1),
          height: frontModelSize.length.toFixed(1),
          width: frontModelSize.height.toFixed(1)
        },
        material: this._objectRef.material['Front'],
        polish: this._objectRef.polish['Front']
      };
    }

    if (models && models.backModel) {
      const backModelSize = this.utilsService.getSizes(models.backModel);
      this._objectRef.objectsList.Back = {
        name: 'Kopfstück',
        size: {
          length: backModelSize.width.toFixed(1),
          height: backModelSize.length.toFixed(1),
          width: backModelSize.height.toFixed(1)
        },
        material: this._objectRef.material['Back'],
        polish: this._objectRef.polish['Back']
      };
    }

    if (models && models.frontLeftModel) {
      const frontLeftModelSize = this.utilsService.getSizes(models.frontLeftModel);
      this._objectRef.objectsList.FrontLeft = {
        name: 'Vorne links',
        size: {
          length: frontLeftModelSize.width.toFixed(1),
          height: frontLeftModelSize.length.toFixed(1),
          width: frontLeftModelSize.height.toFixed(1)
        },
        material: this._objectRef.material['FrontLeft'],
        polish: this._objectRef.polish['FrontLeft']
      };
    }
    if (models && models.frontRightModel) {
      const frontRightModelSize = this.utilsService.getSizes(models.frontRightModel);
      this._objectRef.objectsList.FrontRight = {
        name: 'Vorne rechts',
        size: {
          length: frontRightModelSize.width.toFixed(1),
          height: frontRightModelSize.length.toFixed(1),
          width: frontRightModelSize.height.toFixed(1)
        },
        material: this._objectRef.material['FrontRight'],
        polish: this._objectRef.polish['FrontRight']
      };
    }
    if (this._objectRef.category === "fixed") {
      for (let mat in Object.keys(this._objectRef.material)) {
        this._objectRef.objectsList[mat] = {
          name: mat,
          material: this._objectRef.material[mat],
          polish: this._objectRef.polish[mat]
        }
      }
    }
    if (!models) {
      let index = 1;
      for (const key in Object.keys(this._objectRef.material)) {
        const sizes = this.utilsService.getSizes(this.utilsService.getMeshByName(key, this._node));
        if (!sizes) {
          return;
        }
        let name = key;
        if (key.includes('Object')) {
          name = 'Montageteil ' + index;
          index++;
        }
        this._objectRef.objectsList[key] = {
          name: name,
          size: {
            width: sizes.width.toFixed(1),
            length: sizes.length.toFixed(1),
            height: sizes.height.toFixed(1)
          },
          material: this._objectRef.material[key],
          polish: this._objectRef.polish[key]
        };
      }
    }
  }

  private resize(model, values) {
    if (!model) {
      return;
    }
    const bBox = this.utilsService.getAbstractDimensionsFromBBox(model);
    if (typeof values.sx === 'number') {
      model.scale.x = values.sx / bBox.x;
    }
    if (typeof values.sy === 'number') {
      model.scale.y = values.sy ? values.sy / bBox.y : 1;
    }
    if (typeof values.sz === 'number') {
      model.scale.z = values.sz / bBox.z;
    }
    if (typeof values.z === 'number') {
      model.position.z = values.z;
    }
    if (typeof values.x === 'number') {
      model.position.x = values.x;
    }
    if (typeof values.y === 'number') {
      model.position.y = values.y;
    }
  }

  private _getMeshes() {
    let backModel, leftModel, rightModel, frontRightModel, frontLeftModel, frontModel, monumentHolderModel, noModels;

    this._node.children[0].traverse(child => {
      if (child.name) {
        child.visible = true;
      }
    });
    backModel = this.utilsService.getMeshByName('Back', this._node);
    leftModel = this.utilsService.getMeshByName('Left', this._node);
    rightModel = this.utilsService.getMeshByName('Right', this._node);
    frontRightModel = this.utilsService.getMeshByName('FrontRight', this._node);
    frontLeftModel = this.utilsService.getMeshByName('FrontLeft', this._node);
    frontModel = this.utilsService.getMeshByName('Front', this._node);
    monumentHolderModel = this.utilsService.getMeshByName('MonumentHolder', this._node);
    if (!backModel && !leftModel && !rightModel && !frontRightModel && !frontLeftModel && !frontModel && !monumentHolderModel) {
      noModels = true;
    }
    if (backModel) {
      backModel.visible = true;
    }
    if (leftModel) {
      leftModel.visible = true;
    }
    if (rightModel) {
      rightModel.visible = true;
    }
    if (frontRightModel) {
      frontRightModel.visible = true;
    }
    if (frontLeftModel) {
      frontLeftModel.visible = true;
    }
    if (frontModel) {
      frontModel.visible = true;
    }
    if (monumentHolderModel) {
      monumentHolderModel.visible = true;
    }
    return {
      backModel: backModel,
      leftModel: leftModel,
      rightModel: rightModel,
      frontModel: frontModel,
      monumentHolderModel: monumentHolderModel,
      frontRightModel: frontRightModel,
      frontLeftModel: frontLeftModel,
      noModels: noModels
    };
  }

  getObjectCopy() {
    return this.utilsService.createDeepClone(this._objectRef);
  }

}
