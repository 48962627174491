import { Object3D } from 'three';
import {Inject} from '@angular/core';
import {UtilsService} from '../three-utils/utils.service';
import {MaterialService} from './material.service';
import {AssetsLoadingManager} from './loader-service';
import {Subject} from 'rxjs';
import {Object3DType} from '../../../models/firestore/categories/categorizable.model';
import {Text} from '../../../models/project/text.model';
import {Accessory} from '../../../models/firestore/categories/accessory/accessory.model';
import {Group} from '../../../models/firestore/categories/shared/group.model';

export abstract class AbstractObject3DService<T extends Object3DType> {
  public readonly needsRender: Subject<void> = new Subject<void>();

  protected constructor(@Inject(UtilsService) protected utilsService: UtilsService,
                        @Inject(MaterialService) protected materialService: MaterialService,
                        @Inject(AssetsLoadingManager) protected loaderService: AssetsLoadingManager,
                        private objType: new () => T, public parentName: string) {
    this._objectRef = new objType();
  }

  public initNode() {
    this._node = this.utilsService.getNodeByName(this.parentName);
  }

  public removeFromScene() {
    if(this._node) {
      this.utilsService.disposeElement(this._node);
    }
  }

  /**
   * This method should take care of cleaning up both
   * objects in scene and object fields in service
   */
  public abstract purge(): void;

  /**
   * Implement this so that it reacts to
   * every possible update that requires changes
   * in the rendered object T (ex: size changed)
   * @param updateValues - values passed on from a form/place where the object was changed
   * @param simpleUpdate - use when updated by other components (maybe position needs update for ex.)
   */
  public abstract requestUpdate(updateValues: T, simpleUpdate?: boolean): Promise<void>;

  /**
   * This should handle position updates and pass data
   * from _objectRef to corespondent object in scene
   * Should only be called by requestUpdate when needed
   * @param values - values passed on from requestUpdate()
   */
  protected abstract _update(values: T): void;

  /**
   * Use this when changing material/polish on 3D objects
   * @param updateValues - should be different for every child class
   */
  public abstract changeAppearance(updateValues: any): void;

  /**
   * Use the loader service to create a 3D object
   * Remember to add it to _node
   * @param modelPath - path to model file
   */
  protected _createObject3D(modelPath: string): Promise<any> {
    return this.loaderService.load(modelPath);
  }

  /**
   * Checks if meshName is part of any material group
   * Returns the index of the found group or -1
   * @param meshName - name of the mesh to look for
   * @protected
   */
  protected _findMeshInGroup(meshName: string): number {
    if('groups' in this._objectRef && Array.isArray(this._objectRef['groups'])) {
      for (let i = 0; i < this._objectRef['groups'].length; i++) {
        if((<Group> this._objectRef['groups'][i]).models.includes(meshName)) {
          return i;
        }
      }
    }
    return -1;
  }

  protected _applyMaterial() {
    this._node.traverse(child => {
      if (child.type === 'Mesh') {
        this.materialService.applyMaterial(child);
      }
    });
    this.needsRender.next();
  }

  protected _objectRef: T;
  protected _node: Object3D;
}
