import { Inject, Injectable } from '@angular/core';
import { StoneItemsFirestoreService } from '../../modules/stone/services/stone-items-firestore.service';
import { map, take } from 'rxjs/operators';
import { FirestoreQueryFnBuilder } from '../../core/firebase/firestore/list/firestore-query-fn-builder';
import { FirestoreFilter } from '../../core/firebase/firestore/list/firestore-filter';
import { Stone } from '../../../models/firestore/categories/stone/stone.model';
import { FirestoreSort } from '../../core/firebase/firestore/list/firestore-sort';

@Injectable({
  providedIn: 'root'
})
export class FirestoreDuplicatesRemovalService {

  constructor(@Inject(StoneItemsFirestoreService) protected readonly stoneItems: StoneItemsFirestoreService) { }

  removeDuplicates() {
    // this.removeStoneItemDuplicates();
  }

  private removeStoneItemDuplicates() {
    const query = FirestoreQueryFnBuilder.withSort(FirestoreSort.ascending('name')).build();
    this.stoneItems.read(query).valueChanges()
      .pipe(take(1))
      .subscribe(items => this.verifyStones(items));
  }

  private async verifyStones(items: Array<Stone>) {
    for (const item of items) {
      const q = FirestoreQueryFnBuilder.withFilters([FirestoreFilter.equals('name', item.name)]).build();
      const hasDuplicates = await this.stoneItems.read(q).valueChanges()
        .pipe(take(1), map(results => (results && results.length > 1)))
        .toPromise();
      if (hasDuplicates) {
        console.log(item.name + ' has duplicates. Removing...');
        await this.stoneItems.delete(item.uid);
        console.log('Removed!');
      } else {
        console.log(item.name + ' is unique');
      }
      console.log('Next!');
    }
  }
}
