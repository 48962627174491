import { Inject, Injectable } from '@angular/core';
import { FirebaseStorageService } from '../../core/firebase/firebase-storage.service';
import { CubeTextureLoader, FontLoader, LoadingManager, Matrix4, Scene, TextureLoader } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { ThreeViewService } from '../three-view.service';


// GLTF2Loader(THREE);

@Injectable({ providedIn: 'root' })
export class AssetsLoadingManager {
  constructor(@Inject(FirebaseStorageService) private readonly storage: FirebaseStorageService, private threeViewService: ThreeViewService) {
    this._loadingManager = new LoadingManager();
    // todo: @Alex -> implement CTM loader
    this._loaders['json'] = new FontLoader(this._loadingManager);
    // this._loaders['fbx'] = new FBXLoader(this._loadingManager);
    this._loaders['glb'] = new GLTFLoader(this._loadingManager);
    this._loaders['jpg'] = this._loaders['png'] = new TextureLoader(this._loadingManager);
  }

  load(path: string, fullScene?: boolean): Promise<any> {

    return new Promise((resolve, reject) => {
      // console.log(path)
      this.storage.getDownloadUrl('restricted/' + path).subscribe((res) => {
        this.getLoader(path).load(res, (obj) => {
          this.setManagerLoading();
          if (obj.scene) {
            const scene = new Scene();
            obj.scene.traverse((child) => {
              if (child.material) {
                child.updateMatrixWorld();
                const clone = child.clone();
                clone.applyMatrix4(new Matrix4());
                clone.castShadow = true;
                clone.receiveShadow = true;
                scene.add(clone);
              }
              if (child.type === 'AmbientLight') {
                obj.scene.remove(child);
              }
            });
            if (fullScene) {
              resolve(obj);
            }
            resolve(scene);
          } else { resolve(obj); }
        }, () => { }, reject);
      }, err => {
        console.log('eror loading file ', err);
      });

    });
  }

  loadCubeMap(paths): Promise<any> {
    return new Promise((resolve, reject) => {
      new CubeTextureLoader().load(paths, (images) => {
        resolve(images);
      });
    });
  }
  private _loadTimeOut = setTimeout(() => {}, 10);
  private setManagerLoading() {
    this._loadingManager.onStart = () => {
      clearInterval(this._loadTimeOut);
      document.getElementById('loadingScreen').style.display = 'block';
    };
    this._loadingManager.onLoad = () => {
      this._loadTimeOut = setTimeout(() => {
        document.getElementById('loadingScreen').style.display = 'none';
        this.threeViewService.needToRender();
      }, 1000);

    };
  }

  private getLoader(path: string) {
    const ext = path.substr(path.lastIndexOf('.') + 1);
    return this._loaders[ext];
  }

  private readonly _loadingManager;
  private _loaders: any = {};
}
