import { Identifiable } from '../identifiable.model';
import { Filterable } from '../filterable.model';
import { Point2D } from '../../point-2d.model';
import { FilterDefinition } from '../../../../commons/filter/filter-definition';
import { isEqual } from 'lodash-es';

export class Polish implements Identifiable, Filterable {
  uid: string = null;
  keywords: Array<string> = [];
  name: string = null;
  type: PolishType = PolishType.Polish;
  thumbnail: string = null;
  /** @deprecated **/
  filters: Array<string> = []; // should be removed after all usages are refactored

  color: string = '#ffffff';
  roughness: number = 0.5;
  blending: number = 1;
  metalness: number = 0.0;
  emissive: string = '#000000';
  clearcoat: number = 0.0;
  clearcoatRoughness: number = 0.0;
  normalMap: string = null;
  roughnessMap: string = null;
  metalnessMap: string = null;
  map: string = '';
  side: number = 0;
  normalScale: Point2D = { x: 1.0, y: 1.0 };
  repeat: number = 1;
  envMap: string = null;
  reflectivity: number = 1.0;
  opacity: number = 1.0;
  envMapIntensity: number = 1.0;
  depthFunc: number = 3;
  depthTest: boolean = true;
  depthWrite: boolean = true;
  transparent: boolean = false;
  sheen: string = '#ffffff';

  static of(source: Polish): Polish {
    return Object.assign(new Polish(), source);
  }

  static equals(value: Polish, other: Polish): boolean {
    if (!value && !other) { return true; }
    if (!value || !other) { return false; }
    return value.uid === other.uid
      && value.name === other.name
      && value.type === other.type
      && value.thumbnail === other.thumbnail
      && value.color === other.color
      && value.roughness === other.roughness
      && value.blending === other.blending
      && value.metalness === other.metalness
      && value.emissive === other.emissive
      && value.clearcoat === other.clearcoat
      && value.clearcoatRoughness === other.clearcoatRoughness
      && value.normalMap === other.normalMap
      && value.roughnessMap === other.roughnessMap
      && value.metalnessMap === other.metalnessMap
      && value.map === other.map
      && value.side === other.side
      && value.repeat === other.repeat
      && value.envMap === other.envMap
      && value.reflectivity === other.reflectivity
      && value.opacity === other.opacity
      && value.envMapIntensity === other.envMapIntensity
      && value.depthFunc === other.depthFunc
      && value.depthTest === other.depthTest
      && value.depthWrite === other.depthWrite
      && value.transparent === other.transparent;
  }

  static withDefaults(polish?: Polish): Polish {
    polish = polish || new Polish();
    polish.uid = polish.uid || null;
    polish.keywords = polish.keywords || [];
    polish.name = polish.name || null;
    polish.type = polish.type || null;
    polish.thumbnail = polish.thumbnail || null;
    polish.filters = polish.filters || [];
    polish.color = polish.color || null;
    polish.roughness = polish.roughness || null;
    polish.blending = polish.blending || null;
    polish.metalness = polish.metalness || null;
    polish.emissive = polish.emissive || null;
    polish.clearcoat = polish.clearcoat || null;
    polish.clearcoatRoughness = polish.clearcoatRoughness || null;
    polish.normalMap = polish.normalMap || null;
    polish.roughnessMap = polish.roughnessMap || null;
    polish.metalnessMap = polish.metalnessMap || null;
    polish.map = polish.map || null;
    polish.side = polish.side || null;
    polish.normalScale = polish.normalScale || null;
    polish.repeat = polish.repeat || null;
    polish.envMap = polish.envMap || null;
    polish.reflectivity = polish.reflectivity || null;
    polish.opacity = polish.opacity || null;
    polish.envMapIntensity = polish.envMapIntensity || null;
    polish.depthFunc = polish.depthFunc || null;
    polish.depthTest = polish.depthTest || null;
    polish.depthWrite = polish.depthWrite || null;
    polish.transparent = polish.transparent || null;
    return polish;
  }

  getFilterDefinitions(): Array<FilterDefinition> {
    return [FilterDefinition.fulltext('name')];
  }
}

export enum PolishType {
  Polish = 'polish',
  Other = 'other',
  Special = 'special'
}
