import { AbstractControl, AsyncValidatorFn, FormGroupDirective, NgForm, ValidatorFn } from '@angular/forms';

export class FormUtils {
  static addValidatorsToControl(control: AbstractControl, newValidator: ValidatorFn | ValidatorFn[] | null) {
    if (control) {
      const newValidators = Array.isArray(newValidator) ? newValidator : [newValidator];
      const validators = control.validator ? [control.validator, ...newValidators] : newValidators;
      control.setValidators(validators);
    }
  }

  static addAsyncValidatorsToControl(control: AbstractControl, newValidator: AsyncValidatorFn | AsyncValidatorFn[] | null) {
    if (control) {
      const newValidators = Array.isArray(newValidator) ? newValidator : [newValidator];
      const validators = control.asyncValidator ? [control.asyncValidator, ...newValidators] : newValidators;
      control.setAsyncValidators(validators);
    }
  }


  static showControlErrors(control: AbstractControl, form: FormGroupDirective | NgForm | null, formGroupValidation: boolean = false) {
    const submitted: boolean = form && form.submitted;
    return (control && control.invalid)
           ? this.showControlErrorState(control, submitted)
           : this.showFormGroupErrorState(control, submitted, formGroupValidation);
  }

  static showAsyncControlErrors(control: AbstractControl, formSubmitted = false) {
    return control ? this.showControlErrorState(control, formSubmitted) : false;
  }

  private static showFormGroupErrorState(control: AbstractControl, formSubmitted: boolean, formGroupValidation: boolean): boolean {
    return this.showControlErrorState(control, formSubmitted) && formGroupValidation;
  }

  private static showControlErrorState(control: AbstractControl, formSubmitted: boolean) {
    return control.touched || control.dirty || formSubmitted;
  }
}
