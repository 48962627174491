import { Inject, Injectable, OnDestroy } from '@angular/core';
import { AuthService } from './core/auth/auth.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { UtilsService } from './three-view/three-utils/utils.service';
import { UploadFileService } from './services/upload-file-service.service';
import { FirebaseStorageService } from './core/firebase/firebase-storage.service';
import { AppUser } from './modules/user/data/user.model';
import { ProjectService } from './services/project.service';
import { ErrorResolversRegistrationService } from './core/forms/error-resolvers-registration.service';

@Injectable({ providedIn: 'root' })
export class AppInitService implements OnDestroy {
  constructor(
    @Inject(AuthService) private readonly authService: AuthService,
    @Inject(UtilsService) private readonly utilsService: UtilsService,
    @Inject(UploadFileService) private readonly uploadFileService: UploadFileService,
    @Inject(FirebaseStorageService) private readonly storage: FirebaseStorageService,
    @Inject(ProjectService) private readonly projectService: ProjectService,
    @Inject(ErrorResolversRegistrationService) private readonly errorResolversRegistrationService: ErrorResolversRegistrationService
  ) { }

  init() {
    this.errorResolversRegistrationService.init();
    this.authService.appUser$
      .pipe(
        takeUntil(this.destroyed$),
        filter(appUser => appUser !== null)
      )
      .subscribe(appUser => this.onAuthSuccess(appUser));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  private onAuthSuccess(appUser: AppUser) {
    this.uploadFileService.changeBasePath(this.getUsernameFromEmail(appUser.email));
    // this.triggerMigrationFromOldAppData(); // only uncomment on migrations
  }

  private getUsernameFromEmail(user) {
    const res = user.split('@');
    return `${res[0].replace(/[^\w\s]/gi, '')}`;
  }

  private triggerMigrationFromOldAppData() {
    this.storage.download('restricted/newJSON4.json').subscribe(appData => this.utilsService.migrateDataToFirestore(appData));
  }

  private readonly destroyed$: Subject<void> = new Subject<void>();
}
