import { Inject, Injectable } from '@angular/core';
import { FirestoreService } from '../../../core/firebase/firestore/firestore.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AppUser } from './user.model';
import { UserProjectsFirestoreService } from '../../project/services/user-projects-firestore.service';
import { EmailsFirestoreService } from '../../email/data/emails-firestore.service';

@Injectable({
  providedIn: 'root'
})
export class UserFirestoreService extends FirestoreService<AppUser> {

  protected readonly path: string = 'users';

  constructor(
    @Inject(AngularFirestore) protected readonly firestore: AngularFirestore,
    @Inject(UserProjectsFirestoreService) private readonly projectsService: UserProjectsFirestoreService,
    @Inject(EmailsFirestoreService) private readonly emailsService: EmailsFirestoreService
  ) { super(firestore); }

  get emails(): EmailsFirestoreService {
    return this.emailsService;
  }

  get projects(): UserProjectsFirestoreService {
    return this.projectsService;
  }
}
