import { Inject, Injectable } from '@angular/core';
import { FirestoreService } from '../../../core/firebase/firestore/firestore.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Plate } from '../../../../models/firestore/categories/plate/plate.model';

@Injectable({
  providedIn: 'root'
})
export class PlateItemsFirestoreService extends FirestoreService<Plate> {

  constructor(@Inject(AngularFirestore) protected readonly firestore: AngularFirestore) { super(firestore); }

  protected get path(): string {
    return 'categories/plate/items';
  }
}
